import {useTranslation} from "react-i18next";
import {formatTimestamp} from "../../../../utils";
import {useDispatch} from "react-redux";
import {setActivePage} from "../../../../redux/actions/lobby/actions";
import {setSideBarTournamentId} from "../../../../redux/actions/tournament/actions";
function CashTournamentListItem({data, currency}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleItemClick = (tournamentId) => {
        if (!tournamentId) return;
        dispatch(setActivePage({activePage: 'Tournament'}));
        dispatch(setSideBarTournamentId({tournamentId}))
    }

    return (
        <li className="cash-lobby-tournament-item" onClick={() => handleItemClick(data?.id)}>
            <i className="icon icon-cup"/>
            <div className="cash-lobby-tournament-item__info">
                <span className="cash-lobby-tournament-item__info--top">
                    <span className="cash-lobby-tournament-item__info--top--title">{data?.name}</span>
                    <span className="cash-lobby-tournament-item__info--top--date">{formatTimestamp(data?.startDate)}</span>
                </span>
                <span className="cash-lobby-tournament-item__info--bottom">
                    <span>{t('tournament.bet')}: {data?.buyIn[currency]} {currency}</span>
                    <span>{t('tournament.prize')}: {data?.prize[currency]} {currency}</span>
                </span>
            </div>
        </li>
    )
}

export default CashTournamentListItem