import {useDispatch, useSelector} from "react-redux";
import React from "react";
import Button from "../../../../../../core/Button/Button";
import {selectActiveRoomTab} from "../../../../../../redux/actions/lobby/actions";
import {closeRoom, roomJoinToView, roomViewExit} from "../../../../../../redux/actions/rooms/actions";
import {useTranslation} from "react-i18next";

function ProgressStateButton({row}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {id: userId, isMobile} = useSelector((state) => state.user);
    const room = useSelector((state) => state.rooms.entered)[row?.table_room_id];
    const isGamePrivate = row?.table_room_type?.includes(50);
    const isGamePlayer = [row?.table_room_player, row?.table_room_player2].includes(userId);
    const handleClick = (action) => dispatch(action);
    const renderButton = (text, iconClass, action = () => {
    }) => {
        return !isMobile ? (
            <div className="button-size">
                <Button onClick={action} type={"blue-border"} text={text}/>
            </div>
        ) : (
            <i className={iconClass} onClick={action}/>
        );
    };

    if (room) {
        if (isGamePlayer) {
            return (
                <div className="table-buttons-wrapper">
                    {!isMobile && (
                        <i className="icon-close close-hover"
                           onClick={() => handleClick(closeRoom({roomId: row?.table_room_id}))}/>
                    )}
                    {renderButton(t('buttons.back'), "icon-arrow icon-right", () => handleClick(selectActiveRoomTab({roomId: row?.table_room_id})))}
                </div>
            );
        } else {
            return (
                <div className="table-buttons-wrapper">
                    {
                        renderButton(
                            t('buttons.exit'),
                            "icon-arrow icon-right",
                            () => handleClick(roomViewExit({roomId: row?.table_room_id})),
                        )
                    }
                </div>
            );
        }
    } else {
        return <div className="table-buttons-wrapper">
            {
                isGamePrivate ?
                    renderButton(t('buttons.private'), "icon-eye") :
                    renderButton(t('buttons.view'), "icon-eye", () => handleClick(roomJoinToView({roomId: row?.table_room_id})))
            }
        </div>
    }
}

export default ProgressStateButton;
