/* eslint-disable */

import React, {useState, useCallback} from 'react';
import {useDispatch} from "react-redux";
import BaseModal from "./BaseModal";
import {closeModal, roomAnnounceCombination} from "../../../../redux/actions/rooms/actions";
import {COMBINATION_ANNOUNCE_BUTTONS, COMBINATION_MODAL_BUTTONS} from "../../../../constants";
import Button from "../../../../core/Button/Button";
import {useTranslation} from "react-i18next";

const initial = {
    'tierce': null,
    '50': null,
    '100': null,
    'fours': null,
};
const AnnounceCombinationModal = React.memo(function ({room}) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [combination, setCombination] = useState(initial);
    const gameType = room?.conventions?.gameTypes.find(type => [1, 2, 3, 40].includes(type))
    const activeButtons = COMBINATION_ANNOUNCE_BUTTONS[gameType] || [];
    const handleCombinationClick = useCallback(({name, length, count, cId}) => {
        setCombination(prevCombination => {
            let updatedCombination = {...prevCombination};
            if (updatedCombination[name]) {
                if (updatedCombination[name].count === count) {
                    updatedCombination[name] = null
                } else {
                    updatedCombination[name] = {name, length, count, cId}
                }
            } else {
                updatedCombination[name] = {name, length, count, cId}
            }
            return updatedCombination;
        });
    }, []);

    const announceCombination = useCallback(() => {
        const selectedCombinations = Object.values(combination).filter(Boolean);
        dispatch(roomAnnounceCombination({
            roomId: room.roomId,
            combination: selectedCombinations
        }));
        setCombination(initial);
    }, [dispatch, room.roomId, combination]);

    const renderCombinationButtons = useCallback((name, length, counts, cId) => (

        counts.map((count, index) => (
            <button
                disabled={!activeButtons?.includes(name)}
                key={index}
                className={`modal__announce-combination--type--button ${index !== 0 ? "modal__announce-combination--type--button--s" : ""} ${combination[name]?.count === count ? "active" : ""}`}
                onClick={() => handleCombinationClick({name, length, count: count, cId})}
            >
                {t(`modals.announce_combination.buttons.${name}-x${count}`)}
            </button>
        ))
    ), [combination, handleCombinationClick, t]);

    const handleCancel = useCallback(() => {
        dispatch(closeModal({roomId: room.roomId}));
    }, [dispatch, room.roomId]);

    return (
        <BaseModal>
            <div className="modal__announce-combination">
                <p className="modal__announce-combination--header">{t("modals.announce_combination.announce")}</p>
                <div className="modal__announce-combination--body">
                    {room?.modal?.msg && <p>{t(`modals.announce_combination.${room?.modal?.msg}`)}</p>}
                    <div className="modal__announce-combination--wrapper">
                        {
                            COMBINATION_MODAL_BUTTONS.map((btnData, index) => {
                                return (
                                    <div key={index} className="modal__announce-combination--type">
                                        {renderCombinationButtons(btnData.name, btnData.length, btnData.counts, btnData.cId)}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="modal__announce-combination--buttons">
                        <Button
                            type="yellow"
                            text={t("modals.buttons.announce")}
                            onClick={announceCombination}
                        />
                        <Button type="red" text={t("modals.buttons.close")} onClick={handleCancel}/>
                    </div>
                </div>
            </div>
        </BaseModal>
    );
}, ((prevProps, nextProps) => {
    return prevProps.room.modal.msg === nextProps.room.modal.msg
}));

export default AnnounceCombinationModal
