import React from "react";
import Tabs from "../../Tabs/Tabs";
import {TABS} from "../../../../constants";
import DesktopHeaderOptions from "../DesktopHeaderOptions/DesktopHeaderOptions";
import {useSelector} from "react-redux";
import ActiveRoomTabs from "../../ActiveRoomTabs/ActiveRoomTabs";
const DesktopHeader = React.memo(function() {
    const activeRoomId = useSelector(state => state.lobby.activeRoomId)
    return <div className={`header ${activeRoomId ? 'header__blur' : ''}`}>
        <div>
            <Tabs tabItems={TABS}/>
        </div>
        <ActiveRoomTabs/>
        <DesktopHeaderOptions/>
    </div>
}, (prevProps, nextProps) => true)

export default DesktopHeader