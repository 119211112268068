// import Button from "../../../../core/Button/Button";
// import {useCallback, useEffect, useState} from "react";
// import SettingsInput from "./SettingsInput/SettingsInput";
// import {useTranslation} from "react-i18next";
// import {useDispatch, useSelector} from "react-redux";
// import {userClearNickNameError, userUpdateNickName} from "../../../../redux/actions/user/actions";
//
// function SettingsForm() {
//     const {t} = useTranslation();
//     const nickname = useSelector(state => state.user.nickname);
//     const errors = useSelector(state => state.user.errors);
//     const  dispatch = useDispatch();
//     const [nick, setNick] = useState(nickname?.value);
//     const [hasChange, setChange] = useState(false);
//
//     const handleInputChange = useCallback((name) => {
//         if (!hasChange) {
//             setChange(true)
//         }
//         setNick(name)
//     }, [nick, setNick, hasChange, setChange]);
//
//     useEffect(() => {
//         setChange(false);
//     }, [nickname])
//
//     useEffect(() => {
//         return () => {
//             dispatch(userClearNickNameError())
//         }
//     }, [])
//
//     const handleSaveNickname = useCallback(() => {
//         if (!nick?.length) return;
//         dispatch(userUpdateNickName({ nickname: nick, isDelete: false }));
//     }, [nick, dispatch]);
//
//     const handleDeleteNickname = useCallback(() => {
//         if (!nickname?.value) return;
//         dispatch(userUpdateNickName({ nickname: '', isDelete: true }));
//         setNick(nickname?.value)
//
//     }, [nickname?.value, dispatch]);
//
//     return (
//         <>
//             <SettingsInput
//                 handleChange={(val) => handleInputChange(val)}
//                 value={nick}
//                 placeholder={!hasChange ? nickname?.value || 'nickname' : 'nickname'}
//                 serverError={errors['nickname']}
//                 isVerified={nickname?.isVerified}
//                 hasChange={hasChange}
//             />
//             <div className="settings__nikname--buttons">
//                 <Button
//                     type="yellow"
//                     text={t("settings.save")}
//                     onClick={handleSaveNickname}
//                 />
//                 <Button
//                     type="white-border"
//                     text={t("settings.clear")}
//                     onClick={handleDeleteNickname}
//                 />
//             </div>
//         </>
//     )
// }
//
// export default SettingsForm

import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../../../core/Button/Button";
import SettingsInput from "./SettingsInput/SettingsInput";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { userClearNickNameError, userUpdateNickName } from "../../../../../redux/actions/user/actions";

const SettingsForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const nickname = useSelector(state => state.user.nickname);
    const errors = useSelector(state => state.user.errors);

    const [nick, setNick] = useState(nickname?.value || "");
    const [hasChange, setHasChange] = useState(false);

    // Update nickname when the input value changes
    const handleInputChange = useCallback((newNick) => {
        if (!hasChange) setHasChange(true); // Mark as changed
        setNick(newNick);
    }, [hasChange]);

    // Reset changes flag when nickname updates
    useEffect(() => {
        setHasChange(false);
    }, [nickname]);

    // Clean up nickname error when component unmounts
    useEffect(() => {
        return () => dispatch(userClearNickNameError());
    }, [dispatch]);

    // Save the nickname change
    const handleSaveNickname = useCallback(() => {
        if (nick?.length) {
            dispatch(userUpdateNickName({ nickname: nick, isDelete: false }));
        }
    }, [nick, dispatch]);

    // Clear the nickname (delete)
    const handleDeleteNickname = useCallback(() => {
        if (nickname?.value) {
            dispatch(userUpdateNickName({ nickname: "", isDelete: true }));
            setNick(""); // Reset input after deletion
        }
    }, [nickname, dispatch]);

    return (
        <>
            <SettingsInput
                handleChange={handleInputChange}
                value={nick}
                placeholder={hasChange ? (nickname?.value || nick) : t('settings.nickname')}
                serverError={errors['nickname']}
                isVerified={nickname?.isVerified}
                hasChange={hasChange}
            />
            <div className="settings__nikname--buttons">
                <Button
                    type="yellow"
                    text={t("settings.save")}
                    onClick={handleSaveNickname}
                />
                <Button
                    type="white-border"
                    text={t("settings.clear")}
                    onClick={handleDeleteNickname}
                />
            </div>
        </>
    );
};

export default SettingsForm;
