import TournamentTopPlayerItem from "./TournamentTopPlayerItem";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function TournamentTopPlayers() {
    const {t} = useTranslation();
    const topWinners = useSelector(state => state.tournament.topWinners);
    return (
        <div className="tournament-top-players">
            <h1>{t('tournament.top_winners')}</h1>
            <ul className="tournament-top-players__wrapper">
                {
                    topWinners?.map((item, index) => {
                        const data = {
                            ...item,
                            place: index + 1,
                        }
                        return <TournamentTopPlayerItem
                            key={index}
                            data={data}
                        />
                    })
                }
            </ul>
        </div>
    )
}

export default TournamentTopPlayers