import CASH from "./types";
export const cashUpdateRoomList = (payload) => {
    return {
        ctx: 'lobby',
        type: CASH.UPDATE_ROOM_LIST,
        payload
    }
};
export const cashUpdate = (payload) => {
    return {
        ctx: 'lobby',
        type: CASH.UPDATE,
        payload
    }
};
export const createRoom = (payload) => {
    return {
        type: CASH.CREATE_ROOM,
        payload
    }
};
export const joinRoom = (payload) => {
    return {
        type: CASH.JOIN_ROOM,
        payload
    }
};
export const setFilters = (payload) => {
    return {
        type: CASH.SET_FILTER,
        payload
    }
};
export const resetFilters = () => {
    return {
        type: CASH.RESET_FILTER,
    }
};




