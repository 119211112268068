import React from 'react';
import {useDispatch} from "react-redux";
import BaseModal from "./BaseModal";
import {
    roomRejectOpponent,
    roomAcceptOpponent,
    roomLeave,
} from "../../../../redux/actions/rooms/actions";
import Button from "../../../../core/Button/Button";
import {useTranslation} from "react-i18next";
import Spinner from "../../../../core/Spinner/Spinner";

const JoinRoomModal = React.memo(function ({room, userId}) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    return (
        <BaseModal room={room}>
            <div className="modal__join">
                {
                    userId === room.playerId ? <>
                        <p>{t("modals.join.text")}{room.opponentId}</p>
                        <div className="modal__join--buttons">
                            <Button
                                text={t("modals.buttons.play")}
                                type="green"
                                onClick={() => dispatch(roomAcceptOpponent({roomId: room.roomId}))}
                            />
                            <Button
                                text={t("modals.buttons.reject")}
                                type="yellow"
                                onClick={() => dispatch(roomRejectOpponent({roomId: room.roomId}))}
                            />
                        </div>
                    </> : <>
                        <div className="modal__join--top">
                            <Spinner/>
                            <p>{t("modals.join.waiting_text")} {room.playerId}</p>
                        </div>
                        <Button
                            text={t("modals.buttons.close")}
                            type="red"
                            onClick={() => dispatch(roomLeave({roomId: room.roomId}))}
                        />
                    </>
                }
            </div>
        </BaseModal>
    )
}, (prevProps, nextProps) => {
    return prevProps.room.roomId === nextProps.room.roomId &&
        prevProps.room.playerId === nextProps.room.playerId &&
        prevProps.room.opponentId === nextProps.room.opponentId
});

export default JoinRoomModal
