import {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

function Timer({roomId, platformId}) {
    const timers = useSelector(state => state.rooms.timers);
    const data = timers[roomId]
    const [step,setStep] = useState(0);
    const [timer, setTimer] = useState(data?.time);

    useEffect(() => {
        if (timer === 1 && data?.time > 1) {
            setStep(294.4/parseFloat(data?.time))
        }
        setTimer(data?.time)

    }, [data?.time, timer, step])

    if (data?.platformId !== platformId) return
    return (
            <div className="player__timer">
                {data?.time &&
                    <svg className="player__svg" xmlns="http://www.w3.org/2000/svg"
                         viewBox="-1 -1 34 34">

                        <circle cx="16" cy="16" r="15.9155"
                                className="progress-bar__background"

                        />
                        <circle cx="16" cy="16" r="15.9155"
                                className={` progress-bar__progress ${data.time === 0 ? 'progress-bar__progress--no-anim ' : ''}`}
                                style={{
                                    strokeDashoffset: `${parseFloat(data.time)}%`
                                }}

                        />

                    </svg>
                }
                <p className="player__timer-count">{data.time ? data.time : ''}</p>
            </div>
    )
}

export default Timer
