import {useDispatch} from "react-redux";
import Table from "../../../../core/Table/Table";
import {HISTORY_TABLE_TITLES} from "../../../../constants";
import {setHistorySelectedRoomId} from "../../../../redux/actions/history/actions";
function DesktopHistoryList({rows =[], currency}) {
    const dispatch = useDispatch()
    const handleHistoryItemClick = (row) => {
        dispatch(setHistorySelectedRoomId({roomId: row?.table_history_game_id}))
    }
    return <Table
        name={'History'}
        columns={HISTORY_TABLE_TITLES}
        rows={rows}
        handleClick={(row)=> handleHistoryItemClick(row)}
        currency={currency}
    />
}

export default DesktopHistoryList;