import CombinedLobbyPages from "../Lobby";
import Header from "../components/Header";
import {useSelector} from "react-redux";
import PageWrapper from "../Lobby/PageWrapper/PageWrapper";
import React, {useMemo} from "react";
import Room from "../components/Room";
import GameModal from "../components/Modals";

const Main = React.memo(function () {
    const {activePage, activeRoomId} = useSelector(state => state.lobby);
    const rooms = useSelector(state => state.rooms.entered) || {};
    const MemoizedLobbyActivePage = useMemo(() => CombinedLobbyPages[activePage], [activePage]);
    const board = localStorage.getItem("board") || 1;
    const frontCard = localStorage.getItem("card-front") || 1;
    const backCard = localStorage.getItem("card-back") || 1;
    const avatar = localStorage.getItem("avatar") || 1;
    const roomComponents = Object.values(rooms).map((room, index) => {
        return (
            <Room
                key={index}
                roomId={room.roomId}
                isActive={Number(activeRoomId) === Number(room.roomId)}
            />
        )
    })

    return (
        <div className={`belote-wrapper avatar-V${avatar} board-V${board} card-back-V${backCard} card-front-V${frontCard}`}>
            <GameModal />
            <Header/>
            <PageWrapper page={activePage}>
                <MemoizedLobbyActivePage/>
            </PageWrapper>
            {roomComponents}
        </div>
    );
});

export default Main;
