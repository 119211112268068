import React, {useCallback} from 'react';
import {useDispatch} from "react-redux";
import BaseModal from "./BaseModal";
import {closeModal, roomAnnounceAllCardsToOpponent} from "../../../../redux/actions/rooms/actions";
import {useTranslation} from "react-i18next";
import Button from "../../../../core/Button/Button";

const AcceptPassAllCardsToOpponentModal = React.memo(function ({room}) {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleCancel = useCallback(() => {
        dispatch(closeModal({roomId: room.roomId}));
    }, [dispatch, room.roomId]);

    const handleAcceptClick = useCallback(() => {
        dispatch(roomAnnounceAllCardsToOpponent({roomId: room.roomId}));
    }, [dispatch, room.roomId]);


    return (
        <BaseModal>
            <div className="modal__common">
                <p>{t("modals.common.cards_to_you")}</p>
                <div className="modal__common--buttons">
                    <Button text={t("modals.buttons.yes")} type="yellow" onClick={handleAcceptClick}/>
                    <Button text={t("modals.buttons.no")} type="blue" onClick={handleCancel}/>
                </div>
            </div>
        </BaseModal>
    );
});

export default AcceptPassAllCardsToOpponentModal
