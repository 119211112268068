import {useDispatch, useSelector} from "react-redux";
import {toggleTournamentActiveRooms} from "../../../redux/actions/tournament/actions";
import {lobbyOpenModal, selectActiveRoomTab} from "../../../redux/actions/lobby/actions";
import {roomJoinToView} from "../../../redux/actions/rooms/actions";
import {useTranslation} from "react-i18next";
import TournamentActiveRoomItem from "./TournamentActiveRoomItem/TournamentActiveRoomItem";

function TournamentActiveRooms() {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const isMobile = useSelector((state) => state.user.isMobile);
    const entered = useSelector((state) => state.rooms.entered);
    const sideBarTournamentId = useSelector((state) => state.tournament.sideBarTournamentId);
    const rooms = useSelector((state) => state.tournament.rooms);
    const tournamentRooms = rooms && rooms[sideBarTournamentId]
    const closeTournamentActiveRooms = () => {
        if (!isMobile) return dispatch(toggleTournamentActiveRooms());
        dispatch(lobbyOpenModal({name: 'TournamentInfoMobile'}))
    }
       const handleClick = (event) => {
        if (event?.target?.matches('.tournament-room-item')) {
            if (entered[event?.target.id]) {
                return dispatch(selectActiveRoomTab({roomId: event.target.id}))
            }
            return dispatch(roomJoinToView({roomId: event.target.id}))
        }
    }

    return (
        <div className="tournament-active-rooms">
            <div className="tournament-active-rooms__header">
                <div className="tournament-active-rooms__header--left">
                    <i className="icon-cup"/>
                    <p className="tournament-active-rooms--header-tournament-id">{sideBarTournamentId}</p>
                </div>
                <i className="tournament-active-rooms__header--right icon-close-round"
                   onClick={() => closeTournamentActiveRooms()}/>
            </div>
            <div className="tournament-active-rooms__body" onClick={(e) => handleClick(e)}>
                {
                    tournamentRooms?.length > 0 ?
                    tournamentRooms?.map((room, index) => {
                            return <TournamentActiveRoomItem
                                key={index}
                                index={index}
                                room={room}
                            />
                        }
                    )
                    :
                    <p>{t("tournament.empty_rooms_msg")}</p>
                }
            </div>
        </div>
    )
}

export default TournamentActiveRooms;