import {CARDS_ORDER_BY_SUIT} from "../constants";

export const detectMobile = () => {
    return {
        isMobile: window.innerWidth < 1024,
        isMobileLandscape:  (window.innerHeight < window.innerWidth &&  window.innerHeight < 641)
    }
};
export const convertAmountByUserRate = (amount, rate) => {
    let val = (amount / rate).toFixed(2);
    return val === '0.00' ? '-' : val
};

export const shuffleArray = (array) => {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]];
    }
    return shuffledArray;
}

export const deepClone = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        const arrCopy = [];
        for (let i = 0; i < obj.length; i++) {
            arrCopy[i] = deepClone(obj[i]);
        }
        return arrCopy;
    }

    const objCopy = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            objCopy[key] = deepClone(obj[key]);
        }
    }
    return objCopy;
}

export const setDataInLocalStorage = (key, value) => {
    localStorage.setItem(key, value)
}

export const deepEqual = (objA, objB) => {
    if (objA === objB) return true;
    if (objA == null || objB == null || typeof objA !== 'object' || typeof objB !== 'object') {
        return false;
    }
    if (Array.isArray(objA) && Array.isArray(objB)) {
        if (objA.length !== objB.length) return false;
        for (let i = 0; i < objA.length; i++) {
            if (!deepEqual(objA[i], objB[i])) return false;
        }
        return true;
    }
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);
    if (keysA.length !== keysB.length) return false;
    for (let key of keysA) {
        if (!keysB.includes(key) || !deepEqual(objA[key], objB[key])) {
            return false;
        }
    }

    return true;
};

export const sortCardsBySuit = (cards, trump = {}) => {
    if (!trump?.suit) {
        return cards;
    }
    const suitOrder = CARDS_ORDER_BY_SUIT[trump.suit];
    if (!suitOrder) {
        return cards;
    }
    const clonedCards = deepClone(cards);
    return clonedCards.sort((a, b) => {
        const suitAOrder = suitOrder[a?.suit];
        const suitBOrder = suitOrder[b?.suit];
        if (suitAOrder === undefined || suitBOrder === undefined) {
            return 0;
        }
        if (suitAOrder !== suitBOrder) {
            return suitAOrder - suitBOrder;
        }

        return (a?.rank?.sId || 0) - (b?.rank?.sId || 0);
    });
};

export const updateCardClasses = (refArray, index, removeClass, addClasses) => {
    const cardRef = refArray.current[index];
    if (cardRef) {
        cardRef.classList.remove(...removeClass);
        cardRef.classList.add(...addClasses);
    }
};

export const getShortUniqueKey = () => {
    return Math.random().toString(36).substring(2, 8) + Date.now();
}

export const getRandomIndex = (array, N) => {
    const maxIndex = array.length - N; // Calculate the maximum valid index
    if (N < 0) {
        throw new Error("N cannot be negative.");
    }
    if (maxIndex < 0) {
        throw new Error("N is too large; it exceeds the array length.");
    }
    return Math.floor(Math.random() * (maxIndex + 1));
};

export const removeKeys = (obj, keys) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([key]) => !keys.includes(key))
    );
}
export const formatTimeFromTimestamp = (timestamp, isSeconds = true) => {
    const date = new Date(isSeconds ? timestamp * 1000 : timestamp);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}

export const convertTime = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

}

export const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { month: 'short', day: '2-digit' };
    const dateString = date.toLocaleDateString('en-US', options);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${dateString} | ${hours}:${minutes}`;
}