import React from 'react';
import Card from "../Card";
import {getShortUniqueKey} from "../../../../utils";
const Deck  = React.memo(function () {
    return <div className='deck'>
        {
            [1,2,3].map((item) => {
                return <Card
                    key={getShortUniqueKey()}
                    className={`deck-${item}`} />
            })
        }
    </div>
}, (prevProps, nextProps) => true)

export default Deck
