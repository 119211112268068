import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import SliderItem from "./SliderItem/SliderItem";
import {useSelector} from "react-redux";
import {SLIDER_CONFIG} from "../../../constants";

const Slider = () => {
    const promo = useSelector(state => state.lobby.promo);
    const lang = useSelector(state => state.user.lang);
    const options = {loop: promo[lang]?.length > 1}
    const [emblaRef] = useEmblaCarousel(options, [
        Autoplay(SLIDER_CONFIG)
    ])
    if (!promo[lang]?.length) return;
    return (
            <section className="embla">
                <div className="embla__viewport" ref={emblaRef}>
                    <div className="embla__container">
                        {promo[lang]?.map((item, index) => (
                            <SliderItem
                                key={index}
                                src={item}
                            />
                        ))}
                    </div>
                </div>
            </section>
    )
}
export default Slider
