function RadioButton({title="", checked}){
    return(
        <div className="radio-button">
                <input type="radio" checked={checked} onChange={() => {}}/>
                <span className="radio-ui"></span>
                <span className="radio-button-title">{title}</span>
        </div>
    )
}

export default RadioButton