import React from 'react';
import {useTranslation} from "react-i18next";

const HowToPlay = React.memo(function () {
    const {t} = useTranslation();

    return (
        <div className='how-to-play'>
            <h1>{t('howToPlay.title')}</h1>
            <p>
                {t('howToPlay.part_1')}
            </p>
            <p className='bold'>
                {t('howToPlay.part_2')}
            </p>
            <p>{t('howToPlay.part_3')}</p>
            <p>{t('howToPlay.part_4')}</p>
            <h1>{t('howToPlay.subtitle_1')}</h1>
            <div className="overflow-x">
                <div className="table bold">
                    <div className="table__row">
                        <div className="table__cell">{t('howToPlay.card')}</div>
                        <div className="table__cell">{t('howToPlay.suit_value')}</div>
                        <div className="table__cell">{t('howToPlay.trump_value')}</div>
                        <div className="table__cell">{t('howToPlay.boy_value')}</div>
                    </div>
                    <div className="table__row">
                        <div className="table__cell">A</div>
                        <div className="table__cell">11</div>
                        <div className="table__cell">11</div>
                        <div className="table__cell">19</div>
                    </div>
                    <div className="table__row">
                        <div className="table__cell">10</div>
                        <div className="table__cell">10</div>
                        <div className="table__cell">10</div>
                        <div className="table__cell">10</div>
                    </div>
                    <div className="table__row">
                        <div className="table__cell">K</div>
                        <div className="table__cell">4</div>
                        <div className="table__cell">4</div>
                        <div className="table__cell">4</div>
                    </div>
                    <div className="table__row">
                        <div className="table__cell">Q</div>
                        <div className="table__cell">3</div>
                        <div className="table__cell">3</div>
                        <div className="table__cell">3</div>
                    </div>
                    <div className="table__row">
                        <div className="table__cell">J</div>
                        <div className="table__cell">2</div>
                        <div className="table__cell">20</div>
                        <div className="table__cell">2</div>
                    </div>
                    <div className="table__row">
                        <div className="table__cell">9</div>
                        <div className="table__cell">0</div>
                        <div className="table__cell">14</div>
                        <div className="table__cell">0</div>
                    </div>
                </div>
            </div>
            <p>{t('howToPlay.part_5')}</p>
            <p>{t('howToPlay.part_6')}</p>
            <p>{t('howToPlay.part_7')}</p>
            <h1>{t('howToPlay.subtitle_2')}</h1>
            <p>{t('howToPlay.part_8')}</p>
            <p>{t('howToPlay.part_9')}</p>
            <p>{t('howToPlay.part_10')}</p>
            <p>{t('howToPlay.part_11')}</p>
            <div className="overflow-x">
                <div className="table bold">
                    <div className="table__row">
                        <div className="table__cell">{t('howToPlay.part_12')}</div>
                        <div className="table__cell">{t('howToPlay.part_13')}</div>
                        <div className="table__cell">{t('howToPlay.part_14')}</div>
                    </div>
                    <div className="table__row">
                        <div className="table__cell">A</div>
                        <div className="table__cell">11</div>
                        <div className="table__cell">19</div>
                    </div>
                    <div className="table__row">
                        <div className="table__cell">K, Q, 10</div>
                        <div className="table__cell">10</div>
                        <div className="table__cell">10</div>
                    </div>
                    <div className="table__row">
                        <div className="table__cell">j</div>
                        <div className="table__cell">20</div>
                        <div className="table__cell">10</div>
                    </div>
                    <div className="table__row">
                        <div className="table__cell">9</div>
                        <div className="table__cell">14</div>
                        <div className="table__cell">0</div>
                    </div>
                </div>
            </div>
            <p>{t('howToPlay.part_15')}</p>
            <h1>{t('howToPlay.subtitle_3')}</h1>
            <p>{t('howToPlay.part_16')}</p>
            <h1>{t('howToPlay.subtitle_4')}</h1>
            <p>{t('howToPlay.part_17')}</p>
            <h1>{t('howToPlay.subtitle_10')}</h1>
            <p>{t('howToPlay.part_27')}</p>
            <h1>{t('howToPlay.subtitle_11')}</h1>
            <p>{t('howToPlay.part_28')}</p>
            {/*<h1>{t('howToPlay.subtitle_5')}</h1>*/}
            {/*<p >{t('howToPlay.part_18')}</p>*/}
            <h1>{t('howToPlay.subtitle_6')}</h1>
            <p>{t('howToPlay.part_19')}</p>
            <h1>{t('howToPlay.subtitle_13')}</h1>
            <p>{t('howToPlay.part_21')}</p>
            <h1>{t('howToPlay.subtitle_12')}</h1>
            <p>{t('howToPlay.part_23')}</p>
            <h1>{t('howToPlay.subtitle_7')}</h1>
            <p>{t('howToPlay.part_24')}</p>
            <h1>{t('howToPlay.subtitle_8')}</h1>
            <p>{t('howToPlay.part_25')}</p>
            <h1>{t('howToPlay.subtitle_9')}</h1>
            <p>{t('howToPlay.part_26')}</p>
        </div>
    )
})

export default HowToPlay