import CreateRoomSelect from "./CreateRoomSelect/CreateRoomSelect";
import {useState} from "react";
import Button from "../../../core/Button/Button";
import CreateRoomConventions from "./CreateRoomConventions";
import {useDispatch, useSelector} from "react-redux";
import {BANK_TIMES, PLAYING_TILLS, STEP_TIMES, GAME_CONVENTIONS} from "../../../constants"
import {useTranslation} from "react-i18next";
import {closeLobbyModal} from "../../../redux/actions/lobby/actions";
import CreateRoomInput from "./CreateRoomInput/CreateRoomInput";
import {createRoom} from "../../../redux/actions/cash/actions";

const initialState = {
    step_time: STEP_TIMES[0],
    bank_time: BANK_TIMES[0],
    till_value: PLAYING_TILLS[0],
    game_type: GAME_CONVENTIONS[0][0].value,
    bet: 20,
    without_boy: false,
    private_game: false,
}
function CreateForm() {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [formData, setFormData] = useState(initialState);
    const isMobile= useSelector(state => state.user.isMobile)
    const handleConventionsChange = (data) => {
        setFormData({
            ...formData,
            ...data,
        })
    }
    const onCreate = () => {
        if (isMobile) dispatch(closeLobbyModal());
        dispatch(createRoom(formData))
    }

    return (
        <div className="create-room-wrapper">
            <div className="create-room-row">
                <CreateRoomInput
                    name="bet"
                    label={t("form.bet")}
                    placeholder={t("form.bet")}
                    handleConventionsChange={handleConventionsChange}
                    value={formData.bet}
                />
                <CreateRoomSelect
                    name={'till_value'}
                    label={t("form.till")}
                    data={PLAYING_TILLS}
                    handleChange={handleConventionsChange}
                    value={formData.till_value}
                />
            </div>
            <div className="create-room-row">
                <CreateRoomSelect
                    name={'step_time'}
                    label={t("form.step_time")}
                    data={STEP_TIMES}
                    handleChange={handleConventionsChange}
                    value={formData.step_time}
                />
                <CreateRoomSelect
                    name={'bank_time'}
                    label={t("form.bank_time")}
                    data={BANK_TIMES}
                    handleChange={handleConventionsChange}
                    value={formData.bank_time}
                />
            </div>
            <CreateRoomConventions handleConventionsChange={handleConventionsChange}/>
            <div className="create-room-row">
                <Button type="green" text={t("form.create_btn")} onClick={onCreate}/>
            </div>

        </div>
    )
}

export default CreateForm