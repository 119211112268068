import React from 'react';
import Timer from "./Timer";
import Dialog from "./Dialog";
import Buttons from "./Buttons";
import Avatar from "../../../../core/Avatar/Avatar";
import {SUIT_VALUES} from "../../../../constants";
import {useSelector} from "react-redux";

function Player(props) {
    const {
        playerData,
        playable,
        gameTypes = [],
        position,
        canPassAllCards,
        historyRoomPlayer,
    } = props;
    const isMobile = useSelector(state => state.user.isMobile);

    return (
        <div className={`player player__${position}  ${playerData?.isTrumpAuthor && !isMobile ? "icon-1" : ""}`}>
            <div className="player--info">
                <div className="player--avatar">
                    <Dialog dialog={playerData?.speech}/>
                    <div
                        className={`
                            player--avatar--content
                            ${playerData?.isTrumpAuthor && playerData?.selectedTrump ? `icon-${SUIT_VALUES[playerData?.selectedTrump]}` : ""}`
                        }>
                        <Avatar/>
                    </div>
                    {
                        !historyRoomPlayer && <Timer
                            roomId={playerData?.roomId}
                            platformId={playerData?.platformId}
                        />
                    }
                </div>
                <div>{playerData?.nickname || playerData?.platformId}</div>
                <Buttons
                    isActive={playerData?.isActive}
                    roomId={playerData?.roomId}
                    gameTypes={gameTypes}
                    canAnnounceCombination={playerData?.canAnnounceCombination}
                    canShowCombination={playerData?.canShowCombination}
                    canPassAllCards={canPassAllCards}
                    playable={playable}
                />
            </div>
        </div>
    )
}

export default Player
