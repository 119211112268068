import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {selectActiveRoomTab} from "../../../../../../redux/actions/lobby/actions";
import Button from "../../../../../../core/Button/Button";
import {roomLeave, closeRoom} from "../../../../../../redux/actions/rooms/actions";
import {useTranslation} from "react-i18next";

function RequestStateButton({row}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const userId = useSelector(state => state.user.id);
    const isMobile = useSelector(state => state.user.isMobile);

    return (
        <div className="table-buttons-wrapper">
            {
                row?.table_room_player2 === userId ?

                    !isMobile ?

                        <div className="button-size">
                            <Button onClick={() => dispatch(roomLeave({roomId: row?.table_room_id}))} type={"blue-border"}
                                    text={t('buttons.leave')}/>
                        </div> :
                        <i className="icon-arrow icon-right"
                           onClick={() => dispatch(selectActiveRoomTab({roomId: row?.table_room_id}))}/> :

                    row?.table_room_player === userId ?

                        !isMobile ?
                            <>
                                <i className="icon-close close-hover" onClick={() => dispatch(closeRoom({roomId:row?.table_room_id}))}/>
                                <div className="button-size">
                                    <Button
                                        onClick={() => dispatch(selectActiveRoomTab({roomId: row?.table_room_id}))}
                                        type={"blue-border"} text={t('buttons.back')}/>
                                </div>
                            </> :

                            <i className="icon-arrow icon-right"
                               onClick={() => dispatch(selectActiveRoomTab({roomId: row?.table_room_id}))}/> :

                        !isMobile ?
                            <div className="button-size">
                                <span>Full</span>
                            </div> :
                            <i className="icon-settings"/>
            }
        </div>
    )
}

export default RequestStateButton