import React from 'react';
import {useDispatch} from "react-redux";
import {closeRoom} from "../../../../redux/actions/rooms/actions";
import BaseModal from "./BaseModal";
import Button from "../../../../core/Button/Button";
import Spinner from "../../../../core/Spinner/Spinner";
import {useTranslation} from "react-i18next";

function WaitingForPlayerModal({room}) {
    const dispatch = useDispatch();
    const {t} =useTranslation()
    return (
        <BaseModal>
            <div className="modal__waiting">
                <div className="modal__waiting__top">
                    <Spinner/>
                    <p>{t("modals.waiting.text")}</p>
                </div>
                <Button text={t("modals.buttons.close")} type={'red'} onClick={() => dispatch(closeRoom({roomId:room.roomId}))}/>
            </div>
        </BaseModal>
    )
}

export default WaitingForPlayerModal
