import React, {useState} from 'react';
import PointBarItem from "./PointBarItem";
import {useTranslation} from "react-i18next";
import {GAME_CONVENTIONS} from "../../../../constants";
import {useSelector} from "react-redux";

const PointBar = function (props) {
    const {
        topPlayerId,
        bottomPlayerId,
        roomId,
        conventions,
        isInGame,
        currency,
        points,
    } = props;

    const {t} = useTranslation()
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useSelector(state => state.user.isMobile);
    const getPointBarItemById = (playerId) => {
        return Object.values(points?.finishedRoundsInfo).map(round => {
            const {points, trump: trumpData} = round;
            const gbPoint = points[playerId] || {
                "handPoints": 0,
                "combinationPoints": 0,
                "globalPoints": 0
            };
            const trump = (trumpData && trumpData[playerId]) || null
            return {gbPoint, trump}
        })
    }
    return (
        <div className={`point-bar ${isOpen ? "open" : ""}`}>
            <div className="point-bar__wrapper">
                <div className="point-bar__left">
                    {
                        isOpen ? <>
                                <div className="point-bar__left--players">
                                    <p className={"point-bar__user"}>
                                        {isInGame ? t("point_bar.you") : topPlayerId}
                                    </p>
                                    <p className={"point-bar__user"}>
                                        {isInGame ? t("point_bar.me") : bottomPlayerId}
                                    </p>
                                </div>
                                <div className="point-bar__left--wrapper">
                                    <ul className='point-bar__list'>
                                        {
                                            getPointBarItemById(topPlayerId)?.map((item, index) => {
                                                return (
                                                    <PointBarItem
                                                        key={index}
                                                        point={item.gbPoint}
                                                        trump={item.trump}
                                                    />
                                                )
                                            })
                                        }
                                    </ul>
                                    <ul className='point-bar__list'>
                                        {
                                            getPointBarItemById(bottomPlayerId)?.map((item, index) => {
                                                return (
                                                    <PointBarItem
                                                        key={index}
                                                        point={item.gbPoint}
                                                        trump={item.trump}
                                                    />
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </> :
                            <>
                            <div className="point-bar__left--item">
                                    <h6 className="item__heading">{t("point_bar.id")}</h6>
                                    <p className="item__text">ID{roomId}</p>
                                </div>
                                <div className="point-bar__left--item">
                                    <h6 className="item__heading">{t("point_bar.till")}</h6>
                                    <p className="item__text">{conventions.tillValue}</p>
                                </div>
                                <div className="point-bar__left--item">
                                    <h6 className="item__heading">{t("point_bar.bet")}</h6>
                                    <p className="item__text">{conventions.betAmount[currency]}{currency}</p>
                                </div>
                                <div className="point-bar__left--item">
                                    <h6 className="item__heading">{t("point_bar.convention")}</h6>
                                    <ul className="collapsed__icons">
                                        {
                                            conventions?.gameTypes.map((type, index) => {
                                                const convention = GAME_CONVENTIONS.flat().find((item) => item.value === type)
                                                return <i
                                                    key={index}
                                                    className={`icon-${type}`}
                                                    title={t(`form.${convention?.label}`)}/>
                                            })
                                        }
                                    </ul>
                                </div>
                            </>
                    }
                </div>
                {!isMobile ? (
                    <div className="point-bar__right">
                        <p className="point-bar__right--score">{points?.global[topPlayerId] || 0}</p>
                        <p className="point-bar__right--score">{points?.global[bottomPlayerId] || 0}</p>
                    </div>
                ) : !isOpen && (
                    <div className="point-bar__right">
                        <div className={"point-bar__right--item"}>
                            <p>{isInGame ? t("point_bar.you") : topPlayerId}</p>
                            <p className="point-bar__right--score">{points?.global[topPlayerId] || 0}</p>
                        </div>
                        <div className={"point-bar__right--item"}>
                            <p>{isInGame ? t("point_bar.me") : bottomPlayerId}</p>
                            <p className="point-bar__right--score">{points?.global[bottomPlayerId] || 0}</p>
                        </div>
                    </div>
                )}
                <i className={`point-bar__arrow icon-arrow ${isOpen && "icon-rotate"}`}
                   onClick={() => setIsOpen(!isOpen)}/>
            </div>

        </div>
    )
}

export default PointBar
