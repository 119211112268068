import React from "react";
import {useSelector} from "react-redux";
import DesktopActiveRoomTabItem from "./DesktopActiveRoomTabItem";

function DesktopActiveRoomTabs() {
    const enteredRooms = useSelector(store => store.rooms.entered);
    return <div className="header-active-games">
        <div className="header-active-games-wrapper">
            {
                Object.keys(enteredRooms)?.map((roomId, index) => (
                    <DesktopActiveRoomTabItem
                        key={index}
                        id={roomId}
                        isViewRoom={enteredRooms[roomId]?.isViewRoom}
                        isHistoryRoom={enteredRooms[roomId]?.historyRoomPlayer}
                        isTournamentRoom={enteredRooms[roomId]?.tournamentId}
                    />
                ))
            }
        </div>
    </div>
}

export default DesktopActiveRoomTabs;
