import Table from "../../../../core/Table/Table";
import {ROOM_TABLE_TITLES} from "../../../../constants";

function DesktopRoomList(props) {
   const {rows, currency} = props;
    return <Table
        name={'Rooms'}
        columns={ROOM_TABLE_TITLES}
        rows={rows}
        currency={currency}
    />
}
export default DesktopRoomList