import './styles/App.scss';
import {useSelector} from "react-redux";
import {useEffect} from "react";
import Main from "./game/Main";
const App = ({changeLanguage}) => {
    const lang = useSelector(state => state.user.lang);
    useEffect(() => {
        if(lang) {
            changeLanguage(lang)
        }
    }, [lang, changeLanguage]);
    return <Main/>

}
export default App;