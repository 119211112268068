function Input(props) {
    const {
        name = "",
        value = "",
        error = "",
        placeholder,
        type,
        label,
        handleChange,
        onBlur
    } = props;

    let timeOutId = null

    const onChange = (e) => {
        timeOutId && clearTimeout(timeOutId)
        timeOutId = setTimeout(() => {
            handleChange(e);
        }, 80)
    }

    return (
        <div className="input-wrapper">
            {!error && label && <label> {label}</label>}
            {error && <label> {error}</label>}
            <input className={`input ${type || ""}`}
                   type='text'
                   placeholder={placeholder}
                   value={value}
                   onChange={(e) => onChange(e.target.value)}
                   onBlur={onBlur}
                   name={name}
                   autoComplete="off"
            />
        </div>
    )
}

export default Input