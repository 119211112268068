const types = {
    INITIAL_LOAD: 'setInitialData',
    SET_TOURNAMENTS: 'setTournaments',
    UPDATE_ROOMS: 'updateTournamentRooms',
    JOIN_TOURNAMENT: 'joinTournament',
    QUIT_TOURNAMENT: 'quitTournament',
    UPDATE_PLAYERS: 'updatePlayerList',
    SET_NEW_TOURNAMENT: 'setNewTournament',
    OPEN_TOURNAMENT_REGISTRATION: 'openTournamentRegistration',
    UPDATE_TOURNAMENT: 'updateTournament',
    SET_SIDEBAR_TOURNAMENT_ID: 'setSideBarTournamentId',
    UPDATE_TOURNAMENT_ROUND_TIMER: 'updateTournamentRoundTimer',
    DELETE_TOURNAMENT: 'deleteTournament',
    UPDATE_TOP_WINNERS: 'updateTopWinners',
    SET_TOURNAMENT_ACTIVE_ROOMS_ID: 'toggleTournamentActiveRooms',
};
export default types
