import {useDispatch} from "react-redux";
import {closeLobbyModal} from "../../../../../redux/actions/lobby/actions";
import {setActivePage} from "../../../../../redux/actions/lobby/actions";
import {useState} from "react";
import useAudio from "../../../../../hooks/Audio";
import {useTranslation} from "react-i18next";

function SideMenuMobile() {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [muted, setSetMuted] = useState(localStorage.getItem('isMuted') || 1);
    const {toggleVolume} = useAudio();
    const handleOpenMenu = () => {
        setMenuOpen(true)
    }
    const handleCloseMenu = () => {
        dispatch(closeLobbyModal());
        setMenuOpen(false)
    }

    const handleClick = (name) => {
        setMenuOpen(false)
        dispatch(setActivePage({activePage: name}))
    }
    const toggleMuted = () => {
        const toggleData = toggleVolume();
        if (toggleData !== muted) setSetMuted(toggleData)

    }
    return (
        <div className="mobile-header-menu">
            <i className="icon-burger" onClick={handleOpenMenu}/>
            <div className={`mobile-header-menu-content ${isMenuOpen ? "open" : ""}`}>
                <div className="mobile-header-menu-colse" onClick={handleCloseMenu}>
                    <i className="icon-close"/>
                </div>
                <ul className="mobile-header-menu__options">
                    <li className="mobile-header-menu__options--item"
                        onClick={()=>handleClick("Cash")}>
                        <i className="icon-home"/>
                        <span className="title">{t('mobile_side_menu.home')}</span>
                    </li>
                    <li className="mobile-header-menu__options--item"
                        onClick={()=>handleClick("HowToPlay")}>
                        <i className="icon-0"/>
                        <span className="title">{t('mobile_side_menu.how_to_play')}</span>
                    </li>
                    <li className="mobile-header-menu__options--item"
                    onClick={()=> handleClick("History")}>
                        <i className="icon-history"/>
                        <span className="title">{t('mobile_side_menu.history')}</span>
                    </li>
                    <li className="mobile-header-menu__options--item"
                        onClick={()=>handleClick("MobileSettings")}>
                        <i className="icon-settings"/>
                        <span className="title">{t('mobile_side_menu.settings')}</span>
                    </li>
                    <li className="mobile-header-menu__options--item" onClick={toggleMuted}>
                        <i className={`${Number(muted) ? 'icon-volOff' : 'icon-vol'}`}/>
                        <span className="title">{t('mobile_side_menu.volume')}</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SideMenuMobile