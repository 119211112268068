import Input from "../../../../core/Input/Input";
import React from "react";
import {useSelector} from "react-redux";

function CreateRoomInput({name, placeholder, label, handleConventionsChange, value}) {

    const {min, max} = useSelector(state => state.user.betRanges);

    const onInputChange = (value=  '') => {
        if (value.split('').some(char => isNaN(parseInt(char)))) return;
        const regex = /^(\d+(\.\d{0,2})?)?$/;
        const regexPattern = /^(0\.00|0[1-9]|00)$/;
        if (!regex.test(value)) return;
        let resultValue = regexPattern.test(value) ? min : value > max ? max : value;
        handleConventionsChange({[name]: resultValue})
    }

    const handleBlur = (e) => {
        const value = e.target.value;
        if(!parseFloat(value) || parseFloat(value) < min) handleConventionsChange({[name]: min});
    }


    return (
        <>
            <Input
                type="createRoom"
                name={name}
                label={label}
                placeholder={placeholder}
                handleChange={onInputChange}
                value={value}
                onBlur={handleBlur}
            />
        </>
    )
}

export default CreateRoomInput;