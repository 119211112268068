import {useTranslation} from "react-i18next";
import {getShortUniqueKey} from "../../../utils";

function TableHeader({columns}) {
    const {t} = useTranslation()

    return (
        <div className="table-header">
            {columns?.map((item) => {
                    return (
                        <div
                            key={getShortUniqueKey()}
                            className="table-header--cell"
                        >
                            {t(`table.${item}`)}
                        </div>
                    )
            })}
        </div>
    )
}

export default TableHeader