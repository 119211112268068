import React from 'react';
import Card from "../Card";
import {roomAcceptTrump, roomRejectTrump} from "../../../../redux/actions/rooms/actions";
import {useDispatch} from "react-redux";
import Button from "../../../../core/Button/Button";
import {useTranslation} from "react-i18next";

function TrumpCard({roomId, offeredCard, canReject}) {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    return (<>
            <div className="modal__trump-select--top">
                <p>{t("modals.trump_select.text")}</p>
                <Card
                    roomId={roomId}
                    playable={false}
                    data={offeredCard}
                    className={`card card-name_${offeredCard.suit}${offeredCard.rank.name}`}
                />
            </div>
            <div className="modal__trump-select--buttons">
                <Button text={t("modals.buttons.yes")} type="yellow" onClick={() => dispatch(roomAcceptTrump({roomId}))}/>
                {canReject && <Button text={t("modals.buttons.no")} type="blue" onClick={() => dispatch(roomRejectTrump({roomId}))}/>}
            </div>
        </>
    )
}

export default TrumpCard
