import {useSelector} from "react-redux";
import React from "react";
import Slider from "../../components/Slider/Slider";
import Tabs from "../../components/Tabs/Tabs";
import {TABS} from "../../../constants";

const PageWrapper = function ({children}) {
    const isMobile = useSelector(state => state.user.isMobile);
    const activePage = useSelector(state => state.lobby.activePage);
    return (
        (isMobile) ? (
            <div className="mobile-content">
                {
                    !["HowToPlay", "MobileSettings","History"].includes(activePage) && <>
                        <div className="slider-wrapper">
                            <Slider/>
                        </div>
                        <Tabs tabItems={TABS}/>
                    </>
                }
                <>{children}</>
            </div>
        ) : (
            <>{children}</>
        )
    );
};

export default PageWrapper;
