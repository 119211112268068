import LOBBY from "./types";

export const setActivePage = (payload) => {
    return {
        type: LOBBY.SET_ACTIVE_PAGE,
        payload
    }
};

export const selectActiveRoomTab = (payload) => {
    return {
        type: LOBBY.SELECT_ACTIVE_ROOM_TAB,
        payload
    }
};

export const setPromoData = (payload) => {
    return {
        type: LOBBY.SET_PROMO_DATA,
        payload
    }
};
export const lobbyOpenModal = (payload) => {
    return {
        type: LOBBY.OPEN_MODAL,
        payload
    }
};

export const closeLobbyModal = (payload) => {
    return {
        type: LOBBY.CLOSE_MODAL,
        payload
    }
};
