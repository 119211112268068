import USER from "./types";
export const userIsMobile = (payload) => {
    return {
        type: USER.IS_MOBILE,
        payload
    }
};
export const userUpdateNickName = (payload) => {
    return {
        type: USER.UPDATE_NICK_NAME,
        payload
    }
};
export const userUpdateNickNameFail = (payload) => {
    return {
        type: USER.UPDATE_NICK_NAME_FAIL,
        payload
    }
};
export const userUpdateNickNameSuccess = (payload) => {
    return {
        type: USER.UPDATE_NICK_NAME_SUCCESS,
        payload
    }
};
export const userClearNickNameError = () => {
    return {
        type: USER.CLEAR_NICK_NAME_ERROR,
    }
};
export const userSetData = (payload) => {
    return {
        type: USER.SET_DATA,
        payload
    }
};
export const userUpdateBalance = (payload) => {
    return {
        type: USER.UPDATE_BALANCE,
        payload
    }
};