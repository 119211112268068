import FilterSelect from "../../components/FilterSelect/FilterSelect";
import {HISTORY_TYPE, PLAYING_TILLS, HISTORY_RESULT} from "../../../../../constants";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import FilterInput from "../../components/FilterInput/FilterInput";
import {setHistoryFilter} from "../../../../../redux/actions/history/actions";
import Button from "../../../../../core/Button/Button";
import {closeLobbyModal} from "../../../../../redux/actions/lobby/actions";

function MobileHistoryFilter() {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const filter = useSelector(state => state.history.filter);

    const mapTranslatedOptions = (options, translateLabel = '') => {
        return options.map((option) => {
            return {...option, label: t(`${translateLabel}.${option.label}`)}
        })
    }

    const handleChange = (data) => {
        dispatch(setHistoryFilter(data))
    }

    const closeHistoryModal = () => {
        dispatch(closeLobbyModal());
    }

    const applyFilters = () => {

    }

    const resetFilters = () => {
        dispatch(closeLobbyModal());
    }

    return <div className="mobile-history-filter--wrapper">
        <div className="mobile-history-filter--row">
            <i
                className="icon-close"
                onClick={() => closeHistoryModal()}
            />
        </div>
        <div className="mobile-history-filter--row">
            <FilterSelect
                data={mapTranslatedOptions(HISTORY_RESULT, "filter")}
                placeholder={t("filter.history_result")}
                handleChange={handleChange}
                name="result"
            />
            <FilterSelect
                data={PLAYING_TILLS}
                placeholder={t("filter.history_till")}
                handleChange={handleChange}
                name="till"
            />
        </div>
        <div className="mobile-history-filter--row">
            <FilterInput
                placeholder={t("filter.min_bet")}
                handleChange={handleChange}
                name="minBet"
                value={filter.minBet}/>
            <FilterInput
                placeholder={t("filter.max_bet")}
                handleChange={handleChange}
                name="maxBet"
                value={filter.maxBet}
            />
        </div>
        <div className="mobile-history-filter--row">
            <FilterSelect
                data={mapTranslatedOptions(HISTORY_TYPE, "filter")}
                placeholder={t("filter.history_type")}
                handleChange={handleChange}
                name="type"
            />
        </div>
        <div className="mobile-history-filter--row">
            <Button
                type={"blue"}
                text={t("filter.apply")}
                onClick={applyFilters}
            />
            <Button
                type={"lightblue"}
                text={t("filter.clear_all")}
                onClick={resetFilters}
            />
        </div>
    </div>
}

export default MobileHistoryFilter;