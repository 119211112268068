import USER from "../actions/user/types";
import GAME from "../actions/lobby/types";
import UserSocket from "../../sockets/user-socket";

const initialState = {
    id: null,
    integratorId: null,
    lang: "ru",
    balance: 0,
    currency: 'AMD',
    userName: '',
    isMobile: false,
    nickname: {
        value: '',
        isVerified: false
    },
    errors: {},
    betRanges: {
        min: 20,
        max: 100000
    }
};

const reducer = (state = initialState, action) => {
    const socket = UserSocket.debouncedEmit.bind(UserSocket);

    switch (action.type) {

        case  USER.UPDATE_NICK_NAME:
            socket(GAME.EVENT, {action: action.type, payload: action.payload, ctx: 'User'})
            return state;

        case  USER.UPDATE_NICK_NAME_FAIL:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    nickname: action.payload.error
                },
                nickname: {
                    ...state.nickname,
                    isVerified: false
                }
            }

        case  USER.UPDATE_NICK_NAME_SUCCESS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    nickname: null
                },
                nickname: {
                    ...state.nickname,
                    ...action.payload,
                }
            }

        case  USER.CLEAR_NICK_NAME_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    nickname: null
                },
                nickname: {
                    ...state.nickname,
                    isVerified: true
                }
            }

        case  USER.SET_DATA:
            return {
                ...state,
                ...action.payload
            }

        case  USER.UPDATE_BALANCE:
            return {
                ...state,
                ...action.payload
            }

        case  USER.IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload.isMobile,
                isMobileLandscape: action.payload.isMobileLandscape
            };

        default:
            return state;
    }
};

export default reducer;
