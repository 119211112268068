import * as lobby from './lobby/actions';
import * as userActions from './user/actions';
import * as cashActions from './cash/actions';
import * as roomsActions from './rooms/actions';
import * as tournamentActions from './tournament/actions';
import * as historyActions from './history/actions';

let combinedActions = {
    ...lobby,
    ...userActions,
    ...cashActions,
    ...roomsActions,
    ...tournamentActions,
    ...historyActions,
}
export default combinedActions