import combinedButtons from "../../components/Buttons/RoomButtons";
import {useTranslation} from "react-i18next";

function RoomListItemMobile({data, currency}) {
    const {t} = useTranslation();
    const Button = combinedButtons[data?.table_room_status];
    return (
        <div className="mobile-created-game-row">
            <div className="mobile-created-game-left">
                <div className="mobile-created-game-left__top">
                    <span className="text">ID {data?.table_room_id}</span>
                    <span className="text">{data?.table_room_bet[currency]} <span>{currency}</span></span>
                </div>
                <div className="mobile-created-game-left__bottom">
                    <span className="text">{t('table.table_room_till')}: {data?.table_room_till} | {t('table.table_room_step_time')}: {data?.table_room_stepTime} {t('table.s')} / {data?.table_room_bankTime} {t('table.s')}</span>
                    <i className="icon-40"/>
                </div>
            </div>
            <div className="mobile-created-game-right">
                <Button roomId={data?.table_room_id} row={data}/>
            </div>
        </div>
    )
}

export default RoomListItemMobile