import TournamentParticipantItem from "./TournamentParticipantItem";
import {useTranslation} from "react-i18next";

function TournamentParticipants({participants = []}) {
    const {t} = useTranslation();
    return (
        <div className="tournament-info-players">
            <p className="tournament-info-prize--title">{t('tournament.participants')}</p>
            <ul className="tournament-info-players--content">
                <li className="tournament-info-players--header">
                        <span>{t('tournament.place')}</span>
                        <span>ID</span>
                </li>
                {
                    participants?.sort((p1, p2) => p1.place - p2.place)?.map((item, index) => {
                        return <TournamentParticipantItem
                            key={index}
                            item={item}
                        />
                    })
                }
            </ul>
        </div>
    )
}

export default TournamentParticipants