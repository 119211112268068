import CashTournamentListItem from "./CashTournamentListItem/CashTournamentListItem";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
function CashTournamentList() {
    const {t} = useTranslation();
    const list = useSelector(state => state.tournament.list);
    const currency = useSelector((state) => state.user.currency);
    const tournaments = Object.values(list).filter(tournament => tournament?.info?.visibleForCash && tournament?.info?.status === 'PENDING') || [];
    if (!tournaments.length) return
    return (
        <div className="cash-lobby-tournament">
            <h1>{t('tournament_title')}</h1>
            <ul className="cash-lobby-tournament--group">
                {
                    tournaments?.map((tournament, index) => {
                        return <CashTournamentListItem
                            key={index}
                            data={tournament?.info}
                            currency={currency}
                        />
                    })
                }
            </ul>
        </div>
    )
}

export default CashTournamentList