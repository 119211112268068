function SettingsAvatar(props) {
    const {
        item,
        name,
        isActive,
        onItemClick = () => {}
    } = props;

    return (
        <li
            className={`avatar ${isActive ? "avatar--active" : ""}`}
            onClick={() => onItemClick()}
        >
            <img src={item} alt={name} loading="lazy"/>
        </li>
    )
}

export default SettingsAvatar