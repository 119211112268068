/* eslint-disable */
import React, {useState, useCallback} from 'react';
import {roomAcceptTrump, roomRejectTrump} from "../../../../redux/actions/rooms/actions";
import {useDispatch} from "react-redux";
import Suit from "../Suits";
import Button from "../../../../core/Button/Button";
import Checkbox from "../../../../core/Checkbox/Checkbox";
import {useTranslation} from "react-i18next";

const OtherTrump = React.memo(function ({roomId, canReject, suits}) {
    const dispatch = useDispatch();
    const [otherTrumpSuit, setTrumpSuit] = useState(null);
    const [withOfferedCard, setWithOfferedCard] = useState(false);
    const {t} = useTranslation();

    const handleCheckboxChange = useCallback(() => {
        setWithOfferedCard(!withOfferedCard);
    }, [withOfferedCard]);

    const handleTrumpClick = useCallback((value) => {
        setTrumpSuit((prev) => (value === prev ? null : value));
    }, [otherTrumpSuit]);

    const handleAccept = useCallback(() => {
        if (!otherTrumpSuit) return;
        dispatch(roomAcceptTrump({roomId, otherTrumpSuit, withOfferedCard}));
    }, [dispatch, roomId, otherTrumpSuit, withOfferedCard]);

    const handleReject = useCallback(() => {
        dispatch(roomRejectTrump({roomId}));
    }, [dispatch, roomId]);

    return (
        <>
            <div>
                <p>{t(`${canReject ? "modals.trump_select.other_text" : "modals.trump_select.other_reject_text"}`)}</p>
                <div className={"modal__trump-select--suits"}>
                    {suits?.map((value, index) => (
                        <Suit
                            key={index + value}
                            value={value}
                            selected={otherTrumpSuit === value}
                            handleClick={handleTrumpClick}
                        />
                    ))}
                </div>
            </div>
            <div onClick={handleCheckboxChange}>
                <Checkbox title={t("modals.trump_select.checkbox_text")}  checked={withOfferedCard}/>
            </div>
            <Button
                type="yellow"
                text={t("modals.buttons.accept")}
                onClick={handleAccept}
            />
            {canReject &&
                <Button
                    type={"blue"}
                    text={t("modals.buttons.reject_other_card")}
                    onClick={handleReject}
                />
            }
        </>
    );
}, (prevProps, nextProps) => {
    return prevProps.suits === nextProps.suits && prevProps.canReject === nextProps.canReject;
});

export default OtherTrump;
