function SettingsBoardItem(props) {
    const {
        item,
        isActive,
        onItemClick = () => {}
    } = props;
    return (
        <li
            className={`settings__boards--item ${isActive ? "active" : ""}`}
            onClick={() => onItemClick()}>
            <span className={`settings__boards--inner settings__boards--inner--${item}`}/>
        </li>
    )
}
export default SettingsBoardItem