import RoomListItemMobile from "./RoomListItemMobile/RoomListItemMobile";

function MobileRoomList(props) {
    const {rows, currency} = props;
    return <div className="mobile-created-games">
        {
            rows?.map((row, index) => {
                return <RoomListItemMobile
                    key={index}
                    data={row}
                    currency={currency}
                />
            })
        }
    </div>
}

export default MobileRoomList