import {PLACE_COLORS} from "../../../../constants";

function TournamentPrizePoolItem({pool, prize, currency}) {
    const {percent, place_from, place_to} = pool;
    const handlePrizeByPercent = (prize, percent) => {
        const prizeByPlace = prize * parseFloat(percent) / 100;
        return prizeByPlace.toFixed(2)
    }
    const handlePlace = (place_from, place_to) => {
        if (place_to <= 2) return place_to;
        return `${place_from} - ${place_to}`
    }
    const placeClass = PLACE_COLORS[place_to] ? `${PLACE_COLORS[place_to]} place` : ''

    return (
        <li className="tournament-info-prize--item">
                        <span className={placeClass}>
                            {handlePlace(place_from, place_to)}
                        </span>
            <span>{`${percent}% / (${handlePrizeByPercent(prize, percent)} ${currency})`}</span>
        </li>
    )
}

export default TournamentPrizePoolItem