import FilterSelect from "../../components/FilterSelect/FilterSelect";
import {HISTORY_TYPE, PLAYING_TILLS, HISTORY_RESULT} from "../../../../../constants";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import FilterInput from "../../components/FilterInput/FilterInput";
import {setHistoryFilter} from "../../../../../redux/actions/history/actions";
function DesktopHistoryFilter() {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const filter = useSelector(state => state.history.filter);
    const mapTranslatedOptions = (options, translateLabel = '') => {
        return options.map((option) => {
            return {...option, label: t(`${translateLabel}.${option.label}`)}
        })
    }
    const handleChange = (data) => {
        dispatch(setHistoryFilter(data))
    }
    return <div className="history-filter">
        <div className="history-filter__left">
            <FilterSelect
                data={mapTranslatedOptions(HISTORY_TYPE, "filter")}
                placeholder={t("filter.history_type")}
                handleChange={handleChange}
                name="type"
            />
            <FilterSelect
                data={mapTranslatedOptions(HISTORY_RESULT, "filter")}
                placeholder={t("filter.history_result")}
                handleChange={handleChange}
                name="result"
            />
            <FilterSelect
                data={PLAYING_TILLS}
                placeholder={t("filter.history_till")}
                handleChange={handleChange}
                name="till"
            />
        </div>
        <div className="history-filter__right">
            <span className="filterBet">{t("filter.bet")}:</span>
            <FilterInput
                placeholder={t("filter.min_bet")}
                handleChange={handleChange}
                name="minBet"
                value={filter.minBet}/>
            <FilterInput
                placeholder={t("filter.max_bet")}
                handleChange={handleChange}
                name="maxBet"
                value={filter.maxBet}
            />
        </div>
        <i
            className="delete-filters icon-bin1"
            title={t("filter.clear_all")}
            onClick={() => dispatch(setHistoryFilter())}
        />
    </div>
}

export default DesktopHistoryFilter;