import SettingsAvatar from "./components/SettingItem/SettingsAvatar/SettingsAvatar";
import SettingsCardItem from "./components/SettingItem/SettingsCardItem/SettingsCardItem";
import SettingsBoardItem from "./components/SettingItem/SettingsBoardItem/SettingsBoardItem";

const combinedSettingItems = {
    "avatar": SettingsAvatar,
    "card-front": SettingsCardItem,
    "card-back": SettingsCardItem,
    "board": SettingsBoardItem
}
export default combinedSettingItems