import React from "react";
import {useSelector} from "react-redux";
import MobileTournamentList from "./MobileTournamentList/MobileTournamentList";
import DesktopTournamentList from "./DesktopTournamentList/DesktopTournamentList";
function TournamentList() {
    const {isMobile, currency} = useSelector((state) => state.user)
    const list = useSelector(state => state.tournament.list)
    const getRows = () => {
        return Object.values(list).map(tournament => tournament.rows)
    }

    return isMobile ?  <MobileTournamentList rows={getRows()} currency={currency}/> : <DesktopTournamentList rows={getRows()} currency={currency}/>

}

export default TournamentList