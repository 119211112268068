import ROOMS from "./types";
export const roomEnter = (payload) => {
    return {
        type: ROOMS.ROOM_ENTER,
        payload
    }
};
export const exitRoom = (payload) => {
    return {
        type: ROOMS.EXIT_ROOM,
        payload
    }
};

export const closeRoom = (payload) => {
    return {
        type: ROOMS.CLOSE_ROOM,
        payload
    }
};
export const restoreRooms = (payload) => {
    return {
        type: ROOMS.RESTORE_ENTERED_ROOMS,
        payload
    }
};

export const roomRejectOpponent = (payload) => {
    return {
        type: ROOMS.ROOM_REJECT_OPPONENT,
        payload
    }
};

export const roomAcceptOpponent = (payload) => {
    return {
        type: ROOMS.ROOM_ACCEPT_OPPONENT,
        payload
    }
};

export const roomUpdate = (payload) => {
    return {
        type: ROOMS.ROOM_UPDATE,
        payload
    }
};

export const roomViewExit = (payload) => {
    return {
        type: ROOMS.ROOM_EXIT_FROM_VIEW,
        payload
    }
};
export const roomJoinToView = (payload) => {
    return {
        type: ROOMS.ROOM_JOIN_TO_VIEW,
        payload
    }
};
export const roomFinished = (payload) => {
    return {
        type: ROOMS.ROOM_FINISHED,
        payload
    }
};
export const roomAcceptTrump = (payload) => {
    return {
        type: ROOMS.ROOM_ACCEPT_TRUMP,
        payload
    }
};
export const roomRejectTrump = (payload) => {
    return {
        type: ROOMS.ROOM_REJECT_TRUMP,
        payload
    }
};
export const roomSetWaitingDialog = (payload) => {
    return {
        type: ROOMS.ROOM_SET_WAITING_DIALOG,
        payload
    }
};
export const roomUpdateRound = (payload) => {
    return {
        type: ROOMS.ROOM_UPDATE_ROUND,
        payload
    }
};
export const roomPlayCard = (payload) => {
    return {
        type: ROOMS.ROOM_PLAY_CARD,
        payload
    }
};

export const roomPlayedCard = (payload) => {
    return {
        type: ROOMS.ROOM_PLAYED_CARD,
        payload
    }
};

export const roomAnnounceCombination = (payload) => {
    return {
        type: ROOMS.ROOM_ANNOUNCE_COMBINATION,
        payload
    }
};
export const openModal = (payload) => {
    return {
        type: ROOMS.ROOM_OPEN_MODAL,
        payload
    }
};

export const closeModal = (payload) => {
    return {
        type: ROOMS.ROOM_CLOSE_MODAL,
        payload
    }
};
export const roomAnnounceCombinationFailed = (payload) => {
    return {
        type: ROOMS.ROOM_ANNOUNCE_COMBINATION_FAILED,
        payload
    }
};
export const roomAnnounceCombinationRank = (payload) => {
    return {
        type: ROOMS.ROOM_ANNOUNCE_COMBINATION_RANK,
        payload
    }
};

export const roomAnnounceCombinationRankFailed = (payload) => {
    return {
        type: ROOMS.ROOM_ANNOUNCE_COMBINATION_RANK_FAILED,
        payload
    }
};

export const roomSelectCombinationCard = (payload) => {
    return {
        type: ROOMS.ROOM_SELECT_COMBINATION_CARD,
        payload
    }
};

export const roomSetCardsSelectable = (payload) => {
    return {
        type: ROOMS.ROOM_SET_CARDS_SELECTABLE,
        payload
    }
};


export const roomResetSelectedCombination = (payload) => {
    return {
        type: ROOMS.ROOM_RESET_SELECTED_COMBINATION_CARDS,
        payload
    }
};
export const roomPublishCombination = (payload) => {
    return {
        type: ROOMS.ROOM_PUBLISH_COMBINATION,
        payload
    }
};
export const roomPublishCombinationFailed = (payload) => {
    return {
        type: ROOMS.ROOM_PUBLISH_COMBINATION_FAILED,
        payload
    }
};
export const roomGetCombinationCards = (payload) => {
    return {
        type: ROOMS.ROOM_GET_COMBINATION_CARDS,
        payload
    }
};
export const roomSetCombinationCards = (payload) => {
    return {
        type: ROOMS.ROOM_SET_COMBINATION_CARDS,
        payload
    }
};

export const roomAnnounceAllCardsToOpponent = (payload) => {
    return {
        type: ROOMS.ROOM_ANNOUNCE_ALL_CARDS_TO_OPPONENT,
        payload
    }
};

export const roomRequestAllCardsToMe = (payload) => {
    return {
        type: ROOMS.ROOM_REQUEST_ALL_CARDS_TO_ME,
        payload
    }
};
export const roomAcceptAllCardsToMeRequest = (payload) => {
    return {
        type: ROOMS.ROOM_ACCEPT_ALL_CARDS_TO_ME_REQUEST,
        payload
    }
};
export const roomRejectAllCardsToMeRequest = (payload) => {
    return {
        type: ROOMS.ROOM_REJECT_ALL_CARDS_TO_ME_REQUEST,
        payload
    }
};

export const roomAllCardsPassedToOpponent = (payload) => {
    return {
        type: ROOMS.ROOM_ALL_CARDS_PASSED_TO_OPPONENT,
        payload
    }
};
export const roomResetRound = (payload) => {
    return {
        type: ROOMS.ROOM_RESET_ROUND,
        payload
    }
};
export const updateRoomTimer = (payload) => {
    return {
        type: ROOMS.ROOM_UPDATE_TIMER,
        payload
    }
};

export const roomLeave = (payload) => {
    return {
        type: ROOMS.ROOM_LEAVE_WAITING_ROOM,
        payload
    }
};










