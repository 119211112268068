import React from 'react';

function BaseModal({children}) {
    return (
        <div className="modal">
            <div className="modal__wrapper">
                <div className="modal__content">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default BaseModal
