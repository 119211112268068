const types = {
    EVENT: 'gameEvent',
    UPDATE_ROOM_LIST: 'cashUpdateRoomList',
    UPDATE: 'cashUpdate',
    CREATE_ROOM: 'createRoom',
    JOIN_ROOM: 'joinRoom',
    SET_FILTER:"setFilters",
    RESET_FILTER:"resetFilters",
};
export default types
