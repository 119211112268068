import React from 'react';
import RoomList from "../../../components/RoomList";
import Button from "../../../../core/Button/Button";
import {lobbyOpenModal} from "../../../../redux/actions/lobby/actions";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

const MobileCash = React.memo(function () {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    return <div className="cash-lobby-mobile">
        <RoomList/>
        <div className="mobile-lobby-buttons">
            <Button onClick={() => dispatch(lobbyOpenModal({name: 'MobileCreateGameModal'}))} text={t("form.create")}/>
            <i className="icon-filter" onClick={() => dispatch(lobbyOpenModal({name: 'FilterMobile'}))}/>
        </div>
    </div>
});
export default MobileCash