import {useDispatch} from "react-redux";
import ModalBody from "../../../core/Modal/ModalBody/ModalBody";
import ModalHeader from "../../../core/Modal/ModalHeader/ModalHeader";
import {lobbyOpenModal} from "../../../redux/actions/lobby/actions";
import TournamentParticipants from "../TournamentInfo/TournamentParticipants/TournamentParticipants";

function TournamentPlayersModal({participants}) {
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(lobbyOpenModal({name: 'TournamentInfoMobile'}))
    }
    return <>
        <ModalHeader>
            <div className="tournament-players-modal">
                <i className="icon-close"
                   onClick={()=> handleClose()}
                />
            </div>
        </ModalHeader>
        <ModalBody>
            <TournamentParticipants participants={participants} />
        </ModalBody>
    </>
}

export default TournamentPlayersModal