import {components} from "react-select";
import Checkbox from "../../../../../core/Checkbox/Checkbox";

const Option = (props) => {
    return (
        <div className="option-item">
            <components.Option {...props}>
                <label>{props.label}</label>
                <Checkbox checked={props.isSelected}/>
            </components.Option>
        </div>
    );
};
export default Option
