import combinedActions from '../redux/actions/combinedActions'
class UserSockets {
    constructor() {
        this.socket = {};
        this.timeoutId = null
    }
    onConnect(socket) {
        this.socket = socket;
        console.log('client connected to game socket userSocket_line-5');
    }
    disconnectSocket(dispatch) {
        console.log('client disconnected from game socket userSocket_line-21');
    }

    onConnectionError(dispatch, error = 'connection_error') {
        console.log('connection error');
    }
    handleGameSocketEvents(data, dispatch) {
        const {action, payload, isDispatch} = data;
         if(isDispatch) {
             dispatch(combinedActions[action](payload));
             if (action === 'roomEnter') {
                 dispatch(combinedActions['selectActiveRoomTab']({roomId: payload.roomId}))
             }
             if (action === 'exitRoom') {
                 dispatch(combinedActions['selectActiveRoomTab']({roomId: null}))
             }
         }else {
             combinedActions[action](payload)
         }
    }

    debouncedEmit(event, data, interval = 150) {
        if (this.timeoutId) clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(() => {
            this.socket.emit(event, data);
        }, interval) // Emit the event to the server
    }
}
const UserSocket = new UserSockets()
export default UserSocket ;
