import React from "react";
import {useSelector} from "react-redux";
import MobileHistoryFilter from "./MobileHistoryFilter/MobileHistoryFilter";
import DesktopHistoryFilter from "./DeskTopHistoryFilter/DesktopHistoryFilter";

function HistoryFilter() {
    const isMobile = useSelector(state => state.user.isMobile);
    return isMobile ? <MobileHistoryFilter /> : <DesktopHistoryFilter />
}

export default HistoryFilter;