import SettingsForm from "./components/SettingsForm/SettingsForm";
import SettingItem from "./components/SettingItem/SettingItem";
import {useTranslation} from "react-i18next";
import {AVATARS, BOARD_STYLE, CARD_BACK, CARD_FRONT} from "../../../constants";

function Settings() {
    const {t} = useTranslation()
    return (
        <div className="settings" onClick={(e) => e.stopPropagation()}>
            <div className="settings__nikname">
                <h2 className="settings__title">id / {t("settings.nickname")}</h2>
                <SettingsForm/>
            </div>
            <div className="settings__avatars">
                <h2 className="settings__title">{t("settings.avatar")}</h2>
                <SettingItem
                    name={"avatar"}
                    data={AVATARS}
                />
            </div>
            <div className="settings__cards">
                <h2 className="settings__title">{t("settings.card_front")}</h2>
                <SettingItem
                    name={"card-front"}
                    data={CARD_FRONT}
                />
            </div>
            <div className="settings__cards">
                <h2 className="settings__title">{t("settings.card_back")}</h2>
                <SettingItem
                    name={"card-back"}
                    data={CARD_BACK}
                />
            </div>
            <div className="settings__boards">
                <h2 className="settings__title">{t("settings.board_color")}</h2>
                <SettingItem
                    name={"board"}
                    data={BOARD_STYLE}
                />
            </div>
        </div>
    )
}

export default Settings