import React from 'react';
import BaseModal from "./BaseModal";
import {useTranslation} from "react-i18next";

function RoomJoinRejectModal({room}) {
    const {t}=useTranslation();
    return (
        <BaseModal room={room}>
            <div className="modal__join">
                <p>{t("modals.join.reject_text")}</p>
            </div>
        </BaseModal>
    )
}

export default RoomJoinRejectModal
