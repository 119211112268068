function TournamentTopPlayerItem({data}) {
    const {platformId, place, count} = data;
    return (
        <li className="tournament-top-players__item">
            <p className="place">{place}</p>
            <p className="id">
                ID {platformId}
                <span className="points">
                    {count}
                </span>
            </p>
            <i className="icon-cup"/>
        </li>
    )
}

export default TournamentTopPlayerItem