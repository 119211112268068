import React from "react";
import {useSelector} from "react-redux";
import MobileActiveRoomTabs from "./MobileActiveRoomTabs/MobileActiveRoomTabs";
import DesktopActiveRoomTabs from "./DesktopActiveRoomTabs/DesktopActiveRoomTabs";

function ActiveRoomTabs() {
    const isMobile = useSelector(state => state.user.isMobile);
    return isMobile ? <MobileActiveRoomTabs/> : <DesktopActiveRoomTabs/>
}

export default ActiveRoomTabs;
