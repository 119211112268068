import Table from "../../../../core/Table/Table";
import {TOURNAMENT_TABLE_TITLES} from "../../../../constants";
import React from "react";
import {useDispatch} from "react-redux";
import {setSideBarTournamentId} from "../../../../redux/actions/tournament/actions";
function DesktopTournamentList({rows = [], currency}) {
    const dispatch = useDispatch()
    const handleTournamentItemClick = (row) => {
        dispatch(setSideBarTournamentId({tournamentId: row?.table_tournament_id}))
    }
    return <Table
        name={'Tournaments'}
        rows={rows}
        columns={TOURNAMENT_TABLE_TITLES}
        handleClick={handleTournamentItemClick}
        currency={currency}
    />
}

export default DesktopTournamentList