import Settings from "../../../components/Settings/Settings";
import {useEffect} from "react";
import {setActivePage} from "../../../../redux/actions/lobby/actions";
import {useDispatch, useSelector} from "react-redux";

function MobileSettings() {
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.user.isMobile);

    useEffect(() => {
        if (!isMobile) dispatch(setActivePage({activePage: "Cash"}))
    }, [isMobile,dispatch])

    return <Settings/>
}

export default MobileSettings;