import HistoryListItemMobile from "./HistoryListItemMobile/HistoryListItemMobile";
import {useTranslation} from "react-i18next";
function MobileHistoryList({rows =[], currency}) {
    const {t} = useTranslation();
    return <div className="mobile-history-list">
        <div className="mobile-history-list__header">
            <div className="mobile-history-list__title">{t("history.game_id")}</div>
            <div className="mobile-history-list__title">{t("history.till")}</div>
            <div className="mobile-history-list__title">{t("history.bet")}</div>
        </div>
        <div className="mobile-history-list__body">
            {
                rows?.map((item, index) => {
                    return <HistoryListItemMobile
                        key={index}
                        data={item}
                        currency={currency}
                    />
                })
            }

        </div>
    </div>
}

export default MobileHistoryList;