import React from 'react';
import {
    openModal,
    roomGetCombinationCards
} from "../../../../../redux/actions/rooms/actions";
import {useDispatch} from "react-redux";
import Button from "../../../../../core/Button/Button";
import {useTranslation} from "react-i18next";

function Buttons(props) {
    const {
        isActive,
        roomId,
        gameTypes,
        playable,
        canAnnounceCombination,
        canShowCombination,
        canPassAllCards
    } = props;
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const announceCombination = () => {
        if (!isActive) return
        return dispatch(openModal({
            name: 'AnnounceCombinationModal',
            msg: '',
            roomId
        }))
    }
    const allCardsToOpponent = () => {
        if (!isActive) return
        return dispatch(openModal({
            name: 'AcceptPassAllCardsToOpponentModal',
            msg: '',
            roomId
        }))
    }
    const allCardsToMe = () => {
        if (!isActive) return
        return dispatch(openModal({
            name: 'AcceptPassAllCardsToMeModal',
            msg: '',
            roomId
        }))
    }
    const publishCombination = () => {
        if (!isActive) return
        dispatch(roomGetCombinationCards({roomId}))
    }
    return (
        <div className="player__buttons">
            {
                !gameTypes.includes(1) && !canPassAllCards && playable && <>
                    <Button
                        onClick={() => announceCombination()}
                        isDisabled={!isActive || !canAnnounceCombination}
                        text={t('buttons.announce_combinations')}
                        type={"yellow-border"}
                    />
                    <Button
                        onClick={() => publishCombination()}
                        isDisabled={!isActive || !canShowCombination}
                        text={t('buttons.publish_combinations')}
                        type={"yellow-border"}
                    >
                    </Button>
                </>
            }
            {
                canPassAllCards && playable && <>
                    <Button
                        onClick={() => allCardsToOpponent()}
                        isdisabled={!isActive}
                        text={t('buttons.all_cards_to_you')}
                        type={"yellow-border"}
                    />
                    <Button
                        onClick={() => allCardsToMe()}
                        isdisabled={!isActive}
                        text={t('buttons.all_cards_to_me')}
                        type={"yellow-border"}
                    />
                </>
            }
        </div>
    )
}

export default Buttons
