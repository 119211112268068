import React, {useState, useMemo, useCallback} from 'react';
import {useDispatch} from "react-redux";
import BaseModal from "./BaseModal";
import {roomAnnounceCombinationRank} from "../../../../redux/actions/rooms/actions";
import Button from "../../../../core/Button/Button";
import {useTranslation} from "react-i18next";
import Checkbox from "../../../../core/Checkbox/Checkbox";

const AnnounceCombinationRankModal = React.memo(({room, userId}) => {
    const dispatch = useDispatch();
    const [rank, setRank] = useState(null);
    const [isRankWithTrumpSuit, setRankWithTrumpSuit] = useState(false);
    const {t} = useTranslation();

    const isWithTrumpCheckBoxExists = useMemo(() => {
        return room?.round?.trump?.suit !== 'A';
    }, [room]);

    const handleRankClick = useCallback((value) => {
        setRank(prevRank => (prevRank === value ? null : value));
    }, []);

    const announceCombinationRank = useCallback(() => {
        if (!rank) return;
        dispatch(roomAnnounceCombinationRank({
            roomId: room.roomId,
            name: rank,
            isTrump: isRankWithTrumpSuit
        }));
    }, [dispatch, room.roomId, rank, isRankWithTrumpSuit]);

    const announceYourRankPassed = useCallback(() => {
        if (rank) return;
        dispatch(roomAnnounceCombinationRank({
            roomId: room.roomId,
            name: null,
            isTrump: isRankWithTrumpSuit
        }));
    }, [dispatch, room.roomId, rank, isRankWithTrumpSuit]);

    const modalContent = useMemo(() => {
        if (userId !== room?.round?.activePlayerPlatformId) {
            return <div>{t("modals.combination_rank.waiting_text")}</div>;
        }

        return (
            <div className="modal__combination-rank">
                <p>{t("modals.combination_rank.text")}</p>
                {room?.modal?.msg && <p>{t(`modals.combination_rank.${room?.modal?.msg}`)}</p>}
                <div className="modal__combination-rank--type">
                    {room?.round?.combinationRanks?.map((rankName) => (
                        <span
                            className={`modal__combination-rank--type--button ${rankName === rank ? "active" : ""}`}
                            key={rankName}
                            onClick={() => handleRankClick(rankName)}
                        >
                                {rankName}
                            </span>
                    ))}
                </div>
                {isWithTrumpCheckBoxExists && (
                    <div
                        onClick={() => setRankWithTrumpSuit(!isRankWithTrumpSuit)}
                    >
                        <Checkbox title={t("modals.combination_rank.trump")} checked={isRankWithTrumpSuit}/>
                    </div>
                )}
                <div className="modal__combination-rank--buttons">
                    <Button text={t("modals.buttons.accept")} type="yellow" onClick={announceCombinationRank}/>
                    {room.round.canRejectRank && (
                        <Button text={t("modals.buttons.pass")} type="red" onClick={announceYourRankPassed}/>
                    )}
                </div>
            </div>
        );
    }, [
        t,
        userId,
        room,
        rank,
        isWithTrumpCheckBoxExists,
        isRankWithTrumpSuit,
        handleRankClick,
        announceCombinationRank,
        announceYourRankPassed
    ]);

    return (
        <BaseModal>
            {modalContent}
        </BaseModal>
    );
});

export default React.memo(AnnounceCombinationRankModal);


