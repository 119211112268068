import FilterMultiselect from "../../components/FilterMultiselect/FilterMultiselect";
import {GAME_CONVENTIONS, GAME_STATUS, PLAYING_TILLS, STEP_TIMES} from "../../../../../constants";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {resetFilters, setFilters} from "../../../../../redux/actions/cash/actions";
import React from "react";
import FilterInput from "../../components/FilterInput/FilterInput";

function DesktopFilter() {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const filter = useSelector(state => state.cash.filter);
    const handleFilterChange = (data) => {
        dispatch(setFilters(data))
    }
    const mapTranslatedOptions = (options, translateLabel = '') => {
        return options.map((option) => {
            return {...option, label: t(`${translateLabel}.${option.label}`)}
        })
    }
    return <div className="cash-lobby-filter">
        <div className="cash-lobby-filter__left">
            <span className="filterBet">{t("filter.bet")} :</span>
            <FilterInput
                placeholder={t("filter.min_bet")}
                handleChange={handleFilterChange}
                name="minBet"
                value={filter.minBet}/>
            <FilterInput
                placeholder={t("filter.max_bet")}
                handleChange={handleFilterChange}
                name="maxBet"
                value={filter.maxBet}
            />
        </div>
        <div className="cash-lobby-filter__right">
            <FilterMultiselect
                className="filter-score"
                data={PLAYING_TILLS}
                handleChange={handleFilterChange}
                placeholder={t("filter.score")}
                name={"score"}
                defaultValue={filter.score}
            />
            <FilterMultiselect
                className="filter-time"
                data={STEP_TIMES}
                handleChange={handleFilterChange}
                placeholder={t("filter.step_time")}
                name={"time"}
                defaultValue={filter.time}
            />
            <FilterMultiselect
                className="filter-status"
                data={mapTranslatedOptions(GAME_STATUS, "filter")}
                handleChange={handleFilterChange}
                placeholder={t("filter.state")}
                name={"status"}
                defaultValue={filter.status}
            />
            <FilterMultiselect
                className="filter-type"
                data={mapTranslatedOptions(GAME_CONVENTIONS.flat(), "form")}
                handleChange={handleFilterChange}
                placeholder={t("filter.type")}
                name={"type"}
                defaultValue={filter.type}
            />
        </div>
        <i className="delete-filters icon-bin1" title={t("filter.clear_all")}
           onClick={() => dispatch(resetFilters())}/>
    </div>

}

export default DesktopFilter