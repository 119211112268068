import React from 'react';
import HistoryFilter from "../../../components/Filter/HistoryFilter/HistoryFilter";
import HistoryList from "../../../components/HistoryList/HistoryList";
import HistoryInfo from "../../../components/HistoryInfo/HistoryInfo";

const DeskTopHistory = React.memo(function () {
    return <div className="history">
        <HistoryFilter/>
        <div className="history__wrapper">
            <HistoryList/>
            <HistoryInfo/>
        </div>
    </div>
})

export default DeskTopHistory