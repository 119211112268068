const types = {
    EVENT: 'gameEvent',
    ROOM_ENTER: 'roomEnter',
    EXIT_ROOM: 'exitRoom',
    CLOSE_ROOM: 'closeRoom',
    RESTORE_ENTERED_ROOMS: 'restoreRooms',
    ROOM_REJECT_OPPONENT: 'roomRejectOpponent',
    ROOM_ACCEPT_OPPONENT: 'roomAcceptOpponent',
    ROOM_UPDATE: 'roomUpdate',
    ROOM_FINISHED: 'roomFinished',
    ROOM_ACCEPT_TRUMP: 'roomAcceptTrump',
    ROOM_REJECT_TRUMP: 'roomRejectTrump',
    ROOM_SET_WAITING_DIALOG: 'setWaitingDialog',
    ROOM_UPDATE_ROUND: 'roomUpdateRound',
    ROOM_PLAY_CARD: 'roomPlayCard',
    ROOM_PLAYED_CARD: 'roomPlayedCard',
    ROOM_ANNOUNCE_COMBINATION: 'roomAnnounceCombination',
    ROOM_ANNOUNCE_COMBINATION_FAILED: 'roomAnnounceCombinationFailed',
    ROOM_ANNOUNCE_COMBINATION_RANK: 'roomAnnounceCombinationRank',
    ROOM_ANNOUNCE_COMBINATION_RANK_FAILED: 'roomAnnounceCombinationRankFailed',
    ROOM_PUBLISH_COMBINATION: 'roomPublishCombination',
    ROOM_PUBLISH_COMBINATION_FAILED: 'roomPublishCombinationFailed',
    ROOM_OPEN_MODAL: 'openModal',
    ROOM_CLOSE_MODAL: 'closeModal',
    ROOM_SET_CARDS_SELECTABLE: 'roomSetCardsSelectable',
    ROOM_RESET_SELECTED_COMBINATION_CARDS: 'roomResetSelectedCombination',
    ROOM_GET_COMBINATION_CARDS: 'roomGetCombinationCards',
    ROOM_SET_COMBINATION_CARDS: 'roomSetCombinationCards',
    ROOM_SELECT_COMBINATION_CARD: 'roomSelectCombinationCard',
    ROOM_ANNOUNCE_ALL_CARDS_TO_OPPONENT: 'roomAnnounceAllCardsToOpponent',
    ROOM_REQUEST_ALL_CARDS_TO_ME: 'roomRequestAllCardsToMe',
    ROOM_ACCEPT_ALL_CARDS_TO_ME_REQUEST: 'roomAcceptAllCardsToMeRequest',
    ROOM_REJECT_ALL_CARDS_TO_ME_REQUEST: 'roomRejectAllCardsToMeRequest',
    ROOM_ALL_CARDS_PASSED_TO_OPPONENT: 'roomAllCardsPassedToOpponent',
    ROOM_RESET_ROUND: 'roomResetRound',
    ROOM_UPDATE_TIMER: 'updateRoomTimer',
    ROOM_LEAVE_WAITING_ROOM: 'leaveRoom',
    ROOM_JOIN_TO_VIEW: 'roomJoinToView',
    ROOM_EXIT_FROM_VIEW: 'roomViewExit',

};
export default types
