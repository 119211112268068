import React, {useState} from "react";
import Button from "../../../core/Button/Button";
import TournamentPrizePool from "./TournamentPrizePool/TournamentPrizePool";
import TournamentParticipants from "./TournamentParticipants/TournamentParticipants";
import {useDispatch, useSelector} from "react-redux";
import {
    closeLobbyModal, lobbyOpenModal,
} from "../../../redux/actions/lobby/actions";
import {formatTimestamp} from "../../../utils";
import {useTranslation} from "react-i18next";
import {toggleTournamentActiveRooms} from "../../../redux/actions/tournament/actions";

const initialState = {isPrizeActive: false, isPlayersActive: false};

function TournamentInfo() {
    const {t} = useTranslation();
    const {isMobile, currency} = useSelector(state => state.user);
    const {list, players,  sideBarTournamentId} = useSelector(state => state.tournament);
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const selectedTournament = list[sideBarTournamentId];
    const participants = players[sideBarTournamentId] || {};
    const isActiveTournamentRoomsSection = useSelector(state => state.tournament.isActiveTournamentRoomsSection)
    const handleClick = (key) => {
        if (!isMobile) {
            return setState({
                ...initialState,
                [key]: !state[key]
            })
        }

        return dispatch(lobbyOpenModal({name: 'TournamentPlayersModal'}))
    }
    const openActiveRoomsSection = () => {
        if (!isMobile) return dispatch(toggleTournamentActiveRooms())
        dispatch(lobbyOpenModal({name: 'TournamentActiveRoomsMobile'}))
    }

    if (!sideBarTournamentId) return;

    return (
        <div className="tournament-info">
            <div className="tournament-info--wrapper">
                <div className="tournament-info--header">
                    <p>{t('tournament.information')}</p>
                    {
                        isMobile &&
                        <i className="icon-close"
                           onClick={() => dispatch(closeLobbyModal())}
                        />
                    }
                </div>
                <div className="tournament-info--body">
                    <div className="tournament-info--body__row">
                        <span>ID</span>
                        {selectedTournament?.info?.id || '-'}
                    </div>
                    <div className="tournament-info--body__row">
                        <span>{t('tournament.reg_time')}</span>
                        {selectedTournament ? formatTimestamp(selectedTournament?.info?.registrationDate) : '-'}
                    </div>
                    <div className="tournament-info--body__row">
                        <span>{t('tournament.start_time')}</span>
                        {selectedTournament ? formatTimestamp(selectedTournament?.info?.startDate) : '-'}
                    </div>
                    <div className="tournament-info--body__row">
                        <span>{t('tournament.reBuy')}</span>
                        {
                            selectedTournament
                                ?
                                `${selectedTournament?.info?.reBuyLimit} / ${selectedTournament?.info?.reBuy[currency]} ${currency}`
                                : '-'
                        }
                    </div>
                    <div className="tournament-info--body__row">
                        <span>{t('tournament.round')}</span>
                        {
                            selectedTournament
                                ?
                                `${selectedTournament?.info?.round}${selectedTournament?.info?.nextRoundTimer ? ' / ' + selectedTournament?.info?.nextRoundTimer : ''}`
                                :
                                '-'
                        }

                    </div>
                    <div className="tournament-info--body__row">
                        <span>{t('tournament.step_time')}</span>
                        {
                            selectedTournament
                                ?
                                `${selectedTournament?.info?.stepTime} ${t('tournament.s')} / ${selectedTournament?.info?.bankTime} ${t('tournament.s')}`
                                :
                                '-'
                        }
                    </div>
                    <div className="tournament-info--body__row">
                        <span>{t('tournament.participants')}</span>
                        {
                            selectedTournament
                                ?
                                `${Object.keys(participants)?.length} (${selectedTournament?.info?.minPlayers})`
                                :
                                '-'
                        }
                    </div>
                    <div className="tournament-info--body__row">
                        <span>{t('tournament.convention')}</span>
                        <div>
                            {
                                selectedTournament?.info?.conventions.map((item, index) => {
                                    return <i
                                        key={`icon-${item}${index}`}
                                        className={`icon-${item} table-icon`}
                                    />
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="tournament-info--buttons">
                    {
                        !isMobile &&
                        <Button
                            onClick={() => handleClick("isPrizeActive")}
                            type={`lightblue ${state?.isPrizeActive ? "active" : ""}`}
                            text={t('tournament.prize_pool')}
                        />
                    }
                    <Button
                        onClick={() => handleClick("isPlayersActive")}
                        type={`lightblue ${state?.isPlayersActive ? "active" : ""}`}
                        text={t('tournament.participants')}
                    />
                    {
                        selectedTournament.info.status === "PROGRESS" &&
                        <Button
                            onClick={() => openActiveRoomsSection()}
                            type={`lightblue ${isActiveTournamentRoomsSection && !isMobile ? "active" : ""}`}
                            text={t('tournament.rooms')}
                        />
                    }
                </div>

                {
                   ( state?.isPrizeActive || isMobile) && <TournamentPrizePool
                        prizePool={selectedTournament?.info?.prizePool}
                        prize={selectedTournament?.info?.prize[currency]}
                        currency={currency}
                    />
                }
                {
                    !isMobile && state?.isPlayersActive && <TournamentParticipants participants={Object.values(participants)}/>
                }
            </div>
        </div>
    )
}

export default TournamentInfo