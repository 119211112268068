import {useDispatch} from "react-redux";
import ModalBody from "../../../core/Modal/ModalBody/ModalBody";
import ModalHeader from "../../../core/Modal/ModalHeader/ModalHeader";
import CreateForm from "../CreateRoomForm/CreateForm";
import {closeLobbyModal} from "../../../redux/actions/lobby/actions";

function MobileCreateGameModal() {
    const dispatch = useDispatch()
    return <>
        <ModalHeader>
            <div className="create-game-modal-header">
                <p>Create Game</p>
                <i className="icon-close" onClick={()=> dispatch(closeLobbyModal())}/>
            </div>
        </ModalHeader>
        <ModalBody>
            <CreateForm/>
        </ModalBody>
    </>
}

export default MobileCreateGameModal