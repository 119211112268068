import TabItem from "./TabItem/TabItem";
import {useState} from "react";
import {setActivePage} from "../../../redux/actions/lobby/actions";
import {useDispatch, useSelector} from "react-redux";
import {TABS} from "../../../constants";
import {useTranslation} from "react-i18next";

function Tabs({tabItems}) {
    const dispatch = useDispatch();
    const page = useSelector(state => state.lobby.activePage);
    const [selectedTabIndex, setTabIndex] = useState(page === 'Tournament' ? 1 : 0);
    const {t} = useTranslation();

    const handleTabClick = (name, index) => {
        dispatch(setActivePage({activePage: name}))
        setTabIndex(index)
    }
    const isTabActive = TABS.includes(page)

    return (
        <div className={`tabs tabs--${tabItems?.length}`}>
            {
                tabItems?.map((item, index) => {
                    return <TabItem
                        key={index}
                        item={t(`tabs.${item}`)}
                        active={isTabActive && selectedTabIndex === index}
                        onClick={() => handleTabClick(item, index)}
                    />
                })
            }
            {isTabActive && <span className={`toggle active-${selectedTabIndex + 1}`}></span>}
        </div>)
}

export default Tabs

