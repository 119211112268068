import { all, takeEvery, put, delay } from 'redux-saga/effects';
import ROOMS from "../actions/rooms/types";
import LOBBY from "../actions/lobby/types";
function* handleCloseFinishedRoom(action) {
    yield delay(1500);
    yield put({ type: ROOMS.EXIT_ROOM, payload: action.payload});
    yield put({ type: LOBBY.SELECT_ACTIVE_ROOM_TAB, payload: {roomId: null}});
}

function* watchGameFinished() {
    yield takeEvery(ROOMS.ROOM_FINISHED, handleCloseFinishedRoom);
}

export default function* rootSaga() {
    yield all([
        watchGameFinished(),
    ]);
}
