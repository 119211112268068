import React from 'react';
import JoinRoomModal from "./JoinRoomModal";
import RoomJoinRejectModal from "./RoomJoinRejectModal";
import FinishModal from "./FinishModal";
import TrumpCardModal from "./TrumpCardModal";
import WaitingForPlayerModal from "./WaitingForPlayerModal";
import AnnounceCombinationModal from "./AnnounceCombinationModal";
import AnnounceCombinationRankModal from "./AnnounceCombinationRankModal";
import PublishCombinationModal from "./PublishCombinationModal";
import {useSelector} from "react-redux";
import AcceptPassAllCardsToOpponentModal from "./AcceptPassAllCardsToOpponentModal";
import AcceptPassAllCardsToMeModal from "./AcceptPassAllCardsToMeModal";
import PassRoomAllCardsToMeModal from "./PassRoomAllCardsToMeModal";

const CombinedModals= {
    FinishModal,
    JoinRoomModal,
    TrumpCardModal,
    AcceptPassAllCardsToOpponentModal,
    AcceptPassAllCardsToMeModal,
    PassRoomAllCardsToMeModal,
    WaitingForPlayerModal,
    RoomJoinRejectModal,
    AnnounceCombinationModal,
    AnnounceCombinationRankModal,
    PublishCombinationModal
};
function RoomModal({roomId, userId}) {
    const rooms = useSelector(state => state.rooms.entered);
    const room = rooms[roomId];
    if (!room?.modal?.isActive) return;
    const ActiveModal = CombinedModals[room.modal.name];
    return <ActiveModal room={room} userId={userId}/>
}

export default RoomModal
