import React from "react";
import {useSelector} from "react-redux";
import MobileActiveRoomTabItem from "./MobileActiveRoomTabItem";

function MobileActiveRoomTabs() {
    const enteredRooms = useSelector(store => store.rooms.entered);
    return <ul className="mobile-header-active-games">
        {
            Object.keys(enteredRooms)?.map((roomId, index) => (
                <MobileActiveRoomTabItem
                    key={index}
                    id={roomId}
                    isViewRoom={enteredRooms[roomId]?.isViewRoom}
                    isHistoryRoom={enteredRooms[roomId]?.historyRoomPlayer}
                    isTournamentRoom={enteredRooms[roomId]?.tournamentId}
                />
            ))
        }
    </ul>
}

export default MobileActiveRoomTabs;
