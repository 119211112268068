import PendingTournamentButton from "./PendingTournamentButton";
import ProgressTournamentButton from "./ProgressTournamentButton";
import FinishedTournamentButton from "./FinishedTournamentButton";
import CanceledTournamentButton from "./CanceledTournamentButton";

const combinedTournamentButtons = {
    PENDING: PendingTournamentButton,
    PROGRESS: ProgressTournamentButton,
    CANCELED: CanceledTournamentButton,
    FINISHED: FinishedTournamentButton,
}
export default combinedTournamentButtons