import React from 'react';
import BaseModal from "./BaseModal";
import TrumpCard from "../TrumpCard";
import OtherTrump from "../OtherTrump";
import Spinner from "../../../../core/Spinner/Spinner";
import {useTranslation} from "react-i18next";

const TrumpCardModal = React.memo(function ({room, userId}) {
    const {
        offeredCard,
        canReject,
        rejectCount,
        isJack,
    } = room.round?.trump || {};
    const {t} = useTranslation()

    return (
        <BaseModal>
            {userId === room?.round?.activePlayerPlatformId ?
                <div className="modal__trump-select">
                    {
                        ((rejectCount <= 1 && isJack) || (rejectCount >= 2)) ?
                            <TrumpCard
                                roomId={room.roomId}
                                offeredCard={offeredCard}
                                canReject={canReject}
                            /> :
                            <OtherTrump
                                roomId={room.roomId}
                                suits={room?.round?.trumpSuits}
                                offeredCard={offeredCard}
                                canReject={canReject}
                            />
                    }
                </div> :
                <div className="modal__trump-select--waiting">
                    <Spinner/>
                    <p>{t("modals.trump_select.waiting_text")}</p>
                </div>
            }
        </BaseModal>
    )
}, (prevProps, nextProps) => {
    return prevProps.room.roomId === nextProps.room.roomId && prevProps?.room?.round?.activePlayerPlatformId === nextProps?.room?.round?.activePlayerPlatformId
})

export default TrumpCardModal
