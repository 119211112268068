import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../core/Button/Button";
import React from "react";
import {joinTournament, quitTournament} from "../../../../../../redux/actions/tournament/actions";

function PendingTournamentButton({row}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const userId = useSelector(state => state.user.id);
    const isMobile = useSelector(state => state.user.isMobile)
    const players = useSelector((state) => state.tournament.players);
    const list = useSelector((state) => state.tournament.list) ;
    const tournamentPlayers = players[row.table_tournament_id] || {};
    const tournament = list[row.table_tournament_id] || {};
    if (!tournament?.info.registrationEnabled) return;
    let type = 'green';
    let text = 'join';
    let action = () => dispatch(joinTournament({tournamentId: tournament.id}))
    if (tournamentPlayers[userId]?.status === 'PENDING') {
        type = 'red';
        text = 'quit';
        action = () => dispatch(quitTournament({tournamentId: tournament.id}))
    }
    return !isMobile ? (
        <div className="button-size">
            <Button
                type={type}
                onClick={action}
                text={ t(`tournament.${text}`)}
            />
        </div>
    ) : (
        <i className="icon-arrow icon-right" onClick={action}/>
    );
}

export default PendingTournamentButton


