import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {selectActiveRoomTab} from "../../../../../../redux/actions/lobby/actions";
import Button from "../../../../../../core/Button/Button";
import {joinRoom} from "../../../../../../redux/actions/cash/actions";
import {closeRoom} from "../../../../../../redux/actions/rooms/actions";
import {useTranslation} from "react-i18next";

function WaitingStateButton({row}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const userId = useSelector(state => state.user.id);
    const isMobile = useSelector(state => state.user.isMobile)

    return (
        <div className="table-buttons-wrapper">
            {row?.table_room_player !== userId ?
                !isMobile ?
                    <div className="button-size">
                        <Button onClick={() => dispatch(joinRoom(row?.table_room_id))} text={t('buttons.join')}/>
                    </div> :

                    <i className="icon-play" onClick={() => dispatch(joinRoom(row?.table_room_id))}/> :

                !isMobile ?
                    <>
                        <i className="icon-close close-hover" onClick={() => dispatch(closeRoom({roomId:row?.table_room_id}))}/>
                        <div className="button-size"><Button
                            onClick={() => dispatch(selectActiveRoomTab({roomId: row?.table_room_id}))}
                            type={"blue-border"} text={t('buttons.back')}/></div>
                    </> :

                    <i className="icon-arrow icon-right"
                       onClick={() => dispatch(selectActiveRoomTab({roomId: row?.table_room_id}))}/>
            }
        </div>
    )
}

export default WaitingStateButton