import {default as ReactSelect} from "react-select";
import Option from "./Options";

function FilterMultiselect(props) {
    const {
        className,
        data,
        handleChange,
        placeholder,
        name,
        defaultValue = []
    } = props
    const onChange = (data) => {
        handleChange({[name]: data})
    }

    return (
        <div className={`${className} ${defaultValue.length ? "active-filter" : ""}`}>
            <ReactSelect
                isSearchable={true}
                placeholder={placeholder}
                indicatorSeparator
                options={data}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                blurInputOnSelect={false}
                maxMenuHeight={200}
                isClearable={false}
                controlShouldRenderValue={false}
                value={defaultValue}
                onChange={onChange}
                classNames={{
                    control: () =>
                        "filter-multiselect",
                    option: () =>
                        "filter-option",
                    menu: () =>
                        "filter-menu",
                    dropdownIndicator: () =>
                        "filter-arrow"
                }}
                styles={{
                    placeholder: (base) => {
                        return {
                            ...base,
                            fontSize:"0.85em"
                        }
                    }
                }
                }
                components={{
                    Option,
                    IndicatorSeparator: () => null,
                }}
            />
        </div>
    );
}

export default FilterMultiselect