/* eslint-disable */
import React, {useMemo, useRef, useEffect, useState, useCallback} from 'react';
import Card from "../Card";
import {
    deepClone,
    getShortUniqueKey,
    // sortCardsBySuit,
    updateCardClasses
} from "../../../../utils";
import Deck from "../Deck";
import {
    roomPlayCard,
    roomSelectCombinationCard,
} from "../../../../redux/actions/rooms/actions";
import {useDispatch} from "react-redux";
import useAudio from "../../../../hooks/Audio";
const Board = function (props) {
    const {
        userId,
        roomId,
        roundId,
        topExternalCards = [],
        bottomExternalCards = [],
        combinationExternalCards,
        cardsSelectable,
        boardWinnerId,
        bottomPlayerId,
        topPlayerId,
        playedCards,
        publishedCombinationCards,
        allCardsPassedToOpponent,
        playerIdWithPublicCards,
        isViewRoom,
        historyRoomPlayer,
        isRoomActive
    } = props;
    const dispatch = useDispatch();
    const [bottomInternalCards, setBottomInternalCards] = useState([]);
    const [topInternalCards, setTopInternalCards] = useState([]);
    const [combinationInternalCards, setCombinationInternalCards] = useState([]);
    const [publishInternalCards, setPublishInternalCards] = useState(null);
    const cardDisplayInterval = 60;
    const bitaDisplayInterval = 500;
    const [animatedRender, setAnimatedRender] = useState(true);
    const [roundInternalId, setRoundId] = useState(null);
    const [playWithOpenCards, setPlayWithOpenCards] = useState(false);
    const topCardRefs = useRef([]);
    const bottomCardRefs = useRef([]);
    const {play, stopAllAudio} = useAudio();
    const assignRef = (refArray, index) => el => {
        refArray.current[index] = el;
    };
    const audioHandler = (name) => {
        if (!isRoomActive || historyRoomPlayer) return;
        play(name)
    }
    const resetBoard = () => {
        setBottomInternalCards([]);
        setTopInternalCards([]);
        setCombinationInternalCards([]);
        setPublishInternalCards(null);
        setAnimatedRender(true);
        setPlayWithOpenCards(false);
        topCardRefs.current = [];
        bottomCardRefs.current = [];
        topPlayerCards = [];
        bottomPlayerCards = [];
    }
    const handlePublishCombinationCards = (cards, refs, method, isBottom) => {
        const cls = isBottom ? 'card-combination-bottom' : 'card-combination-top';
        // const actualCards = refs?.current.filter(r => {
        //     return (
        //         !r?.classList.contains('bita-top') &&
        //         !r?.classList.contains('bita-bottom') &&
        //         !r?.classList.contains('card-bottom-played') &&
        //         !r?.classList.contains('card-top-played')
        //     )
        // });
        cards?.forEach((data, index) => {
            const {card, cardIndex} = data;
            const name = `card-name_${card?.suit}${card?.rank.name}`;

            if (method === "remove") {
                // Add 'cls' immediately
                refs?.current[cardIndex]?.classList[method](cls); // in this case cards will be shown in the real position
                // actualCards[actualCards?.length - cards.length + index]?.classList[method](cls);
                // Remove 'name' after a delay
            } else {
                // Add both 'cls' and 'name' immediately when adding
                // actualCards[actualCards?.length - cards.length + index]?.classList[method](cls, name)
                refs?.current[cardIndex]?.classList[method](cls, name);// in this case cards will be removed from the real position
            }
        });
    };
    // const reIndexCards = () => {
    //     const sortedCard = sortCardsBySuit(bottomInternalCards, trump);
    //     for (let i = 0; i < 9; i++) {
    //         const oldCard = bottomInternalCards[i];
    //         const newIndex = sortedCard?.findIndex(c => c?.suit === oldCard?.suit && c?.rank.name === oldCard?.rank.name);
    //         if (bottomCardRefs?.current[i]) {
    //             const oldClass = `card_${i + 1}`;
    //             const newClass = `card_${newIndex + 1}`;
    //             bottomCardRefs?.current[i]?.classList.replace(oldClass, newClass);
    //         }
    //     }
    // };
    const handleReferenceClassNames = (refs, cards, isBottom) => {
        refs?.current.forEach((ref, index) => {
            const card = cards[index];
            let cls = [];
            if (card?.played) {
                if (card?.bita) {
                    const bitaDirection = topPlayerId === card?.bita ? 'bita-top' : 'bita-bottom'
                    cls.push(bitaDirection, 'card-not-visible')
                } else {
                    cls.push(isBottom ? 'card-bottom-played' : 'card-top-played')
                }
            } else {
                cls = [`card_${index + 1}`];
            }
            ref?.classList.add(...cls);
        })
    }
    const renderCards = (cards, isBottom) => {
        if (!cards || !cards.length) return null;
        return cards.map((card, index) => {
            const cardName = card ? `card-visible card-name_${card?.suit}${card?.rank?.name}` : '';
            let className = isBottom
                ? `card-bottom ${card?.playable ? 'playable' : 'not-playable '}`
                : 'card-top';
            if (animatedRender) {
                const isCardInInitialSix = cards.length === 9 && index < 6;
                className = `${className} ${isCardInInitialSix ? '' : 'card-deck'}`;
            }
            const uniqueKey = getShortUniqueKey();
            const cardRef = assignRef(isBottom ? bottomCardRefs : topCardRefs, index);
            return (
                <Card
                    key={uniqueKey}
                    roomId={roomId}
                    data={card}
                    className={`${className} ${cardName}`}
                    onCardClick={() => handleClickCard(index)}
                    ref={cardRef}
                />
            );
        });
    };
    const handleClickCard = (index) => {
        if (isViewRoom || historyRoomPlayer) return;
        const targetCardRef = bottomCardRefs?.current[index];
        if (!targetCardRef) return;
        const cardSelectable = targetCardRef?.classList.contains('card-selectable');
        if (cardSelectable) {
            const action = targetCardRef?.classList.contains('card-selected') ? 'remove' : 'add';
            targetCardRef?.classList[action]('card-selected');
            const card = bottomExternalCards[index]
            dispatch(roomSelectCombinationCard({roomId, card: {name: `${card?.rank.name}${card?.suit}`, data: card}}))
        } else {
            const cardPlayable = targetCardRef?.classList.contains('playable');
            if (!cardPlayable) return;
            dispatch(roomPlayCard({roomId, cardIndex: index}));
        }
    };

    const updateCardSelectionClass = useCallback((cards, action) => {
        bottomInternalCards.forEach(({suit, rank}, index) => {
            const bottomCard = bottomCardRefs?.current[index];
            if (!bottomCard || bottomCard?.classList.contains('card-bottom-played')) return;

            const indexOfCard = cards.findIndex(card => card.rank.name === rank.name && card.suit === suit);

            if (action === 'add') {
                const additionalClass = ['card-selectable'];
                if (indexOfCard !== -1) additionalClass.push('card-selected');
                bottomCard?.classList.add(...additionalClass);
            } else if (action === 'remove') {
                bottomCard?.classList.remove('card-selectable', 'card-selected');
            }
        });
    }, [bottomInternalCards, bottomCardRefs]);

    let topPlayerCards = useMemo(() => renderCards(topInternalCards, false), [topInternalCards.length]);

    let bottomPlayerCards = useMemo(() => renderCards(bottomInternalCards, true), [bottomInternalCards.length]);

    const closeCardsDistance = (refs) => {
        const notPlayedCardsRefs = refs?.current.filter(r => {
            return (
                !r?.classList.contains('bita-top') &&
                !r?.classList.contains('bita-bottom') &&
                !r?.classList.contains('card-bottom-played') &&
                !r?.classList.contains('card-top-played')
            )
        });
        if (notPlayedCardsRefs) {
            notPlayedCardsRefs.forEach((ref, index) => {
                const oldClassName = ref?.classList && Array.from(ref?.classList).find(cls => cls.startsWith('card_'));
                if (oldClassName) {
                    const newClassName = `card_${index + 1}`
                    ref?.classList.replace(oldClassName, newClassName)
                }
            });
        }

    }

    useEffect(() => {
        if (!isRoomActive) {
            stopAllAudio()
        }
    }, [isRoomActive])

    useEffect(() => {
        setRoundId(roundId)
        resetBoard()
    }, [
        roundId,
        roundInternalId
    ])

    useEffect(() => {
        // Ensure bottomExternalCards and bottomCardRefs are available
        if (!bottomExternalCards.length || !bottomCardRefs?.current?.length) return;

        bottomExternalCards.forEach(extCard => {
            const name = `card-name_${extCard?.suit}${extCard?.rank.name}`;
            const targetCardRef = bottomCardRefs?.current.find(ref => ref?.classList.contains(name));

            if (!targetCardRef) return;

            const isPlayable = extCard?.playable;
            const hasPlayableClass = targetCardRef?.classList.contains('playable');
            const hasNotPlayableClass = targetCardRef?.classList.contains('not-playable');

            // Update class if needed
            if (isPlayable && hasNotPlayableClass) {
                targetCardRef?.classList.replace('not-playable', 'playable');
            } else if (!isPlayable && hasPlayableClass) {
                targetCardRef?.classList.replace('playable', 'not-playable');
            }
        });
    }, [
        bottomExternalCards,
        bottomCardRefs
    ]);


    useEffect(() => {
        // --- Handle class names and card distances ---
        handleReferenceClassNames(topCardRefs, topInternalCards, false);
        handleReferenceClassNames(bottomCardRefs, bottomInternalCards, true);
        closeCardsDistance(topCardRefs);
        closeCardsDistance(bottomCardRefs);

        // --- Handle card display animation (if animatedRender is true) ---
        if (animatedRender) {
            const totalCards = bottomInternalCards.length;
            const isFirstBatch = totalCards === 6;
            const startCount = isFirstBatch ? 0 : 6;

            const displayCards = (count) => {
                if (count >= totalCards) return;
                // Clear any existing timeout before starting a new display cycle
                const bottomCard = bottomCardRefs?.current[count];
                const topCard = topCardRefs?.current[count];
                // Apply the class to animate the cards
                if (bottomCard) bottomCard?.classList.replace('card-deck', `card_${count + 1}`);
                if (topCard) topCard?.classList.replace('card-deck', `card_${count + 1}`);
                // Wait for 80 seconds
                delay(100).then((timeoutId) => {
                    if (timeoutId) {
                        audioHandler('cardsShuffle');
                        clearTimeout(timeoutId)
                        timeoutId = null
                    }
                    displayCards(count + 1);
                });
            };

            if (totalCards > 0 && bottomCardRefs?.current.length === totalCards && topCardRefs?.current.length) {
                displayCards(startCount);
            }
        }

        // --- Sync internal and external cards ---
        if (bottomInternalCards.length !== bottomExternalCards.length) {
            setBottomInternalCards(bottomExternalCards);
        }

        if (topInternalCards.length !== topExternalCards.length) {
            setTopInternalCards(topExternalCards);
        }

        // --- Handle animated render state ---
        if (topInternalCards.length === 0 && topExternalCards.length === 9) {
            setAnimatedRender(false);
        }
        return () => {
            stopAllAudio()
        }
    }, [
        bottomInternalCards.length,
        topInternalCards.length,
        animatedRender,
        cardDisplayInterval,
        bottomExternalCards.length,
        topExternalCards.length,
        isViewRoom, // Adding isViewRoom to ensure that the reIndexing behavior works correctly when `totalCards === 9`
    ]);
    const delay = (ms) => {
        return new Promise((resolve, reject) => {
            const timeoutId = setTimeout(() => {
                resolve(timeoutId); // Return timeoutId when the timeout completes
            }, ms);
        });
    }

    const deleteBita = () => {
        const bitaBottom = bottomCardRefs?.current.find(r => {
            return (
                ( r?.classList.contains('bita-top') || r?.classList.contains('bita-bottom')) &&
                !r?.classList.contains('card-not-visible')
            )
        });
        const bitaTop = topCardRefs?.current.find(r => {
            return (
                ( r?.classList.contains('bita-top') || r?.classList.contains('bita-bottom')) &&
                !r?.classList.contains('card-not-visible')
            )
        });
        bitaBottom?.classList.add('card-not-visible')
        bitaTop?.classList.add('card-not-visible')
    }

    useEffect(() => {
        if (!playedCards?.length) return;

        const clonedCards = deepClone(playedCards);
        const lastPlayedCard = clonedCards[clonedCards.length - 1];
        if (!lastPlayedCard) return;
        deleteBita()
        const {platformId, index: cardIndex, card} = lastPlayedCard;
        const playedCardOrder = `played-card-order-${playedCards?.length}`
        // Update classes for played cards
        if (platformId === bottomPlayerId) {
            if (isViewRoom) {
                const name = `card-name_${card?.suit}${card?.rank.name}`;
                updateCardClasses(bottomCardRefs, cardIndex, [`card_${cardIndex + 1}`, 'playable', 'not-playable', 'card-visible'], [playedCardOrder, 'card-bottom-played', name]);
            } else {
                updateCardClasses(bottomCardRefs, cardIndex, [`card_${cardIndex + 1}`, 'playable', 'not-playable', 'card-visible'], [playedCardOrder, 'card-bottom-played']);
            }

        } else {
            const name = `card-name_${card?.suit}${card?.rank.name}`;
            updateCardClasses(topCardRefs, cardIndex, [`card_${cardIndex + 1}`, 'playable', 'not-playable', 'card-visible'], [playedCardOrder, 'card-top-played', name]);
        }


        if (bottomCardRefs?.current.length) {
            audioHandler('playCard')
        }

        // Adjust card distances for top and bottom players
        closeCardsDistance(topCardRefs);
        closeCardsDistance(bottomCardRefs);
        // Handle "bita" animation when board winner is determined
        if (boardWinnerId && playedCards.length === 2) {
            const bitaDirection = bottomPlayerId === boardWinnerId ? 'bita-bottom' : 'bita-top';
            const getCardIndexByPlayer = (playerId) => {
                return playedCards.find(card => card.platformId === playerId)?.index;
            };

            const topPlayedCardIndex = getCardIndexByPlayer(topPlayerId);
            const bottomPlayedCardIndex = getCardIndexByPlayer(bottomPlayerId);

            const topRefBita = topCardRefs?.current[topPlayedCardIndex];
            const bottomRefBita = bottomCardRefs?.current[bottomPlayedCardIndex];
            const setBita = () => {
                audioHandler('cardSlide')
                topRefBita?.classList.replace('card-top-played', bitaDirection);
                bottomRefBita?.classList.replace('card-bottom-played', bitaDirection);
            }

            delay(bitaDisplayInterval).then((timeoutId) => {
                if (timeoutId) {
                    setBita();
                    clearTimeout(timeoutId)
                    timeoutId = null
                    console.log('cleared')
                }
            });
        }
    }, [
        playedCards,
        bottomPlayerId,
        topPlayerId,
        boardWinnerId,
        bottomCardRefs,
        topCardRefs,
        isViewRoom,
    ]);

    useEffect(() => {
        if (!allCardsPassedToOpponent) return;  // Early return if no cards are passed

        // Determine which player passed the cards
        const isTopCardsPassed = topPlayerId === allCardsPassedToOpponent;
        const targetRefs = isTopCardsPassed ? topCardRefs : bottomCardRefs;

        // Ensure the targetRefs array is not empty
        if (!targetRefs?.current.length) return;

        // Create a utility function to check for existing unwanted classes
        const shouldAddClass = (ref) => {
            return ![
                'bita-top',
                'bita-bottom',
                'card-bottom-played',
                'card-top-played'
            ].some(className => ref?.classList.contains(className));
        };

        // Determine the additional class name based on conditions
        const additionalClassName = isTopCardsPassed
            ? ['top-cards-passed']
            : ['bottom-cards-passed'];

        if (isViewRoom) additionalClassName.push('rotation');

        // Filter refs and add classes to the appropriate elements
        audioHandler('cardSlide')

        targetRefs?.current
            .filter(shouldAddClass)
            .forEach(ref => {
                ref?.classList.add(...additionalClassName);
            });

    }, [
        allCardsPassedToOpponent,
        topPlayerId,
        bottomPlayerId,
        isViewRoom
    ]);

    useEffect(() => {
        if (!playerIdWithPublicCards || playWithOpenCards) return;

        const {platformId, cards} = playerIdWithPublicCards;
        // Ensure that playerIdWithPublicCards is not the current user and cards exist
        if (platformId !== userId && cards?.length) {
            setPlayWithOpenCards(true);

            // Determine if the top player has public cards and set the appropriate refs
            const isTopCards = topPlayerId === platformId;
            const targetRefs = isTopCards ? topCardRefs : bottomCardRefs;
            // Add the classes to each card
            delay(0).then((timeoutId) => {
                if (timeoutId) {
                    cards?.forEach((card, index) => {
                        const ref = targetRefs?.current[index];
                        if (ref) {
                            const cardName = `card-name_${card.suit}${card.rank.name}`;
                            ref?.classList.add('card-visible', cardName);
                        }
                    });
                    clearTimeout(timeoutId)
                    timeoutId = null
                    console.log('cleared')
                }
            });
        }
    }, [
        playerIdWithPublicCards,
        topPlayerId,
        bottomPlayerId,
        topCardRefs,
        bottomCardRefs,
        playWithOpenCards,
        userId
    ]);

    useEffect(() => {
        // Convert external combination cards to an array
        const externalCombinationCards = Object.values(combinationExternalCards);
        // Return early if no relevant cards exist
        if (!externalCombinationCards.length && !combinationInternalCards.length) return;

        // Determine if the number of external and internal cards has changed
        const hasNewCards = externalCombinationCards.length !== combinationInternalCards.length;
        const shouldUpdateSelectable = externalCombinationCards.length && cardsSelectable;

        // If new cards exist and should update selection
        if (hasNewCards && shouldUpdateSelectable) {
            setCombinationInternalCards(externalCombinationCards);
            updateCardSelectionClass(externalCombinationCards, 'add');

            setBottomInternalCards(prevState =>
                prevState.map(card => ({...card, selectable: true}))
            );
        }

        // If no cards are selectable and external combination cards are empty
        if (!cardsSelectable && !externalCombinationCards.length) {
            updateCardSelectionClass(externalCombinationCards, 'remove');

            // Clear internal cards and update the bottom cards' selectable state
            setCombinationInternalCards([]);

            setBottomInternalCards(prevState =>
                prevState.map(card => ({...card, selectable: false}))
            );
        }
    }, [
        combinationExternalCards,
        combinationInternalCards,
        cardsSelectable,
        updateCardSelectionClass
    ]);

    useEffect(() => {
        const handleCards = (cards, action ) => {
            if (cards) {
                if (topPlayerId in cards) {
                    handlePublishCombinationCards(cards[topPlayerId], topCardRefs, action, false);
                }

                if (bottomPlayerId in cards && bottomPlayerId !== userId && !historyRoomPlayer) {
                    handlePublishCombinationCards(cards[bottomPlayerId], bottomCardRefs, action, true);
                }
                if (historyRoomPlayer && bottomPlayerId in cards) {
                    handlePublishCombinationCards(cards[bottomPlayerId], bottomCardRefs, action, true);
                }
                audioHandler('cardsShuffle')
            }
        };
        if (!publishedCombinationCards && publishInternalCards) {
            // Handle removing cards if combination cards are not published
            handleCards(publishInternalCards, 'remove');
        } else if (publishedCombinationCards) {
            // // Set internal cards and handle adding combination cards
            setPublishInternalCards(publishedCombinationCards);
            handleCards(publishedCombinationCards, 'add');
        }

    }, [
        publishedCombinationCards,
        publishInternalCards,
        topPlayerCards,
        bottomPlayerCards,
        topPlayerId,
        bottomPlayerId,
        userId,
    ]);

    return (
        <div>
            <div className='top-cards-wrap'>
                {topPlayerCards}
            </div>
            <Deck/>
            <div className='bottom-cards-wrap'>
                {bottomPlayerCards}
            </div>
        </div>
    );
}

export default Board;
