import React from 'react';
import HistoryList from "../../../components/HistoryList/HistoryList";
import {useDispatch} from "react-redux";
import {lobbyOpenModal} from "../../../../redux/actions/lobby/actions";

const MobileHistory = React.memo(function () {
    const dispatch = useDispatch();
    return  <>
        <div
            className="mobile-history-filter"
            onClick={()=>dispatch(lobbyOpenModal({name: 'HistoryFilterMobile'}))}
        >
            <i className="icon-sort-icon"/>
        </div>
        <HistoryList/>
    </>
})

export default MobileHistory