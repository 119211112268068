import {useState} from "react";
import {getShortUniqueKey} from "../../../utils";
import RadioButton from "../../../core/RadioButton/RadioButton";
import Checkbox from "../../../core/Checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import {GAME_CONVENTIONS} from "../../../constants";
import React from "react";

 const CreateRoomConventions = React.memo(function ({handleConventionsChange}) {
    const {t} = useTranslation();
    const [gameTypes, checkbox] = GAME_CONVENTIONS
    const [state, setState] = useState({
        game_type: gameTypes[0].value,
        without_boy: false,
        private_game: false,
    });

    const onConventionItemClick = ({type, value, label}) => {
        const updatedState = {...state};
        if (type === 'radio') {
            updatedState['game_type'] = value;
        } else {
            updatedState[label] = !updatedState[label];
        }
        setState(updatedState);
        handleConventionsChange(updatedState);
    };

    return (
            <>
                {gameTypes.map((item) => {
                    return (
                        <div
                            key={getShortUniqueKey()}
                            className="create-room-row"
                            onClick={() => onConventionItemClick(item)}
                        >
                            <RadioButton
                                checked={state.game_type === item.value}
                                title={t(`form.${item.label}`)}
                            />
                            <i className={`icon-${item.value}`} />
                        </div>
                    )
                })
                }
                {
                    checkbox.map((item) => {
                        return (
                            <div
                                key={getShortUniqueKey()}
                                className="create-room-row create-room-row--border"
                                onClick={() => onConventionItemClick(item)}
                            >
                                <Checkbox
                                    checked={state[item.label]}
                                    title={t(`form.${item.label}`)}
                                />
                                <i className={`icon-${item.value}`} />
                            </div>

                        )
                    })
                }
            </>
    )
},(prevProps, nextProps)=> true)

export default CreateRoomConventions

