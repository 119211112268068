import {useSelector} from "react-redux";
import FilterMobile from "./FilterMobile";
import HistoryFilterMobile from "./HistoryFilterMobile";
import TournamentActiveRoomsMobile from "./TournamentActiveRoomsMobile";
import TournamentInfoMobile from "./TournamentInfoMobile";
import TournamentPlayersModal from "./TournamentPlayersModal";
import Modal from "../../../core/Modal/Modal";
import MobileCreateGameModal from "./CreateRoomMobile";
import {getShortUniqueKey} from "../../../utils";

const CombinedModals = {
    FilterMobile: {animated: true, modal: FilterMobile, isMobile: true},
    HistoryFilterMobile: {animated: true, modal: HistoryFilterMobile, isMobile: true},
    TournamentActiveRoomsMobile: {animated: true, modal: TournamentActiveRoomsMobile, isMobile: true},
    TournamentInfoMobile: {animated: true, modal: TournamentInfoMobile, isMobile: true},
    TournamentPlayersModal: {animated: true, modal: TournamentPlayersModal, isMobile: true},
    MobileCreateGameModal: {animated: true, modal: MobileCreateGameModal, isMobile: true},
};

function GameModal() {
    const isMobile = useSelector(state => state.user.isMobile);
    const activeModalName = useSelector(state => state.lobby.activeModalName);
    const Component = CombinedModals[activeModalName];
    const isRender = Component?.isMobile ? Component?.isMobile === isMobile : true;
    if (!Component) return
    const ActiveModal = Component.modal;

    return (
        <>
            {
                isRender && <Modal
                    key={getShortUniqueKey()}
                    animated={Component?.animated}>
                    <ActiveModal/>
                </Modal>
            }
        </>
    )
}

export default GameModal
