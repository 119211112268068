import { combineReducers } from 'redux';
import userReducer from './user';
import cashReducer from './cash';
import lobbyReducer from './lobby';
import roomsReducer from './rooms';
import tournamentReducer from './tournament';
import historyReducer from './history';

const rootReducer = combineReducers({
    user: userReducer,
    lobby: lobbyReducer,
    cash: cashReducer,
    rooms: roomsReducer,
    tournament: tournamentReducer,
    history: historyReducer,
});
export default rootReducer;
