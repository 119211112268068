function TournamentActiveRoomItem({room, index}) {
    return (
        <div className={`tournament-active-rooms__item`}>
            <div  id={room?.id} data-room-index={index} className='tournament-room-item'></div>
            <div className="tournament-active-rooms__item--top">
                <span className="tournament-active-rooms__item--top--index">{index + 1}</span>
                <p className="tournament-active-rooms__item--top--id">ID: {room?.id}</p>
                <p className="tournament-active-rooms__item--players">
                    <span className="tournament-active-rooms__item--player-id">{room?.playerId}</span>
                    <span>vs</span>
                    <span className="tournament-active-rooms__item--player-id">{room?.opponentId}</span>
                </p>
            </div>
            <p className="tournament-active-rooms__item--bottom">
                <span className="tournament-active-rooms__item--bottom--points">{room?.points[room?.playerId] || 0}</span>
                -
                <span className="tournament-active-rooms__item--bottom--points">{room?.points[room?.opponentId] || 0}</span>
            </p>
        </div>
    )
}
export default TournamentActiveRoomItem;