import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../core/Button/Button";
import React from "react";
function CanceledTournamentButton() {
    const {t} = useTranslation()
    const isMobile = useSelector(state => state.user.isMobile)
    return !isMobile ? (
        <div className="button-size">
            <Button
                type='blue-border'
                text={t(`tournament.canceled`)}
                onClick={() => {}}
            />
        </div>
    ) : (
        <i className="icon-arrow icon-right" />
    );
}

export default CanceledTournamentButton