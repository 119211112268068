import Input from "../../../../../../core/Input/Input";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

function SettingsInput(props) {
    const {
        value,
        placeholder,
        handleChange,
        serverError,
        isVerified,
        hasChange
    } = props;
    const [error, setError] = useState('');
    const {t} = useTranslation()

    useEffect(() => {
        if (serverError) {
            setError(serverError)
        } else {
            setError('')
        }
    }, [serverError]);


    useEffect(() => {
        if (value === '') {
            setError('')
        }
    }, [value]);

    const setNickname = (value) => {
        if (value.length > 8 || !value.length) {
            setError(t('settings.validation'));
        } else {
            error && setError('');
        }
        handleChange(value);
    }
    return (
        <div className="settings-form-wrapper">
            <Input
                type={`
                    settings-filled 
                    ${error ? 'settings-filled--error' : ""}
                    ${isVerified && !hasChange ? 'settings-filled--success' : ""}
                `}
                error={error && t(error)}
                handleChange={(val) => setNickname(val)}
                value={value}
                placeholder={placeholder}
            />
            {isVerified && !hasChange  && <i className="settings__check"/>}
        </div>
    )
}

export default SettingsInput;