import React from 'react';
import {SUIT_VALUES} from "../../../../../constants";

const PointBarItem = function ({point = null,trump}) {

    if (!point) return;
    const {handPoints, combinationPoints, globalPoints} = point;
    return (
        <li className="point-bar__list--item">
            <div className="point-bar__list--wrap">
                <p className={`point-bar__list--num ${trump ? `icon-${SUIT_VALUES[trump]}` : ''}`}>{globalPoints}</p>
                <p className="point-bar__list--score">{combinationPoints} + {handPoints}</p>
            </div>
        </li>
    )
}

export default PointBarItem
