import {useTranslation} from "react-i18next";
import Button from "../../../../../core/Button/Button";
import {viewHistoryRoom} from "../../../../../redux/actions/history/actions";
import {useDispatch} from "react-redux";

function HistoryListItemMobileInner({data, currency, isOpen = false}) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    return (
        <div>
            <div className="mobile-history-list__item">
                <div className="mobile-history-list__item--col">
                    <i className={`icon-${data?.table_history_type[0]}`}/>
                    <span>{data?.table_history_game_id}</span>
                </div>
                <div className="mobile-history-list__item--col">
                    <span>{data?.table_history_till}</span>
                </div>
                <div className="mobile-history-list__item--col">
                    <span>{data?.table_history_bet[currency]} {currency}</span>
                    <i className={`icon-arrow ${isOpen ? "rotate":""}`}/>
                </div>
            </div>
            {isOpen &&
                <ul className="mobile-history-list__item--dropdown">
                    <li className="mobile-history-list__item--dropdown--row">
                        <span>{t("history.game_id")}</span>
                        {data?.table_history_game_id}
                    </li>
                    <li className="mobile-history-list__item--dropdown--row">
                        <span>{t("history.bet")}</span>
                        {data?.table_history_bet[currency]} {currency}
                    </li>
                    <li className="mobile-history-list__item--dropdown--row">
                        <span>{t("history.opponent")}</span>
                        {data?.table_history_opponent}
                    </li>
                    <li className="mobile-history-list__item--dropdown--row">
                        <span>{t("history.till")}</span>
                        {data?.table_history_till}
                    </li>
                    <li className="mobile-history-list__item--dropdown--row">
                        <span>{t("history.step_time")}</span>
                        {data?.table_history_step_time} {t('history.s')}
                    </li>
                    <li className="mobile-history-list__item--dropdown--row">
                        <span>{t("history.bank_time")}</span>
                        {data?.table_history_bank_time} {t('history.s')}
                    </li>
                    <li className="mobile-history-list__item--dropdown--row">
                        <span>{t("history.score")}</span>
                        {data?.table_history_points}
                    </li>
                    <li className="mobile-history-list__item--dropdown--row">
                        <span>{t("history.result")}</span>
                        {t(`history.${data?.table_history_result}`)}
                    </li>
                    <li className="mobile-history-list__item--dropdown--row">
                        <span>{t("history.type")}</span>
                        <span>
                            {
                                data?.table_history_conventions?.map((item, index) => {
                                    return <i
                                        key={`icon-${item}${index}`}
                                        className={`icon-${item} table-icon`}
                                    />
                                })
                            }
                        </span>
                    </li>
                    <li className="mobile-history-list__item--dropdown--row">
                        <Button
                            type="blue"
                            text={t('buttons.view')}
                            onClick={() => dispatch(viewHistoryRoom({roomId: data?.table_history_game_id}))}
                        />
                    </li>
                </ul>
            }
        </div>

    )
}

export default HistoryListItemMobileInner;