import React from "react";
import {useDispatch} from "react-redux";
import TournamentListItemMobile from "./TournamenListItemMobile/TournamentListItemMobile";
import {lobbyOpenModal} from "../../../../redux/actions/lobby/actions";
import {setSideBarTournamentId} from "../../../../redux/actions/tournament/actions";

function MobileTournamentList({rows = [], currency}) {
    const dispatch = useDispatch()
    const handleTournamentItemClick = (row) => {
        dispatch(setSideBarTournamentId({tournamentId: row?.table_tournament_id}))
        dispatch(lobbyOpenModal({name: 'TournamentInfoMobile'}))
    }
    return <div className="mobile-tournament-list">
        {rows?.map((item, index) => {
            return (
                <TournamentListItemMobile
                    key={index}
                    data={item}
                    onClick={handleTournamentItemClick}
                    currency={currency}
                />
            )
        })}
    </div>
}

export default MobileTournamentList