/* eslint-disable */
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import DesktopRoomList from "./DesktopRoomList";
import MobileRoomList from "./MobileRoomList";

function RoomList() {
    const {isMobile, currency} = useSelector((state) => state.user);
    const roomList = useSelector((state) => state.cash.roomList);
    const filter = useSelector((state) => state.cash.filter);
    let filteredRoomList = [...roomList];
    const [rows, setRows] = useState([])

    const applyFilters = () => {
        for (const key in filter) {
            if (filter[key] && key === "minBet") {
                filteredRoomList = filteredRoomList.filter(item => {
                    return (+item.rows.table_room_bet[currency]) >= filter.minBet
                })
            }
            if (filter[key] && key === "maxBet") {
                filteredRoomList = filteredRoomList.filter(item => {
                    return (+item.rows.table_room_bet[currency]) <= filter.maxBet
                })
            }
            if (filter[key]?.length && key === "score") {
                const values = filter.score.map(({value}) => value);
                filteredRoomList = filteredRoomList.filter(item =>
                    values?.includes(item.rows.table_room_till)
                );
            }
            if (filter[key]?.length && key === "time") {
                const values = filter.time.map(({value}) => value);
                filteredRoomList = filteredRoomList.filter(item =>
                    values?.includes(item.rows.table_room_stepTime)
                );
            }
            if (filter[key]?.length && key === "status") {
                const values = filter.status.map(({value}) => value);
                filteredRoomList = filteredRoomList.filter(item =>
                    values?.includes(item.rows.table_room_status)
                );
            }
            if (filter[key]?.length && key === "type") {
                const values = filter.type.map(({value}) => value);
                filteredRoomList = filteredRoomList.filter(item =>
                    values.some((val) => item.rows.table_room_type.indexOf(val) !== -1)
                );
            }
        }

        const updatedRows = filteredRoomList.map(item => {
            return item.rows
        });
        setRows(updatedRows)
    }

    useEffect(() => {
        applyFilters()
    }, [filter, roomList]);
    return (
        !isMobile
            ?
            <DesktopRoomList rows={rows} currency={currency}/>
            :
            <MobileRoomList rows={rows} currency={currency}/>
    )
}

export default RoomList