import TableHeader from "./TableHeader/TableHeader";
import TableBody from "./TableBody/TableBody";

function Table(props) {
    const {
        name,
        columns,
        rows,
        handleClick = () => {},
        currency,
    } = props;
    return (
        <div className="table-wrapper">
            <div className="table">
                <TableHeader columns={columns}/>
                <TableBody
                    name={name}
                    rows={rows}
                    handleClick={handleClick}
                    currency={currency}
                />
            </div>
        </div>
    )
}

export default Table