import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../core/Button/Button";
import React from "react";
import {joinTournament} from "../../../../../../redux/actions/tournament/actions";
function ProgressTournamentButton({row}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const userId = useSelector(state => state.user.id);
    const isMobile = useSelector(state => state.user.isMobile)
    const players = useSelector((state) => state.tournament.players);
    const list = useSelector((state) => state.tournament.list);
    const tournamentPlayers = players[row.table_tournament_id] || {};
    const tournament = list[row.table_tournament_id] || {};
    if (!tournament?.info.registrationEnabled) return;
    let type = tournament?.info?.hasReBuy ? 'green' : 'blue-border' ;
    let text = tournament?.info?.hasReBuy ? 'reBuy' : 'started';
    let action = () => dispatch(joinTournament({tournamentId: tournament.id}))

    if (['PENDING', 'PROGRESS'].includes(tournamentPlayers[userId]?.status)) {
        type = 'red';
        text = 'joined';
    }
    return !isMobile ? (
        <div className="button-size">
            <Button
                type={type}
                onClick={action}
                text={t(`tournament.${text}`)}
            />
        </div>
    ) : (
        <i className="icon-arrow icon-right" onClick={action}/>
    );
}

export default ProgressTournamentButton