// import Avatar from "../../../../core/SettingsAvatar/SettingsAvatar";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function BalanceMobile() {
    const {t} = useTranslation()
    const {balance, currency} = useSelector(state => state.user);
    return (
        <div className="mobile-header-balance">
            <div className="mobile-header-balance-avatar">
                {/*<Avatar src={`/images/avatar/avatar${avatar+1}.png`} />*/}
            </div>
            <div className="mobile-header-balance-wrapper">
                <h1>{t('balance')}</h1>
                <span>{`${balance} ${currency}`}</span>
            </div>
        </div>
    )
}
export default BalanceMobile