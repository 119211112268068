import {useSelector} from "react-redux";
import ModalBody from "../../../core/Modal/ModalBody/ModalBody";
import TournamentInfo from "../TournamentInfo/TournamentInfo";
function TournamentInfoMobile() {
    const selectedTournamentId = useSelector(state => state.tournament.sideBarTournamentId);
    return <ModalBody>
        {selectedTournamentId && <TournamentInfo/> }
    </ModalBody>
}

export default TournamentInfoMobile