function SettingsCardItem(props) {
    const {
        item,
        name,
        isActive,
        onItemClick = () => {}
    } = props;

    return (
        <li
            className={`settings__cards--item ${isActive ? "active" : ''}`}
            onClick={() => onItemClick()}>
            <img alt={name} src={item} loading="lazy"/>
        </li>
    )
}

export default SettingsCardItem