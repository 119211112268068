import TOURNAMENT from "../actions/tournament/types";
import GAME from "../actions/lobby/types";
import UserSocket from "../../sockets/user-socket";
import {deepClone} from "../../utils";

const initialState = {
    list: {},
    players: {},
    rooms: {},
    topWinners:[],
    sideBarTournamentId: null,
    isActiveTournamentRoomsSection: false
};
const reducer = (state = initialState, action) => {
    const socket = UserSocket.debouncedEmit.bind(UserSocket);
        switch (action?.type) {
            case TOURNAMENT.INITIAL_LOAD:
                return {
                    ...state,
                    list: action.payload.tournamentList,
                    players: action.payload.playerList,
                    rooms: action.payload.rooms,
                    topWinners: action.payload.topWinners
                };

            case TOURNAMENT.UPDATE_ROOMS:
                return {
                    ...state,
                    rooms: {
                        ...state.rooms,
                        [action.payload.tournamentId]: action.payload.rooms
                    }
                };

            case TOURNAMENT.SET_TOURNAMENTS:
                return {
                    ...state,
                    list: action.payload.tournamentList
                };

            case TOURNAMENT.JOIN_TOURNAMENT:
                socket(GAME.EVENT, {action: action.type, payload: action.payload, ctx: 'Tournaments'})
                return state;

            case TOURNAMENT.QUIT_TOURNAMENT:
                socket(GAME.EVENT, {action: action.type, payload: action.payload, ctx: 'Tournaments'})
                return state;

            case TOURNAMENT.UPDATE_PLAYERS:
                return {
                    ...state,
                    players: {
                        ...state.players,
                        [action.payload.tournamentId]: action.payload.players
                    }
                }

            case TOURNAMENT.SET_NEW_TOURNAMENT:
                return {
                    ...state,
                    list: {
                        ...state.list,
                        ...action.payload.tournament
                    },
                    players: {
                        ...state.players,
                        ...action.payload.players
                    },
                }

            case TOURNAMENT.OPEN_TOURNAMENT_REGISTRATION:
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [action.payload.tournamentId]: {
                            ...state.list[action.payload.tournamentId],
                            info: {
                                ...state.list[action.payload.tournamentId].info,
                                registrationEnabled: true
                            }

                        }
                    }
                }

            case TOURNAMENT.UPDATE_TOURNAMENT:
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [action.payload.tournamentId]: {
                            ...state.list[action.payload.tournamentId],
                            ...action.payload.data
                        }
                    }
                }

            case TOURNAMENT.SET_SIDEBAR_TOURNAMENT_ID:
                return {
                    ...state,
                    sideBarTournamentId: action.payload.tournamentId
                }

            case TOURNAMENT.UPDATE_TOURNAMENT_ROUND_TIMER:
                return {
                    ...state,
                    list: {
                        ...state.list,
                        [action.payload.tournamentId]: {
                            ...state.list[action.payload.tournamentId],
                            info: {
                                ...state.list[action.payload.tournamentId].info,
                                nextRoundTimer: action.payload.nextRoundTimer
                            }

                        }
                    }
                }

            case TOURNAMENT.DELETE_TOURNAMENT:
                const updatedList = deepClone(state.list);
                delete updatedList[action.payload.tournamentId]
                const players = deepClone(state.players);
                delete players[action.payload.tournamentId]
                return {
                    ...state,
                    list: updatedList,
                    players: players,
                }

            case TOURNAMENT.UPDATE_TOP_WINNERS:
                return {
                    ...state,
                    topWinners: action.payload.topWinners
                }

            case TOURNAMENT.SET_TOURNAMENT_ACTIVE_ROOMS_ID:
                return {
                    ...state,
                    isActiveTournamentRoomsSection: !state.isActiveTournamentRoomsSection
                }

            default:
                return state;
        }
    }
;
export default reducer;
