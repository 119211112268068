import FilterMultiselect from "../../components/FilterMultiselect/FilterMultiselect";
import {GAME_CONVENTIONS, GAME_STATUS, PLAYING_TILLS, STEP_TIMES} from "../../../../../constants";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {resetFilters, setFilters} from "../../../../../redux/actions/cash/actions";
import ReactSlider from "react-slider";
import React, {useState} from "react";
import Button from "../../../../../core/Button/Button";
import {closeLobbyModal} from "../../../../../redux/actions/lobby/actions";
import FilterCheckboxMobile from "../../components/FilterCheckboxMobile/FilterCheckboxMobile";
function MobileFilter() {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const filter = useSelector(state => state.cash.filter);
    const [mobileFilter, setMobileFilter] = useState(filter);
    const handleResetFilters = () => {
        setMobileFilter({
            minBet: '',
            maxBet: '',
            score: [],
            time: [],
            status: [],
            type: []
        })
        dispatch(closeLobbyModal());
        dispatch(resetFilters());
    }
    const setMobileSelect = (data) => {
        setMobileFilter({
            ...mobileFilter,
            ...data
        })
    }

    const onRangeChange = (values) => {
        setMobileFilter(prevFilter => ({
            ...prevFilter,
            minBet: values[0],
            maxBet: values[1]
        }));
    };
    const handleMobileFilterChange = ({ field, item }) => {
        setMobileFilter(prevFilter => {
            const updatedField = prevFilter[field].includes(item)
                ? prevFilter[field].filter(existingItem => existingItem.value !== item.value)
                : [...prevFilter[field], item];
            return {
                ...prevFilter,
                [field]: updatedField
            };
        });
    };
    const checkMobileItemStatus = ({field, item}) => {
        return mobileFilter[field]?.findIndex(elem => elem.value === item.value) !== -1
    }
    const handleCloseMobileFilterModal = () => {
        dispatch(closeLobbyModal());
    }
    const handleApplyMobileFiltersChange = (data) => {
        dispatch(closeLobbyModal());
        dispatch(setFilters(data))
    }

    return <div className="mobile-cash-lobby-filter">
        <div className="mobile-cash-lobby-filter__group">
            <div className="mobile-cash-lobby-filter__group--row">
                <p>{t("filter.bet")}</p>
                <i
                    className="icon-close"
                    onClick={() => handleCloseMobileFilterModal()}
                />
            </div>
        </div>
        <div className="mobile-cash-lobby-filter__group">
            <ReactSlider
                className="mobile-bet-range-slider"
                thumbClassName="mobile-bet-range-thumb"
                trackClassName="mobile-bet-range-track"
                defaultValue={(filter?.minBet || filter?.maxBet) ? [filter?.minBet, filter?.maxBet] : [0, 20000]}
                min={0}
                max={20000}
                ariaLabel={['mobile-bet-range-lower thumb', 'mobile-bet-range-upper thumb']}
                ariaValuetext={state => `mobile-bet-range value ${state.valueNow}`}
                renderThumb={(props, state) => {
                    const {key, ...restProps} = props
                    return (<div {...restProps} key={key}>
                        <span className="amount__value">{state.valueNow}</span>
                    </div>)
                }}
                pearling
                minDistance={1}
                onChange={(values) => onRangeChange(values)}
            />
        </div>
        <div className="mobile-cash-lobby-filter__group">
            <FilterMultiselect
                className="filter-score"
                data={PLAYING_TILLS}
                handleChange={setMobileSelect}
                placeholder={t("filter.score")}
                name={"score"}
                defaultValue={mobileFilter['score']}
            />
            <FilterMultiselect
                className="filter-time"
                data={STEP_TIMES}
                handleChange={setMobileSelect}
                placeholder={t("filter.step_time")}
                name={"time"}
                defaultValue={mobileFilter['time']}
            />
        </div>
        <div className="mobile-cash-lobby-filter__group">
            {
                GAME_STATUS.map((item, index) => {
                    const data = {field: "status", item}
                    return (
                        <FilterCheckboxMobile
                            key={index}
                            title={t(`form.${item.label}`)}
                            isChecked={checkMobileItemStatus(data)}
                            onClick={() => handleMobileFilterChange(data)}
                        />
                    )
                })
            }
        </div>
        <div className="mobile-cash-lobby-filter__group">
            {
                GAME_CONVENTIONS.flat().map((item, index) => {
                    const data = {field: "type", item};
                    return (
                        <FilterCheckboxMobile
                            key={index}
                            title={t(`form.${item.label}`)}
                            isChecked={checkMobileItemStatus(data)}
                            onClick={() => handleMobileFilterChange(data)}
                            icon={item.value}
                        />
                    )
                })
            }
        </div>
        <div className="mobile-cash-lobby-filter__group">
            <div className="mobile-cash-lobby-filter__group--row">
                <Button
                    onClick={() => handleApplyMobileFiltersChange(mobileFilter)}
                    type={"blue"}
                    text={t("filter.apply")}
                />
                <Button
                    onClick={() => handleResetFilters()}
                    type={"lightblue"}
                    text={t("filter.clear_all")}
                />
            </div>
        </div>
    </div>
}
export default MobileFilter