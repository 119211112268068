import React from 'react';
import Board from "./Board";
import {useDispatch, useSelector} from "react-redux";
import Player from "./Player";
import PointBar from "./PointBar";
import {historyRoomNextAction} from "../../../redux/actions/history/actions";
import RoomModal from "./RoomModal";

const Room = function ({roomId, isActive}) {
    const entered = useSelector(state => state.rooms.entered);
    const {id: userId, currency, isMobile} = useSelector(state => state.user);
    const room = entered[roomId];
    const opponentId = room?.round?.opponentId;
    const dispatch = useDispatch()
    const players = room?.round?.players;
    let topPlayer = players && ((room?.isViewRoom) ? players[room?.playerId] : players[opponentId]);
    let bottomPlayer = players && ((room?.isViewRoom) ? players[room?.opponentId] : players[userId]);

    return (
        <div className={`game ${isActive ? 'active' : 'inActive'}`}>
            <div className="game__wrapper">
                <div className="room--wrapper">
                    {!isMobile && <div className="room__ratio"/>}
                    <div className={"board"}>
                        <div className="board__wrapper">
                            {!isMobile &&
                                <div className="board__view">
                                    <span className="board__view--wrapper"></span>
                                </div>
                            }
                            <div className={`board__content ${room?.isViewRoom ? 'board__content-view-room' : ''}`}>
                                {
                                    topPlayer && (
                                        <Player
                                            playerData={topPlayer}
                                            playable={false}
                                            top={true}
                                            position={'top'}
                                            historyRoomPlayer={room?.historyRoomPlayer}
                                        />
                                    )
                                }

                                <RoomModal roomId={roomId} userId={userId}/>

                                {
                                    ((room?.status === 'PROGRESS' && !room?.historyRoomPlayer) || room?.historyRoomPlayer) &&
                                    <div className="board__center">
                                        <PointBar
                                            points={room?.points}
                                            topPlayerId={topPlayer?.platformId}
                                            bottomPlayerId={bottomPlayer?.platformId}
                                            roomId={room?.roomId}
                                            conventions={room?.conventions}
                                            isInGame={userId === bottomPlayer?.platformId}
                                            currency={currency}
                                        />
                                        <Board
                                            userId={userId}
                                            roomId={roomId}
                                            isRoomActive={isActive}
                                            activePlayerId={room?.round?.activePlayerPlatformId}
                                            roundId={room?.round?.id}
                                            bottomPlayerId={bottomPlayer?.platformId}
                                            topPlayerId={topPlayer?.platformId}
                                            topExternalCards={topPlayer?.cards}
                                            bottomExternalCards={bottomPlayer?.cards}
                                            trump={room?.round?.trump}
                                            playedCards={room?.round?.playedCards}
                                            boardWinnerId={room?.round?.boardWinnerId}
                                            publishedCombinationCards={room?.round?.publishedCombinationCards}
                                            combinationExternalCards={room?.selectedCombinationCards}
                                            cardsSelectable={room?.cardsSelectable}
                                            allCardsPassedToOpponent={room?.round?.allCardsPassedToOpponent}
                                            playerIdWithPublicCards={room?.round?.playerIdWithPublicCards}
                                            isViewRoom={room?.isViewRoom}
                                            historyRoomPlayer={room?.historyRoomPlayer}
                                        />
                                    </div>
                                }
                                {
                                    room?.historyRoomPlayer && <div className='history-controllers'>
                                        <br/>
                                        <div onClick={() => dispatch(historyRoomNextAction({roomId: room?.roomId}))}>next
                                        </div>
                                    </div>
                                }
                                {
                                    bottomPlayer && (
                                        <Player
                                            playable={bottomPlayer.platformId === userId && (!room?.isHistoryRoom && !room.historyRoomPlayer)}
                                            playerData={bottomPlayer}
                                            canPassAllCards={room?.round?.canPassAllCards}
                                            gameTypes={room?.conventions?.gameTypes}
                                            top={false}
                                            historyRoomPlayer={room?.historyRoomPlayer}
                                            position={'bottom'}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Room
