import UserSocket from "../../sockets/user-socket";
import ROOMS from "../actions/rooms/types";
import {removeKeys} from "../../utils";
import LOBBY from "../actions/lobby/types";

const initialState = {
    entered: {},
    timers: {}
};
const roomInitialData = {
    roomId: null,
    status: null,
    playerId: null,
    opponentId: null,
    conventions: [],
    canJoin: false,
    joinRequest: false,
    modal: {},
    round: {},
    selectedCombinationCards: {},
    cardsSelectable: false,
    cardsChanged: false,
    timers: {}
}
const reducer = (state = initialState, action) => {
    const socket = UserSocket.debouncedEmit.bind(UserSocket);
        let room = null
        let timer = null
        switch (action?.type) {

            case ROOMS.ROOM_ENTER:
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: {
                            ...roomInitialData,
                            ...action.payload
                        }
                    }
                }

            case ROOMS.EXIT_ROOM:
                const entered = {...state.entered};
                if (entered[action.payload.roomId]) delete entered[action.payload.roomId];
                return {
                    ...state,
                    entered,
                }

            case ROOMS.CLOSE_ROOM:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state

            case ROOMS.RESTORE_ENTERED_ROOMS:
                const restoredRooms = {}
                Object.values(action.payload.enteredRooms).forEach(room => {
                    restoredRooms[room.roomId] = {
                        ...roomInitialData,
                        ...room
                    }
                })
                return {
                    ...state,
                    entered: restoredRooms
                }

            case ROOMS.ROOM_REJECT_OPPONENT:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state

            case ROOMS.ROOM_ACCEPT_OPPONENT:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state

            case ROOMS.ROOM_UPDATE:
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: {
                            ...state.entered[action.payload.roomId],
                            ...action.payload,
                        }
                    }
                }

            case ROOMS.ROOM_FINISHED:
                room = {
                    ...state.entered[action.payload.roomId],
                    modal: action.payload.modal,
                    joinRequest: false,
                    round: action.payload.round
                };
                timer = removeKeys({...state.timers}, [action.payload.roomId])
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    },
                    timers: timer
                }

            case ROOMS.ROOM_ACCEPT_TRUMP:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state;

            case ROOMS.ROOM_REJECT_TRUMP:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state;

            case ROOMS.ROOM_SET_WAITING_DIALOG:
                room = {
                    ...state.entered[action.payload.roomId],
                    roomDialog: {
                        isActive: true,
                        msg: action.payload.msg,
                        winnerId: action.payload.winnerId,
                        loserId: action.payload.loserId,
                    },
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_UPDATE_ROUND:
                room = {
                    ...state.entered[action.payload.roomId],
                    round: {
                        ...state.entered[action.payload.roomId].round,
                        ...action.payload.round
                    },
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_PLAY_CARD:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state

            case ROOMS.ROOM_PLAYED_CARD:
                room = {
                    ...state.entered[action.payload.roomId],
                    playedCard: action.payload.playedCard
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_ANNOUNCE_COMBINATION:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state

            case ROOMS.ROOM_OPEN_MODAL:
                room = {
                    ...state.entered[action.payload.roomId],
                    modal: {
                        isActive: true,
                        name: action.payload.name,
                        msg: action.payload.msg,
                    }
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_CLOSE_MODAL:
                room = {
                    ...state.entered[action.payload.roomId],
                    modal: {}
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_ANNOUNCE_COMBINATION_FAILED:
                room = {
                    ...state.entered[action.payload.roomId],
                    ...action.payload
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_ANNOUNCE_COMBINATION_RANK:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state

            case ROOMS.ROOM_ANNOUNCE_COMBINATION_RANK_FAILED:
                room = {
                    ...state.entered[action.payload.roomId],
                    ...action.payload
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_PUBLISH_COMBINATION:
                socket(ROOMS.EVENT, {
                    action: action.type, payload: {
                        ...action.payload,
                        selectedCombinationCards: state.entered[action.payload.roomId].selectedCombinationCards,
                        cardsChanged: state.entered[action.payload.roomId].cardsChanged
                    },
                    ctx: 'Rooms'
                })
                return state

            case ROOMS.ROOM_PUBLISH_COMBINATION_FAILED:
                room = {
                    ...state.entered[action.payload.roomId],
                    ...action.payload
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }

                }

            case ROOMS.ROOM_SET_CARDS_SELECTABLE:
                room = {
                    ...state.entered[action.payload.roomId],
                    cardsSelectable: action.payload.selectable
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_RESET_SELECTED_COMBINATION_CARDS:
                room = {
                    ...state.entered[action.payload.roomId],
                    selectedCombinationCards: {},
                    cardsSelectable: false,
                    modal: {}
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_GET_COMBINATION_CARDS:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state

            case ROOMS.ROOM_SET_COMBINATION_CARDS:
                room = {
                    ...state.entered[action.payload.roomId],
                    ...action.payload,
                    cardsChanged: false,
                    cardsSelectable: true,
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_SELECT_COMBINATION_CARD:
                room = state.entered[action.payload.roomId]
                const cards = room['selectedCombinationCards'] ? {...room['selectedCombinationCards']} : {};
                if (cards[action.payload.card.name]) {
                    delete cards[action.payload.card.name]
                } else {
                    cards[action.payload.card.name] = action.payload.card.data
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: {
                            ...state.entered[action.payload.roomId],
                            cardsChanged: true,
                            selectedCombinationCards: cards
                        }
                    }
                }

            case ROOMS.ROOM_ANNOUNCE_ALL_CARDS_TO_OPPONENT:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state;

            case ROOMS.ROOM_REQUEST_ALL_CARDS_TO_ME:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state;

            case ROOMS.ROOM_ACCEPT_ALL_CARDS_TO_ME_REQUEST:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state;

            case ROOMS.ROOM_REJECT_ALL_CARDS_TO_ME_REQUEST:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'});
                return state;

            case ROOMS.ROOM_ALL_CARDS_PASSED_TO_OPPONENT:
                room = {
                    ...state.entered[action.payload.roomId],
                    modal: {},
                    round: {
                        ...state.entered[action.payload.roomId].round,
                        allCardsPassedToOpponent: action.payload.platformId
                    }
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_RESET_ROUND:
                room = {
                    ...state.entered[action.payload.roomId],
                    round: {}
                }
                return {
                    ...state,
                    entered: {
                        ...state.entered,
                        [action.payload.roomId]: room
                    }
                }

            case ROOMS.ROOM_UPDATE_TIMER:
                const timers = {
                    ...state.timers,
                    [action.payload.roomId]: action.payload
                }
                return {
                    ...state,
                    timers
                }

            case ROOMS.ROOM_LEAVE_WAITING_ROOM:
                socket(LOBBY.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'})
                return state

            case ROOMS.ROOM_JOIN_TO_VIEW:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'})
                return state

            case ROOMS.ROOM_EXIT_FROM_VIEW:
                socket(ROOMS.EVENT, {action: action.type, payload: action.payload, ctx: 'Rooms'})
                return state

            default:
                return state;
        }
    }
;
export default reducer;
