import TournamentPrizePoolItem from "./TournamentPrizePoolItem";
import {useTranslation} from "react-i18next";

function TournamentPrizePool(props) {
    const {
        prizePool = [],
        prize = 0,
        currency = 'AMD',
    } = props;
    const {t} = useTranslation();

    return (
        <div className="tournament-info-prize">
            <p className="tournament-info-prize--title">{t('tournament.prize_pool')}</p>
            <ul className="tournament-info-prize--content">
                {
                    prizePool?.map((pool, index) => {
                        return <TournamentPrizePoolItem
                            key={index}
                            prize={prize}
                            pool={pool}
                            currency={currency}
                        />
                    })
                }
            </ul>
        </div>
    )
}

export default TournamentPrizePool