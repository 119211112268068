import {formatTimeFromTimestamp} from "../../../../../utils";
import React from "react";
import {useTranslation} from "react-i18next";

function TournamentListItemMobile(props) {
    const {
        data,
        currency,
        onClick = () => {}
    } = props
    const {t} = useTranslation();

    return (
        <div className="mobile-tournament-list-item" onClick={() => onClick(data)}>
            <div className="mobile-tournament-list-item-left">
                <div className="mobile-tournament-list-item-left__top">
                    <span className="text">
                       {data?.table_tournament_name} |
                        <span>{formatTimeFromTimestamp(data?.table_tournament_start)}</span><
                        /span>
                    <span className="text">
                        {data?.table_tournament_prize[currency]}
                        <span>{currency}</span>
                    </span>
                </div>
                <div className="mobile-tournament-list-item-left__bottom">
                    <span className="text">
                        {data?.table_tournament_till}|
                        {
                            data?.table_tournament_type.map((item, index) => {
                                return <i
                                    key={`icon-${item}${index}`}
                                    className={`icon-${item} table-icon`}
                                />
                            })
                        }
                    </span>
                    <span>
                        {t('tournament.bet')}: {data?.table_tournament_buy_in[currency]}
                        <span>{currency}</span>
                    </span>
                </div>
            </div>
            <div className="mobile-tournament-list-item-right">
                <i className={"icon-arrow"}/>
            </div>
        </div>
    )
}

export default TournamentListItemMobile