const SUIT_VALUES = {'h': 'hearth', 's': 'spades', 'd': 'diamond', 'c':'club', 'A':'a'};

const PLAYING_TILLS = [
    {value: 51, label: 51},
    {value: 101, label: 101},
    {value: 1, label: 1},
    {value: 3, label: 3},
    {value: 5, label: 5},
    {value: 7, label: 7},
    {value: 9, label: 9},
    {value: 11, label: 11},
    {value: 13, label: 13}
];

const STEP_TIMES = [
    {value: 1000000, label: 10},
    {value: 15000, label: 15},
    {value: 30000, label: 30},
    {value: 45000, label: 45}
];

const BANK_TIMES = [
    {value: 60000, label: 60},
    {value: 120000, label: 120},
    {value: 180000, label: 180},
    {value: 240000, label: 240}
];

const GAME_STATUS = [
    {value: "WAITING", label: "waiting"},
    {value: "BUSY", label: "busy"},
];

const GAME_CONVENTIONS = [
    [
        {
            value: 2,
            label: "all_combinations",
            type: 'radio',
        },
        {
            value: 3,
            label: "not_4&100",
            type: 'radio',
        },
        {
            value: 1,
            label: "only_belote",
            type: 'radio',
        },
        {
            value: 40,
            label: "only_tierce",
            type: 'radio',
        },
    ], [
        {
            value: 10,
            label: "without_boy",
            type: 'checkbox',
            hasCheckbox: true
        }, {
            value: 50,
            label: "private_game",
            type: 'checkbox',
            hasCheckbox: true
        },]
];

const COMBINATION_ANNOUNCE_BUTTONS = {
    1: [],
    2: ['fours', '100', '50', 'tierce'],
    3: ['50', 'tierce'],
    40: ['tierce'],
}

const SUIT_ICONS = {
    h: "♥",
    d: "♦",
    s: "♠",
    c: "♣",
    A: "A"
};

const TABS = ["Cash", "Tournament"];

const ROOM_TABLE_TITLES = [
    "table_room_id",
    "table_room_player",
    "table_room_score",
    "table_room_opponent",
    "table_room_bet",
    "table_room_till",
    "table_room_step_time",
    "table_room_bank_time",
    "table_room_type",
    ""
];

const TOURNAMENT_TABLE_TITLES = [
    "table_tournament_name",
    "table_tournament_buy_in",
    "table_tournament_till",
    "table_tournament_type",
    "table_tournament_start",
    "table_tournament_prize",
    ""
]

const HISTORY_TABLE_TITLES = [
    "table_history_game_id",
    "table_history_type",
    "table_history_opponent",
    "table_history_bet",
    "table_history_till",
    "table_history_conventions",
    "table_history_step_time",
    "table_history_started",
];

const COMBINATION_MODAL_BUTTONS = [
    {name: 'tierce', length: 3, counts: [1, 2, 3], cId: 1},
    {name: '50', length: 4, counts: [1, 2], cId: 2},
    {name: '100', length: 5, counts: [1], cId: 3},
    {name: 'fours', length: 4, counts: [1, 2], cId: 4},
]

const CARDS_ORDER_BY_SUIT = {
    c: {h: 1, s: 2, d: 3, c: 4},
    d: {c: 1, h: 2, s: 3, d: 4},
    s: {h: 1, c: 2, d: 3, s: 4},
    h: {c: 1, d: 2, s: 3, h: 4},
    A: {c: 1, d: 2, s: 3, h: 4},
};

const AVATARS = [
    "/images/avatar/avatar1.png",
    "/images/avatar/avatar2.png",
    "/images/avatar/avatar3.png",
    "/images/avatar/avatar4.png",
    "/images/avatar/avatar5.png",
    "/images/avatar/avatar6.png",
    "/images/avatar/avatar7.png",
    "/images/avatar/avatar8.png",
    "/images/avatar/avatar9.png",
    "/images/avatar/avatar10.png",
    "/images/avatar/avatar11.png",
    "/images/avatar/avatar12.png",
    "/images/avatar/avatar13.png",
    "/images/avatar/avatar14.png",
    "/images/avatar/avatar15.png"
]

const CARD_FRONT = [
    "/images/cards/front/frontV1.png",
    "/images/cards/front/frontV2.png",
    "/images/cards/front/frontV3.png",
]

const CARD_BACK = [
    "/images/cards/back/backV1.png",
    "/images/cards/back/backV2.png",
    "/images/cards/back/backV3.png",
    "/images/cards/back/backV4.png",
]

const BOARD_STYLE = [
    "green",
    "blue",
    "red",
    "purple"
]

const TOURNAMENT_STATES ={
    'PENDING': 'pending',
    'PROGRESS': 'started',
    'CANCELED': 'canceled',
    'FINISHED': 'finished',
};

const HISTORY_TYPE=[
    {value: "ALL", label: "all"},
    {value: "CASH", label: "cash"},
    {value: "TOURNAMENT", label: "tournament"},
];

const HISTORY_RESULT=[
    {value: "WIN", label: "win"},
    {value: "LOSE", label: "lose"},
];

const AUDIO_FAILS = [
    '/audio/card-slide.wav',
    '/audio/cards-shuffle.wav',
    '/audio/play-card.wav',
    '/audio/gonga.wav',
]

const SLIDER_CONFIG = {
    playOnInit: true,
    stopOnInteraction: false,
    delay: 3000
}
const PLACE_COLORS = {
    1: 'gold',
    2: 'silver',
    3: 'bronze',
}

module.exports = {
    SUIT_VALUES,
    SUIT_ICONS,
    PLAYING_TILLS,
    STEP_TIMES,
    BANK_TIMES,
    GAME_CONVENTIONS,
    ROOM_TABLE_TITLES,
    TABS,
    TOURNAMENT_TABLE_TITLES,
    GAME_STATUS,
    COMBINATION_MODAL_BUTTONS,
    COMBINATION_ANNOUNCE_BUTTONS,
    CARDS_ORDER_BY_SUIT,
    AVATARS,
    CARD_FRONT,
    CARD_BACK,
    BOARD_STYLE,
    TOURNAMENT_STATES,
    HISTORY_TYPE,
    HISTORY_RESULT,
    HISTORY_TABLE_TITLES,
    AUDIO_FAILS,
    SLIDER_CONFIG,
    PLACE_COLORS
}