import Input from "../../../../../core/Input/Input";

function FilterInput({handleChange,placeholder,name,value}){
    const onInputChange = (value = '') => {
        if (value.split('').some(char => isNaN(parseInt(char)))) {
            return
        }
        handleChange({[name]: value})
    }

    return (
        <>
            <Input
                type="filter-filled"
                placeholder={placeholder}
                handleChange={onInputChange}
                name={name}
                value={value}
                onChange={onInputChange} />
        </>
    )
}
export default FilterInput;