import React from 'react';
import {useDispatch} from "react-redux";
import BaseModal from "./BaseModal";
import {roomPublishCombination, roomResetSelectedCombination} from "../../../../redux/actions/rooms/actions";
import Button from "../../../../core/Button/Button";
import {useTranslation} from "react-i18next";

function PublishCombinationModal({room}) {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const handleCloseModal = () => {
        dispatch(roomResetSelectedCombination({roomId: room?.roomId}))
    }
    return <BaseModal>
        <div className="modal__common">
            {room?.modal?.msg && <p>{t(`modals.common.${room?.modal?.msg}`)}</p>}
            <p>{t("modals.common.publish")}</p>
            <div className="modal__common--buttons">
                <Button
                    text={t("modals.buttons.publish")}
                    type="yellow"
                    onClick={() => dispatch(roomPublishCombination({roomId: room?.roomId}))}
                />
                <Button
                    text={t("modals.buttons.close")}
                    type="red" onClick={() => handleCloseModal()}
                />
            </div>
        </div>
    </BaseModal>
}

export default PublishCombinationModal
