import {useTranslation} from "react-i18next";
import Button from "../../../core/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {viewHistoryRoom} from "../../../redux/actions/history/actions";

function HistoryInfo() {
    const {t} = useTranslation();
    const currency = useSelector(state => state.user.currency)
    const {selectedRoomId, selectedRoomInfo} = useSelector(state => state.history)
    const dispatch = useDispatch()
    return (
        <div className="history-info">
            <div className="history-info__wrapper">
                <div className="history-info__header">Խաղի ID {selectedRoomInfo?.table_history_game_id || ""}</div>
                <div className="history-info__content">
                    <div className="history-info__row">
                        <span>{t("history.game_id")}</span>
                        {selectedRoomInfo && selectedRoomInfo?.table_history_game_id}
                    </div>
                    <div className="history-info__row">
                        <span>{t("history.bet")}</span>
                        {selectedRoomInfo && `${selectedRoomInfo?.table_history_bet[currency]}${currency}`}
                    </div>
                    <div className="history-info__row">
                        <span>{t("history.opponent")}</span>
                        {selectedRoomInfo && selectedRoomInfo?.table_history_opponent}
                    </div>
                    <div className="history-info__row">
                        <span>{t("history.till")}</span>
                        {selectedRoomInfo && selectedRoomInfo?.table_history_till}
                    </div>
                    <div className="history-info__row">
                        <span>{t("history.score")}</span>
                        {selectedRoomInfo && selectedRoomInfo?.table_history_points}
                    </div>
                    <div className="history-info__row">
                        <span>{t("history.step_time")}</span>
                        {selectedRoomInfo && `${selectedRoomInfo?.table_history_step_time}${t('history.s')}`}
                    </div>
                    <div className="history-info__row">
                        <span>{t("history.bank_time")}</span>
                        {selectedRoomInfo && `${selectedRoomInfo?.table_history_bank_time}${t('history.s')}`}
                    </div>
                    <div className="history-info__row">

                        <span>{t("history.type")}</span>
                        <span>
                            {
                                selectedRoomInfo?.table_history_conventions?.map((item, index) => {
                                    return <i
                                        key={`icon-${item}${index}`}
                                        className={`icon-${item} table-icon`}
                                    />
                                })
                            }
                        </span>
                    </div>
                    <div className="history-info__row">
                        <span>{t("history.result")}</span>
                        {selectedRoomInfo && t(`history.${selectedRoomInfo?.table_history_result}`)}
                    </div>
                </div>
                {
                    selectedRoomId &&
                    <>
                        <div className="history-info__footer">
                            <Button text={t("buttons.view")} onClick={() => dispatch(viewHistoryRoom({roomId: selectedRoomId}))}/>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default HistoryInfo