import React from 'react';
import {useSelector} from "react-redux";
import DeskTopTournament from "./DesktopTournament";
import MobileTournament from "./MobileTournament";

const Tournament = React.memo(function () {
    const isMobile = useSelector((state) => state.user.isMobile)
    return <div className="tournament-lobby">
        {
            isMobile
                ?
                <MobileTournament/>
                :
                <DeskTopTournament/>
        }
    </div>
})

export default Tournament