import React from 'react';
import {useDispatch} from "react-redux";
import BaseModal from "./BaseModal";
import {roomAcceptAllCardsToMeRequest, roomRejectAllCardsToMeRequest} from "../../../../redux/actions/rooms/actions";
import Button from "../../../../core/Button/Button";
import {useTranslation} from "react-i18next";

const PassRoomAllCardsToMeModal = React.memo(({room, userId}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const handleAcceptCardsToOpp = () => {
        dispatch(roomAcceptAllCardsToMeRequest({roomId: room.roomId}))
    }
    const handleRejectCardsToOpp = () => {
        dispatch(roomRejectAllCardsToMeRequest({roomId: room.roomId}))
    }

    return (
        <BaseModal>
            {
                userId !== room?.round?.activePlayerPlatformId ?
                    <p>{t("modals.combination_rank.waiting_text")}</p> :
                    <div className="modal__common">
                        <p>{t("modals.common.cards_to_me_opponent")}</p>
                        <div className="modal__common--buttons">
                            <Button text={t("modals.buttons.ok")} type="yellow" onClick={handleAcceptCardsToOpp}/>
                            <Button text={t("modals.buttons.reject")} type="blue" onClick={handleRejectCardsToOpp}/>
                        </div>
                    </div>
            }
        </BaseModal>
    );
});

export default React.memo(PassRoomAllCardsToMeModal);


