import HistoryListItemMobileInner from "./HistoryListItemMobileInner";
import {setHistorySelectedRoomId} from "../../../../../redux/actions/history/actions";
import {useDispatch, useSelector} from "react-redux";

function HistoryListItemMobile({data, currency}) {
    const dispatch = useDispatch()
    const selectedRoomId = useSelector(state => state.history.selectedRoomId)
    const handleHistoryItemClick = (roomId) => {
        const setValue = selectedRoomId === roomId ? null : roomId
        dispatch(setHistorySelectedRoomId({roomId: setValue}))
    }

    return (
        <div
            className="mobile-history-list__wrapper"
            onClick={() => handleHistoryItemClick(data.table_history_game_id)}
        >
            <HistoryListItemMobileInner
                data={data}
                currency={currency}
                isOpen={selectedRoomId === data.table_history_game_id}
            />
        </div>
    )
}

export default HistoryListItemMobile;