import React from 'react';
import {SUIT_VALUES} from "../../../../constants";

const Suit = React.memo(function Suit({value, selected, handleClick = (val) => {}}) {
    return (
        <span
            className={`modal__trump-select--suits--item icon-${SUIT_VALUES[value]} ${selected ? "selected": ""}`}
            onClick={() => handleClick(value)}>
        </span>
    )
}, (prevProps, nextProps) => {
    return prevProps.value === nextProps.value && prevProps.handleClick === nextProps.handleClick;
})

export default Suit
