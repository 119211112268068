import {useDispatch} from "react-redux";
import {selectActiveRoomTab} from "../../../../redux/actions/lobby/actions";
function MobileActiveRoomTabItem(props) {
    const {
        id,
        isViewRoom,
        isTournamentRoom,
    } = props;
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(selectActiveRoomTab({roomId: id}))
    }
    return <li onClick={handleClick}>
            <i className={`
                ${isTournamentRoom && !isViewRoom ? 'icon-cup' : 'icon-1'}
                ${isViewRoom ? 'icon-eye' : ''}
            `}/>
    </li>
}

export default MobileActiveRoomTabItem