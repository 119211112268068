import {useSelector} from "react-redux";
import DesktopHistoryList from "./DesktopHistoryList/DesktopHistoryList";
import MobileHistoryList from "./MobileHistoryList/MobileHistoryList";
function HistoryList() {
    const {isMobile, currency} = useSelector((state) => state.user);
    const roomList = useSelector((state) => state.history.list);

    return isMobile ?
        <MobileHistoryList  rows={Object.values(roomList)} currency={currency}/> :
        <DesktopHistoryList rows={Object.values(roomList)} currency={currency}/>

}

export default HistoryList;