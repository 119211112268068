import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getHistoryList} from "../../../redux/actions/history/actions";
import DeskTopHistory from "./DesktopHistory";
import MobileHistory from "./MobileHistory";

const History = React.memo(function () {
    const isMobile = useSelector((state) => state.user.isMobile);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHistoryList())
    }, [dispatch]);

    return isMobile ?  <MobileHistory /> :  <DeskTopHistory />
    })

export default History