import {useRef} from 'react';

const useAudio = () => {
    const audioRef = useRef({
        waiting: new Audio('/audio/waiting.wav'),
        playCard: new Audio('/audio/play-card.wav'),
        cardSlide: new Audio('/audio/card-slide.wav'),
        cardsShuffle: new Audio('/audio/cards-shuffle.wav'),
        gonga: new Audio('/audio/gonga.wav')
    });
    const localData = localStorage.getItem('isMuted') || 1;
    const toggleVolume = () => {
        const updated = localData === '1' ? '0' : '1'
        localStorage.setItem('isMuted', updated);
        return updated
    };

    const play = (name) => {
        if (Number(localData)) return;
        stopAllAudio();
        const audio = audioRef.current[name];
        if (audio) {
            // Only attempt to play if it's not already playing
            if (audio.paused || audio.ended) {
                audio.play().catch((error) => {
                    console.log(error);
                });
            }
            audio.onended = () => {
                console.log(`Audio ${name} finished playing`);
                // Reset audio state after it finishes
                audio.currentTime = 0; // Reset to start
                stopAllAudio();
            };
        }
    }
    const stopAllAudio = () => {
        Object.values(audioRef.current).forEach(audio => {
            audio.pause(); // Stop the audio
            audio.currentTime = 0; // Reset to the start
        });
    };

    return {
        toggleVolume,
        stopAllAudio,
        play,
    };
};

export default useAudio;
