import Checkbox from "../../../../../core/Checkbox/Checkbox";
import React from "react";

function FilterCheckboxMobile(props) {
    const {
        title,
        isChecked,
        onClick,
        icon
    } = props
    return <div
        className="mobile-cash-lobby-filter__group--row"
        onClick={() => onClick()}
    >
        <Checkbox
            className={"white"}
            checked={isChecked}
            title={title}
        />
        {icon && <i className={`icon-${icon}`}/>}
    </div>
}

export default FilterCheckboxMobile