import React from 'react';
import Filter from "../../../components/Filter/CashFilter/Filter";
import CreateForm from "../../../components/CreateRoomForm/CreateForm";
import CashTournamentList from "../../../components/CashTournamentList/CashTournamentList";
import RoomList from "../../../components/RoomList";
import Slider from "../../../components/Slider/Slider";

const DesktopCash = React.memo(function () {
    return <div className="cash-lobby">
        <CashTournamentList/>
        <Filter/>
        <div className="lobby-wrapper">
            <RoomList/>
            <div className="lobby-right">
                <CreateForm/>
                <Slider/>
            </div>
        </div>
    </div>
});
export default DesktopCash