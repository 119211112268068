import Select from "react-select";

function FilterSelect({name,placeholder, data, handleChange, value}) {
    const onChange = (data) => {
        handleChange({[name]: data})
    }
    return (
        <div className="history-select--wrapper">
            <Select
                isSearchable={false}
                value={value}
                options={data}
                maxMenuHeight={200}
                placeholder={placeholder}
                classNames={{
                    control: (state) =>
                        "history-select",
                    option: (state) =>
                        `history-select-option`,
                    menu: (state) =>
                        "history-select-menu",
                    dropdownIndicator: () =>
                        "filter-arrow",
                    singleValue: (state) =>
                        "history-select-value"
                }}
                styles={{
                    placeholder: (base) => {
                        return {
                            ...base,
                            fontSize:"0.85em",
                            color:"#fffffff"
                        }
                    }
                }
                }
                components={{
                    IndicatorSeparator: () => null,
                }}
                onChange={(value) => onChange(value)}
            />
        </div>
    )
}

export default FilterSelect;