import HISTORY from "../actions/history/types"
import UserSocket from "../../sockets/user-socket";

const initialState = {
    list: [],
    selectedRoomId: null,
    selectedRoomInfo: null,
    filter: {
        minBet: '',
        maxBet: '',
        result: [],
        till: [],
        type: []
    }

};
const reducer = (state = initialState, action) => {
        const socket = UserSocket.debouncedEmit.bind(UserSocket);

        switch (action?.type) {

            case HISTORY.SET_HISTORY_FILTER:
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        ...action.payload
                    }
                }

            case HISTORY.GET_HISTORY_LIST:
                socket(HISTORY.EVENT, {action: action.type, payload: action.payload, ctx: 'History'})
                return state

            case HISTORY.SET_HISTORY_LIST:
                return {
                    ...state,
                    list: action.payload.roomList
                }

            case HISTORY.SET_HISTORY_SELECTED_ROOM_ID:
                return {
                    ...state,
                    selectedRoomId: action.payload.roomId,
                    selectedRoomInfo: state.list[action.payload.roomId]
                }

            case HISTORY.VIEW_HISTORY_ROOM:
                socket(HISTORY.EVENT, {action: action.type, payload: action.payload, ctx: 'History'})
                return state;

            case HISTORY.EXIT_HISTORY_ROOM:
                socket(HISTORY.EVENT, {action: action.type, payload: action.payload, ctx: 'History'})
                return state;

            case HISTORY.VIEW_HISTORY_ROOM_NEXT:
                socket(HISTORY.EVENT, {action: action.type, payload: action.payload, ctx: 'History'})
                return state;

            default:
                return state;
        }
    }
;
export default reducer;
