import {setDataInLocalStorage} from "../../../../../utils";
import {useState} from "react";
import combinedSettingItems from "../../CombineSettings";

function SettingItem(props) {
    const {name, data} = props;
    const TargetItem = combinedSettingItems[name];

    const getActiveIndex = () => {
        return parseInt(localStorage.getItem(name)) || 1
    }

    const [activeIndex, setActiveIndex] = useState(getActiveIndex());


    const handleItemClick = (index, itemName) => {
        if (activeIndex === index) return;
        setDataInLocalStorage(itemName, index)

        const element = document.querySelector(".belote-wrapper");
        const regexPattern = new RegExp(`${itemName}-V\\d+`);

        element.classList.forEach(cls => {
            if (regexPattern.test(cls)) {
                element.classList.remove(cls);
                element.classList.add(`${itemName}-V${index}`);
            }
        });

        setActiveIndex(index)
    }

    return (
        <ul className="settings__elements--wrapper">
            {data?.map((item, index) => {
                return (
                    <TargetItem
                        key={index}
                        item={item}
                        name={name}
                        isActive={index + 1 === getActiveIndex()}
                        onItemClick={() => handleItemClick(index + 1, name)}
                    />
                )
            })}
        </ul>
    )
}

export default SettingItem