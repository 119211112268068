import TOURNAMENT from "./types";

export const setTournaments = (payload) => {
    return {
        type: TOURNAMENT.SET_TOURNAMENTS,
        payload
    }
};
export const joinTournament = (payload) => {
    return {
        type: TOURNAMENT.JOIN_TOURNAMENT,
        payload
    }
};
export const updateTournamentRooms = (payload) => {
    return {
        type: TOURNAMENT.UPDATE_ROOMS,
        payload
    }
};
export const quitTournament = (payload) => {
    return {
        type: TOURNAMENT.QUIT_TOURNAMENT,
        payload
    }
};
export const updatePlayerList = (payload) => {
    return {
        type: TOURNAMENT.UPDATE_PLAYERS,
        payload
    }
};
export const setInitialData = (payload) => {
    return {
        type: TOURNAMENT.INITIAL_LOAD,
        payload
    }
};
export const setNewTournament = (payload) => {
    return {
        type: TOURNAMENT.SET_NEW_TOURNAMENT,
        payload
    }
};
export const openTournamentRegistration = (payload) => {
    return {
        type: TOURNAMENT.OPEN_TOURNAMENT_REGISTRATION,
        payload
    }
};
export const updateTournament = (payload) => {
    return {
        type: TOURNAMENT.UPDATE_TOURNAMENT,
        payload
    }
};
export const setSideBarTournamentId = (payload) => {
    return {
        type: TOURNAMENT.SET_SIDEBAR_TOURNAMENT_ID,
        payload
    }
};
export const updateTournamentRoundTimer = (payload) => {
    return {
        type: TOURNAMENT.UPDATE_TOURNAMENT_ROUND_TIMER,
        payload
    }
};
export const deleteTournament = (payload) => {
    return {
        type: TOURNAMENT.DELETE_TOURNAMENT,
        payload
    }
};
export const updateTopWinners = (payload) => {
    return {
        type: TOURNAMENT.UPDATE_TOP_WINNERS,
        payload
    }
};
export const toggleTournamentActiveRooms = (payload) => {
    return {
        type: TOURNAMENT.SET_TOURNAMENT_ACTIVE_ROOMS_ID,
    }
};

