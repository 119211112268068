import {createTableRow} from "../TableElement/TableElements";
import {useTranslation} from "react-i18next";

function TableBody({name, rows, handleClick, currency}) {
    const {t} = useTranslation();
    return (
        <div className="table-body">
            {
                rows?.map((row) => {
                    return createTableRow(name, row, currency, handleClick, t)
                })
            }
        </div>
    )
}

export default TableBody