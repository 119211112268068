import {useDispatch} from "react-redux";
import {setActivePage} from "../../../../redux/actions/lobby/actions";
import Settings from "../../Settings/Settings";
import {useState} from "react";
import useAudio from "../../../../hooks/Audio";

function DesktopHeaderOptions() {
    const dispatch = useDispatch();
    const [isOpen, setState] = useState(false);
    const [muted, setSetMuted] = useState(localStorage.getItem('isMuted') || 1);
    const {toggleVolume} = useAudio();
    const toggleActivePage = (name) => {
        isOpen && setState(!isOpen)
        dispatch(setActivePage({activePage: name}))
    }
    const toggleMuted = () => {
        isOpen && setState(!isOpen)
        const toggleData = toggleVolume();
        if (toggleData !== muted) setSetMuted(toggleData)

    }
    const onClickSettings = (e) => {
        e.stopPropagation()
        setState(!isOpen)
    }

    return (
        <ul className={`lobby-header-icon-group ${isOpen ? 'highlight' : ''}`}>
            <li
                className={`header-option--icon ${Number(muted) ? 'icon-volOff' : 'icon-vol'}`}
                onClick={() => toggleMuted()}
            />
            <li
                className="header-option--icon icon-history"
                onClick={() => toggleActivePage('History')}
            />
            <li
                className="header-option--icon icon-settings"
                onClick={(e) => onClickSettings(e)}>
                {
                    isOpen && <>
                        <Settings/>
                        <div
                            className="settings__backdrop"
                            onClick={() => setState(false)}>
                        </div>
                    </>
                }
            </li>
            <li
                className="header-option--icon icon-0"
                onClick={() => toggleActivePage('HowToPlay')}
            />
        </ul>

    )
}

export default DesktopHeaderOptions