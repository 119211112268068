import React from 'react';
import { useTranslation } from "react-i18next";

function Dialog({ dialog }) {
    const {
        msg,
        variable,
        newLine = false,
        start = false,
        translate = false
    } = dialog;
    const { t } = useTranslation();
    if (!msg) return null;
    return (
        <div className="player--dialog">
            {newLine ? (
                <>
                    {
                        start ? (
                            <>
                                <span>{translate ? t(`dialog.${variable}`): variable}</span>
                                <span>{t(`dialog.${msg}`, { '%s': '' })}</span>
                            </>
                        ) : (
                            <>
                                <span>{t(`dialog.${msg}`, { '%s': '' })}</span>
                                <span>{translate ? t(`dialog.${variable}`): variable}</span>
                            </>
                        )
                    }
                </>
            ) : (
                <span>{t(`dialog.${msg}`, { '%s': variable })}</span>
            )}
        </div>
    );
}

export default Dialog;
