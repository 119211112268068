import LOBBY from "../actions/lobby/types";
const initialState = {
    activeRoomId: null,
    activePage: 'Cash',
    promo: [],
    activeModalName: '',
};
const reducer = (state = initialState, action) => {
        switch (action?.type) {

            case LOBBY.SET_ACTIVE_PAGE:
                return {
                    ...state,
                    activePage: action.payload.activePage,
                    activeRoomId: null
                }

            case LOBBY.SET_PROMO_DATA:
                return {
                    ...state,
                    promo: action.payload.promo,
                }

            case LOBBY.SELECT_ACTIVE_ROOM_TAB:
                return {
                    ...state,
                    activeRoomId: action.payload.roomId,
                }

            case  LOBBY.OPEN_MODAL:
                return {
                    ...state,
                    activeModalName: action.payload.name
                }

            case  LOBBY.CLOSE_MODAL:
                return {
                    ...state,
                    activeModalName: ''
                }

            default:
                return state;
        }
    }
;
export default reducer;
