function Checkbox({checked, title, className = "black"}) {
    return (
        <div className="checkbox-wrapper">
            <input
                type="checkbox"
                checked={checked}
                onChange={() => {}}
            />
            <div className={className}>

            </div>
            {title && <span>{title}</span>}
        </div>
    )
}

export default Checkbox