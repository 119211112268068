import {PLACE_COLORS} from "../../../../constants";
function TournamentParticipantItem({item}) {
    const placeClass = PLACE_COLORS[item.place] || ''

    return (
        <li className="tournament-info-players--item">
            <p className={`tournament-info-players--item--cell ${placeClass ? "place" : ""}`}>
                <span className={placeClass}>{item.place}</span>
            </p>
            <p className="tournament-info-players--item--cell">
                <span>{item?.nickName || item.id}</span>
            </p>
        </li>
    )
}

export default TournamentParticipantItem