import {useDispatch} from "react-redux";
import {selectActiveRoomTab} from "../../../../redux/actions/lobby/actions";
import {closeRoom, roomViewExit} from "../../../../redux/actions/rooms/actions";
import {exitHistoryRoom} from "../../../../redux/actions/history/actions";
import {useTranslation} from "react-i18next";

function DesktopActiveRoomTabItem(props) {
    const {
        id,
        isViewRoom,
        isHistoryRoom,
        isTournamentRoom,
    } = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(selectActiveRoomTab({roomId: id}))
    }
    const onClose = (event) => {
        event.stopPropagation();
        if (!isViewRoom) {
            return dispatch(closeRoom({roomId: id}));
        }
        if (isHistoryRoom) {
            return dispatch(exitHistoryRoom({roomId: id}));
        }
        return dispatch(roomViewExit({roomId: id}));
    };

    return <div className="header-active-game-item" onClick={handleClick}>
        <i className={`
            ${isTournamentRoom && !isViewRoom ? 'icon-cup' : 'icon-1'}
            ${isViewRoom ? 'icon-eye' : ''}
        `}/>
        <p>{t('tabs.game')} {id} </p>
        <i className="icon-close" onClick={(e) => onClose(e)}/>
    </div>
}

export default DesktopActiveRoomTabItem