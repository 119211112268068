import React from 'react';

const Card = React.forwardRef((props, ref) => {
    const {
        data,
        className,
        onCardClick,
    } = props;

    const visibleCard = () => (
        <div
            ref={ref}
            className={`card
                ${className}
            `}
            onClick={onCardClick}
        >
            <div className="card--inner">
                <span className="card__back"></span>
                <span className={`card__front`}></span>
            </div>
        </div>
    );

    const hiddenCard = () => (
        <div ref={ref} className={`card  ${className}`}>
            <div className="card--inner">
                <span className="card__back"></span>
                <span className="card__front"></span>
            </div>
        </div>
    );

    return (
        <>
        {data ? visibleCard() : hiddenCard()}
        </>
    );
});

export default Card;