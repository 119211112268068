import Select from 'react-select';

function CreateRoomSelect({name, label, data, handleChange, value}) {
    const onChange = (data) => {
        handleChange({[name]: data})
    }

    return (
        <div className="create-room-select-wrapper">
            {label && <label> {label}</label>}
            <Select
                isSearchable={false}
                value={value}
                options={data}
                maxMenuHeight={200}
                classNames={{
                    control: (state) =>
                        "create-room-select",
                    option: (state) =>
                        `create-room-select-option`,
                    menu: (state) =>
                        "create-room-select-menu",
                    singleValue:(state)=>
                        "create-room-select-value"
                }}
                components={{
                    IndicatorSeparator: () => null,
                }}
                onChange={(value)=>onChange(value)}
            />
        </div>
    )
}

export default CreateRoomSelect