import React from 'react';
import BaseModal from "./BaseModal";
import {useTranslation} from "react-i18next";

function FinishModal({room, userId}) {
    const {t} = useTranslation()
    return (
        <BaseModal>
            <div className="modal__finish">
                {room.modal.msg ?
                    <h2>{room.modal.winnerId === userId ? t(`modals.finish.win_${room?.modal?.msg}`) : t(`modals.finish.lose_${room?.modal?.msg}`)}</h2>
                    :
                    <h2>{room.modal.winnerId === userId ? t("modals.finish.you_won") : t("modals.finish.opponent_won")}</h2>
                }
                <div className="modal__finish--content">
                    <p className="modal__finish--result">
                        {t("modals.finish.game_score")}
                        {room?.modal?.points && <span>{`${room?.modal?.points[room.modal.winnerId]} : ${room?.modal?.points[room.modal.loserId]}`}</span>}
                    </p>

                    {!room?.tournamentId && <p className={"modal__finish--prize"}>
                        {t("modals.finish.amount")}
                        <span className="modal__finish--prize--prize">192</span>
                    </p>}
                </div>
            </div>
        </BaseModal>
    )
}

export default FinishModal
