function Modal({children, animated}) {
    return (
        <div className={`modal ${animated ? "modal-animated" : ""} modal--opened`}>
            <div className="modal__wrapper">
                    {children}
            </div>
        </div>
    )
}

export default Modal;