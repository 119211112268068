import HISTORY from "./types";
export const setHistoryFilter = (payload) => {
    return {
        type:HISTORY.SET_HISTORY_FILTER,
        payload
    }
}
export const getHistoryList = (payload) => {
    return {
        type:HISTORY.GET_HISTORY_LIST,
        payload
    }
}
export const setHistoryList = (payload) => {
    return {
        type:HISTORY.SET_HISTORY_LIST,
        payload
    }
}
export const setHistorySelectedRoomId = (payload) => {
    return {
        type:HISTORY.SET_HISTORY_SELECTED_ROOM_ID,
        payload
    }
}
export const viewHistoryRoom = (payload) => {
    return {
        type:HISTORY.VIEW_HISTORY_ROOM,
        payload
    }
}

export const exitHistoryRoom = (payload) => {
    return {
        type:HISTORY.EXIT_HISTORY_ROOM,
        payload
    }
}

export const historyRoomNextAction = (payload) => {
    return {
        type:HISTORY.VIEW_HISTORY_ROOM_NEXT,
        payload
    }
}
