import React from 'react';
import TournamentTopPlayers from "../../../components/TournamentTopPlayers/TournamentTopPlayers";
import {useSelector} from "react-redux";
import TournamentInfo from "../../../components/TournamentInfo/TournamentInfo";
import TournamentList from "../../../components/TournamentList/TournamentList";
import TournamentActiveRooms from "../../../components/TournamentActiveRooms/TournamentActiveRooms";

const DeskTopTournament = React.memo(function () {
    const isActiveTournamentRoomsSection = useSelector((state) => state.tournament.isActiveTournamentRoomsSection);
    return  <>
        <TournamentTopPlayers/>
        <div className="tournament-wrapper">
            <div className="tournament-list">
                {
                    isActiveTournamentRoomsSection ? <TournamentActiveRooms/> : <TournamentList/>
                }
            </div>
            <TournamentInfo/>
        </div>
    </>
})

export default DeskTopTournament