import CASH from "../actions/cash/types";
import UserSocket from "../../sockets/user-socket";

const initialState = {
    roomList: [],
    filter: {
        minBet: '',
        maxBet: '',
        score: [],
        time: [],
        status: [],
        type: []
    },
};
const reducer = (state = initialState, action) => {
    const socket = UserSocket.debouncedEmit.bind(UserSocket);

        switch (action?.type) {

            case CASH.UPDATE_ROOM_LIST:
                return {
                    ...state,
                    roomList: action.payload.roomList
                }

            case CASH.CREATE_ROOM:
                socket(CASH.EVENT, {action: action.type, payload: action.payload, ctx: 'Cash'})
                return state

            case CASH.JOIN_ROOM:
                socket(CASH.EVENT, {action: action.type, payload: action.payload, ctx: 'Cash'})
                return state

            case CASH.SET_FILTER:
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        ...action.payload
                    }
                }

            case CASH.RESET_FILTER:
                return {
                    ...state,
                    filter: initialState.filter,
                }

            default:
                return state;
        }
    }
;
export default reducer;
