import Cash from "./Cash";
import Tournament from "./Tournament";
import History from "./History";
import HowToPlay from "./HowToPlay";
import MobileSettings from "./MobilePages/MobileSettings/MobileSettings";

const CombinedLobbyPages = {
    Cash,
    Tournament,
    History,
    HowToPlay,
    MobileSettings,
}

export default CombinedLobbyPages