import BalanceMobile from "./BalanceMobile/BalanceMobile";
import SideMenuMobile from "./SideMenuMobile/SideMenuMobile";
import React from "react";
import ActiveRoomTabs from "../../ActiveRoomTabs/ActiveRoomTabs";
const MobileHeader = React.memo(function() {
    return <div className="mobile-header">
        <BalanceMobile />
        <ActiveRoomTabs />
        <SideMenuMobile />
    </div>
}, (prevProps, nextProps) => true)

export default MobileHeader