import combinedButtons from "../../../game/components/RoomList/components/Buttons/RoomButtons";
import {convertTime, formatTimeFromTimestamp, getShortUniqueKey} from "../../../utils";
import combinedTournamentButtons from "../../../game/components/RoomList/components/Buttons/TournamentButtons";

export const createTableRow = (tableName, row, currency, handleClick, t) => {
    return (
        <div
            key={getShortUniqueKey()}
            className={`table-body--row ${tableName !== "Rooms" ? "hover" : ""}`}
            onClick={() => handleClick(row)}>
            {
                Object.keys(row).map((key) => {
                    const cell = row[key];
                    return createTableCell(cell, key, row, currency, t)
                })
            }
        </div>
    )
}

export const createTableCell = (cell, key, row, currency, t) => {
    const uniqueKeyIndex = getShortUniqueKey();
    switch (key) {
        case 'table_tournament_id':
        case 'table_history_points':
        case 'table_history_bank_time':
        case 'table_history_result':
            return;
        case 'table_tournament_name':
            return <div className="table-body--cell" key={uniqueKeyIndex}>{`${cell}${row?.table_tournament_id}`}</div>
        case 'table_room_type':
        case 'table_history_type':
        case 'table_history_conventions':
        case 'table_tournament_type':
            return (
                <div className="table-body--cell" key={uniqueKeyIndex}>
                    {
                        cell.map((item) => {
                            return <i
                                key={`icon-${item}${uniqueKeyIndex}`}
                                className={`icon-${item} table-icon`}
                            />
                        })
                    }
                </div>);
        case 'table_room_status':
            const RoomRowButton = combinedButtons[cell]
            if (!RoomRowButton) return
            return (
                <div className="table-body--cell table-body--cell--buttons" key={uniqueKeyIndex}>
                    <RoomRowButton row={row}/>
                </div>
            )
        case 'table_tournament_status':
            const TournamentRowButton = combinedTournamentButtons[row.table_tournament_status]
            return <div key={uniqueKeyIndex} className="table-body--cell table-body--cell--buttons">
                <TournamentRowButton row={row}/>
            </div>
        case 'table_history_bet':
        case 'table_tournament_prize':
        case 'table_tournament_buy_in':
        case 'table_room_bet':
            return <div className="table-body--cell" key={uniqueKeyIndex}>{`${cell[currency]} ${currency}`}</div>
        case 'table_history_step_time':
        case 'table_room_bankTime':
        case 'table_room_stepTime':
            return <div className="table-body--cell" key={uniqueKeyIndex}>{cell}{t('tournament.s')}</div>
        case 'table_history_started':
            return <div className="table-body--cell" key={uniqueKeyIndex}>{convertTime(cell)}</div>
        case 'table_tournament_start':
            return <div className="table-body--cell" key={uniqueKeyIndex}>{formatTimeFromTimestamp(cell)}</div>
        default:
            return <div className="table-body--cell" key={uniqueKeyIndex}>{cell}</div>
    }
}
