import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_ru from "../translations/ru/common.json";
import common_am from "../translations/am/common.json";
import common_en from "../translations/en/common.json";

export default function initI18n (lang = 'en') {
    i18n.use(initReactI18next)
        .init({
            lng: lang,
            fallbackLng: lang,
            defaultNS: 'common',
            resources: {
                en: {
                    common: common_en
                },
                am: {
                    common: common_am
                },
                ru: {
                    common: common_ru
                },
            }
        });
    return i18n;
};