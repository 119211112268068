const types = {
    EVENT: 'gameEvent',
    SET_HISTORY_FILTER: 'setHistoryFilter',
    GET_HISTORY_LIST: 'getHistoryList',
    SET_HISTORY_LIST: 'setHistoryList',
    SET_HISTORY_SELECTED_ROOM_ID: 'setHistorySelectedRoomId',
    VIEW_HISTORY_ROOM: 'viewHistoryRoom',
    EXIT_HISTORY_ROOM: 'exitHistoryRoom',
    VIEW_HISTORY_ROOM_NEXT: 'historyRoomNextAction',
};
export default types
